import { ECOASTUCE_EDIT_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import EcoAstuceBox from '@shared/components/box';
import useUrlToken from '@shared/hooks/use-url-token';
import UrlToken from '@shared/misc/helpers/urlToken';
import React from 'react';
import useData from './hook';
import { CardProps } from './type';

const Card: React.FC<CardProps> = ({ ecoAstuce, skeleton, activatePopup }) => {
  const { isDeletable, confirmDelete } = useData(ecoAstuce);
  const sirenToken = useUrlToken(RouteParameters.sirenId);
  return (
    <EcoAstuceBox
      dataCy={ecoAstuce.id}
      title={ecoAstuce.title}
      text={ecoAstuce.description}
      src={ecoAstuce.image ?? ''}
      showDelete={isDeletable}
      onDelete={() => {
        activatePopup(confirmDelete, ecoAstuce);
      }}
      detailLink={ECOASTUCE_EDIT_PATH.replace(RouteParameters.sirenId, sirenToken.toString()).replace(
        RouteParameters.ecoAstuceId,
        UrlToken.fromXLink(ecoAstuce._links.self).toString(),
      )}
      skeleton={skeleton}
    />
  );
};

export default Card;
