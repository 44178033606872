export function isNullOrEmpty(value: string | undefined | null): boolean {
  return value === '' || value == null;
}
export function isNotEmpty(value: string | undefined | null): boolean {
  return !isNullOrEmpty(value);
}

/**
 * Permet de passer un mot en pascalCase
 * @param value
 * @returns
 */
export const toPascalCase = (value: string): string => {
  return value.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());
};

/**
 * It takes a sentence and replaces the spaces with dashes
 * @param {string} sentence - string
 * @returns A function that takes a string and returns a string.
 */
export const sentenceToString = (sentence: string): string => {
  return sentence.replace(' ', '-');
};

/**
 * It takes a string and a number, and returns a string
 * @param {string} text - The text to truncate
 * @param {number} length - The length of the string you want to truncate.
 * @returns A function that takes two parameters, text and length, and returns a string.
 */
export const truncateText = (text: string | undefined, length: number): string => {
  if (!text) return '';
  if (text.length >= length) return `${text.substring(0, length)}...`;
  return text;
};

/**
 * Permet de savoir si la string est un url valide
 * @param uri
 * @returns
 */
export const isValidHttpUrl = (uri?: string) => {
  if (!uri) return false;

  let url;
  try {
    url = new URL(uri);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 *
 * Génère une URL avec la route précisée en paramètre
 *
 */
export const encodeYserUri = (uriToEncode: string): string => {
  return encodeURI(btoa(uriToEncode));
};

/**
 *
 * Change le format du string en paramètre pour mettre la première lettre en majuscule
 *
 */
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
