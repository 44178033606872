import {
  Columns,
  ColumnsItem,
  Container,
  Link,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import { ECOASTUCES_LIST_BTE_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import useUrlToken from '@shared/hooks/use-url-token';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import Card from './card-item';
import useData from './hook';
import { EcoAstucesBTEProps } from './type';

const EcoAstucesBTE: React.FC<EcoAstucesBTEProps> = ({ ecoAstucesBTE, skeleton }) => {
  const { t } = useData();
  const sirenToken = useUrlToken(RouteParameters.sirenId);
  return (
    <Section background={TrilogyColor.PRIMARY}>
      <Container fluid>
        <Rows>
          <RowItem>
            <Columns>
              <ColumnsItem size={9}>
                <Title inverted level={TitleLevels.TWO} data-cy="ecoastuces-bte-title">
                  {t('bte.title-ecoastuces')}
                  <br />
                  {t('bte.title-bte')}
                </Title>
              </ColumnsItem>
              <ColumnsItem size={3}>
                <Link
                  to={ECOASTUCES_LIST_BTE_PATH.replace(RouteParameters.sirenId, sirenToken.toString())}
                  routerLink={routerLink}
                  data-cy="ecoastuces-bte-voirtout"
                >
                  <Text style={{ textDecoration: 'underline' }} inverted level={TextLevels.TWO}>
                    {t('bte.show-everything')}
                  </Text>
                </Link>
              </ColumnsItem>
            </Columns>
          </RowItem>
          <RowItem>
            <Container fluid>
              <Rows>
                {ecoAstucesBTE.map((ecoAstuce) => (
                  <RowItem key={ecoAstuce.id}>
                    <Card ecoAstuce={ecoAstuce} skeleton={skeleton} />
                  </RowItem>
                ))}
              </Rows>
            </Container>
          </RowItem>
        </Rows>
      </Container>
    </Section>
  );
};
export default EcoAstucesBTE;
