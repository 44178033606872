import { Siren } from '@services/interfaces/generated/YSER-v1';
import React, { useContext, useMemo } from 'react';
import { SirenContextType, SIREN_CACHE_KEY } from './types';

/**
 * Valorisation initiale du contexte
 */
const initialState: SirenContextType = {
  siren: undefined,
  setSiren: (siren?: Siren) => {
    throw new Error(`The 'setSiren' function must be used within a SirenProvider ${siren?.id}`);
  },
};
/**
 * Contexte permettant de gérer la siren actuellement sélectionnée
 */
const SirenContext = React.createContext<SirenContextType>(initialState);

export const SirenProvider: React.FC = ({ children }) => {
  const [siren, setSiren] = React.useState<Siren | undefined>(() => {
    const restoredSiren: Siren | undefined =
      localStorage.getItem(SIREN_CACHE_KEY) !== null
        ? JSON.parse(localStorage.getItem(SIREN_CACHE_KEY) ?? '')
        : undefined;
    return restoredSiren;
  });
  const value = useMemo<SirenContextType>(
    () => ({
      siren,
      setSiren: (newSiren) => {
        if (newSiren) {
          localStorage.setItem(SIREN_CACHE_KEY, JSON.stringify(newSiren));
          setSiren(newSiren);
        } else {
          localStorage.removeItem(SIREN_CACHE_KEY);
          setSiren(undefined);
        }
      },
    }),
    [siren, setSiren],
  );

  return <SirenContext.Provider value={value}>{children}</SirenContext.Provider>;
};

/**
 * Hook permettant de récupérer la valeur courante de l'identifiant de siren sur lequel on se trouve
 */
export function useSiren(): Siren | undefined {
  const context = useContext(SirenContext);

  if (context === undefined) {
    throw new Error('The `useSiren` hook must be used within a SirenProvider');
  }

  return context.siren;
}
/**
 * Hook permettant de gérer la valeur courante de l'identifiant de siren sur lequel on se trouve
 */
export function useSirenProvider(): (siren?: Siren) => void {
  const context = React.useContext(SirenContext);
  if (context === undefined) {
    throw new Error('The `useSirenProvider` hook must be used within a SirenProvider');
  }
  return context.setSiren;
}
/**
 * Hook permettant de récupérer le contexte de siren
 */
export function useSirenContext(): SirenContextType {
  const context = React.useContext(SirenContext);
  if (context === undefined) {
    throw new Error('The `useSirenContext` hook must be used within a SirenProvider');
  }
  return context;
}

export default SirenContext;
