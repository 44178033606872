/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OAUTH_CALLBACK } from '@router/paths';
import { UserRoles } from '@router/types';
import { SIREN_CACHE_KEY } from '@shared/contexts/types';
import getConfig from '@shared/misc/configuration';
import { TypeConfig } from '@shared/misc/configuration/type';
import Axios from 'axios';
import { SubEvent } from 'sub-events';
import Data from './data';
import {
  AuthenticationError,
  EXPIRESAT_CACHE_KEY,
  OAuthConfiguration,
  TOKENS_CACHE_KEY,
  UserData,
  USERINFOS_CACHE_KEY,
  UserToken,
} from './type';

class AuthBytelService {
  /** Clé de cache de la date d'expiration du token en cours */
  static EXPIRESAT_CACHE_KEY = EXPIRESAT_CACHE_KEY;

  /** Clé de cache des tokens JWT */
  static TOKENS_CACHE_KEY = TOKENS_CACHE_KEY;

  /** Clé de cache des infos de l'utilisateur */
  static USERINFOS_CACHE_KEY = USERINFOS_CACHE_KEY;

  /**
   * Détermine si les données du serveur OAuth ont été chargées
   */
  private _isLoaded = false;

  /**
   * Gestionnaire d'événement lors du chargement de la configuration du service
   */
  private _onIsLoadedChanged: SubEvent<boolean>;

  /**
   * Accesseur au gestionnaire d'événement lors du chargement de la configuration du service
   */
  get onIsLoadedChanged(): SubEvent<boolean> {
    return this._onIsLoadedChanged;
  }

  /**
   * Détermine si l'utilisateur est authentifié
   */
  private _isAuthenticated = false;

  /**
   * Gestionnaire d'événement lors du changement de statut d'authentification
   */
  private _onAuthenticationChanged: SubEvent<boolean>;

  /**
   * Accesseur au gestionnaire d'événement lors du changement de statut d'authentification
   */
  get onAuthenticationChanged(): SubEvent<boolean> {
    return this._onAuthenticationChanged;
  }

  /**
   * Détermine si l'utilisateur est authentifié
   */
  private _isAuthenticating = false;

  /**
   * Gestionnaire d'événement lors du changement de statut d'authentification
   */
  private _onAuthenticatingChanged: SubEvent<boolean>;

  /**
   * Accesseur au gestionnaire d'événement lors du changement de statut d'authentification
   */
  get onAuthenticatingChanged(): SubEvent<boolean> {
    return this._onAuthenticatingChanged;
  }

  /**
   * Gestionnaire d'événement lors de la mise à jour du jeton JWT
   */
  private _onAccessTokenChanged: SubEvent<string>;

  /**
   * Accesseur au gestionnaire d'événement lors de la mise à jour du jeton JWT
   */
  get onAccessTokenChanged(): SubEvent<string> {
    return this._onAccessTokenChanged;
  }

  /**
   * Gestionnaire d'événement lors de la mise à jour du nom de l'utilisateur
   */
  private _onUserNameChanged: SubEvent<string>;

  /**
   * Accesseur au gestionnaire d'événement lors de la mise à jour du nom de l'utilisateur
   */
  get onUserNameChanged(): SubEvent<string> {
    return this._onUserNameChanged;
  }

  /**
   * Gestionnaire d'évènement lors de la mise à jour des rôles de l'utilisateur
   */
  private _onRolesChanged: SubEvent<string[]>;

  /**
   * Accesseur au gestionnaire d'évènement lors de la mise à jour des rôles de l'utilisateur
   */
  get onRolesChanged(): SubEvent<string[]> {
    return this._onRolesChanged;
  }

  /**
   * Gestionnaire d'évènement lors de la mise à jour des sirens de l'utilisateur
   */
  private _onSirensChanged: SubEvent<string[]>;

  /**
   * Accesseur au gestionnaire d'évènement lors de la mise à jour des sirens de l'utilisateur
   */
  get onSirensChanged(): SubEvent<string[]> {
    return this._onSirensChanged;
  }

  /**
   * Gestionnaire d'évènement lors de la mise à jour des infos de l'utilisateur
   */
  private _onUserInfosChanged: SubEvent<UserData | undefined>;

  /**
   * Accesseur au gestionnaire d'évènement lors de la mise à jour des infos de l'utilisateur
   */
  get onUserInfosChanged(): SubEvent<UserData | undefined> {
    return this._onUserInfosChanged;
  }

  /**
   * Contenu de la dernière erreur reçue par la classe
   */
  private _lastError: AuthenticationError;

  /**
   * Accesseur au gestionnaire d'événement lors de la levée d'une erreur
   */
  get lastError(): AuthenticationError {
    return this._lastError;
  }

  /**
   * Gestionnaire d'événement lors de la levée d'une erreur
   */
  private _onErrorRaised: SubEvent<AuthenticationError>;

  /**
   * Accesseur au gestionnaire d'événement lors de la levée d'une erreur
   */
  get onErrorRaised(): SubEvent<AuthenticationError> {
    return this._onErrorRaised;
  }

  /**
   * Date (epoch) à partir de quand le token sera expiré
   */
  private _expiresAt = 0;

  /**
   * Ensemble des jetons JWT reçu de la part du serveur d'autorisation
   */
  private _tokens: UserToken | undefined;

  /**
   * Ensemble des données de l'utilisateur connecté, reçu de l'IDP
   */
  private _userInfos: UserData | undefined;

  /**
   * Gestionnaire d'événement lors du rafraichissement du token
   */
  private _onTokenRefresh: SubEvent<void>;

  /**
   * Accesseur au gestionnaire d'événement lors du rafraichissement du token
   */
  get onTokenRefresh(): SubEvent<void> {
    return this._onTokenRefresh;
  }

  private _environment: OAuthConfiguration | undefined;

  private _nextTimeoutInterval: NodeJS.Timeout | undefined;

  /**
   * Méthode pour vider le cache
   */
  static cleanCache = (): void => {
    localStorage.removeItem(AuthBytelService.TOKENS_CACHE_KEY);
    localStorage.removeItem(AuthBytelService.EXPIRESAT_CACHE_KEY);
    localStorage.removeItem(AuthBytelService.USERINFOS_CACHE_KEY);
    localStorage.removeItem(SIREN_CACHE_KEY);
  };

  constructor() {
    // #region Initialisation des gestionnaires d'événements
    this._onIsLoadedChanged = new SubEvent<boolean>();
    this._onAuthenticatingChanged = new SubEvent<boolean>();
    this._onAuthenticationChanged = new SubEvent<boolean>();
    this._onAccessTokenChanged = new SubEvent<string>();
    this._onUserNameChanged = new SubEvent<string>();
    this._onRolesChanged = new SubEvent<string[]>();
    this._onSirensChanged = new SubEvent<string[]>();
    this._onUserInfosChanged = new SubEvent<UserData | undefined>();
    this._onTokenRefresh = new SubEvent();
    this._onErrorRaised = new SubEvent<AuthenticationError>();
    // #endregion

    this.loadApplicationConfiguration();
  }

  /** Charge le fichier JSON de configuration de l'application */
  private loadApplicationConfiguration(): Promise<void> {
    return getConfig().then((configuration) => {
      const redirectUri = `${window.location.protocol}//${window.location.host}`;
      this._environment = {
        type: configuration.ENVIRONNEMENT,
        loginUrl: configuration.OAUTH2_LOGIN_URL + redirectUri,
        logoutUrl: configuration.OAUTH2_LOGOUT_URL + redirectUri,
        tokenUrl: configuration.OAUTH2_TOKEN_URL,
        refreshTokensUrl: configuration.OAUTH2_REFRESH_TOKEN_URL,

        userDataUrl: configuration.OAUTH2_USER_DATA_URL,

        roleAdmin: configuration.ROLE_ADMINISTRATOR,
        roleUser1: configuration.ROLE_USER1,

        disableAuth: configuration.DISABLE_AUTH,
      };

      if (this._environment.disableAuth) {
        console.info('Authentification désactivée');
        this.setupFakeAuth();
      }

      if (this.isInStorage()) {
        this.loadCurrentData();
      }
      this.loadOpenIdServerConfiguration(configuration);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private setupFakeAuth(): void {
    const config = Data;
    const fakeExpireAt = (new Date().getTime() + config.expireAt).toString();

    localStorage.setItem(AuthBytelService.EXPIRESAT_CACHE_KEY, fakeExpireAt);
    localStorage.setItem(AuthBytelService.TOKENS_CACHE_KEY, JSON.stringify(config.tokens));
    localStorage.setItem(AuthBytelService.USERINFOS_CACHE_KEY, JSON.stringify(config.userInfos));
  }

  /** Charge les données d'authentification présentes dans le localStorage */
  private loadCurrentData(): void {
    if (!this.isExpired() && this.isTokenValid()) {
      this._tokens = JSON.parse(localStorage.getItem(AuthBytelService.TOKENS_CACHE_KEY) || '') as UserToken;
      this._userInfos = JSON.parse(localStorage.getItem(AuthBytelService.USERINFOS_CACHE_KEY) || '') as UserData;
      this._expiresAt = Number.parseInt(localStorage.getItem(AuthBytelService.EXPIRESAT_CACHE_KEY) || '', 10);

      this.initialiseRefreshSequence();

      this._onAccessTokenChanged.emit(this.accessToken);
      this._onUserNameChanged.emit(this.userName);
      this._onRolesChanged.emit(this.roles);
      this._onSirensChanged.emit(this.sirens);
      this._onUserInfosChanged.emit(this.userInfos);
      this.isAuthenticated = true;
      const isLoadedSubscription = this._onIsLoadedChanged.subscribe((isLoaded) => {
        if (isLoaded) {
          this.fetchUserInfos();
          isLoadedSubscription.cancel();
        }
      });
    } else {
      this.isAuthenticated = false;
    }
  }

  /** Initialise le timeout chargé de demander le renew du token */
  private initialiseRefreshSequence() {
    if (this._environment?.disableAuth) return;

    if (!Number.isNaN(this._expiresAt)) {
      if (this._nextTimeoutInterval) {
        clearTimeout(this._nextTimeoutInterval);
      }

      const nextOccurence = this._expiresAt - new Date().getTime() - 5000; // Je déclenche le time 5 secondes avant la fin du token
      this._nextTimeoutInterval = setTimeout(() => {
        this.isAuthenticated = false;
        if (this._tokens?.refresh_token) {
          this.refreshToken();
        } else {
          console.log('login from initialiseRefreshSequence');
          this.login();
        }
      }, nextOccurence);
    }
  }

  /** Charge la configuration du serveur OIDC */
  private loadOpenIdServerConfiguration(configuration: TypeConfig) {
    if (this.isAuthenticated === false) {
      this.isAuthenticating = true;
    }
    this.isLoaded = true;
  }

  // #region Getter / Setter
  /** Vérifie que les données sont bien présentes dans le localStorage */
  // eslint-disable-next-line class-methods-use-this
  private isInStorage(): boolean {
    return (
      localStorage.getItem(AuthBytelService.EXPIRESAT_CACHE_KEY) !== null &&
      localStorage.getItem(AuthBytelService.TOKENS_CACHE_KEY) !== null &&
      localStorage.getItem(AuthBytelService.USERINFOS_CACHE_KEY) !== null
    );
  }

  /** Vérifie si le token est expiré */
  // eslint-disable-next-line class-methods-use-this
  private isExpired(): boolean {
    const expiresAt = Number.parseInt(localStorage.getItem(AuthBytelService.EXPIRESAT_CACHE_KEY) || '', 10);
    const now = new Date().getTime();

    const isExpired = Number.isNaN(expiresAt) || expiresAt < now;
    // console.info('token is expired ? ', isExpired);
    return isExpired;
  }

  /** S'assure que le token n'est pas vide */
  // eslint-disable-next-line class-methods-use-this
  private isTokenValid(): boolean {
    const accessToken = localStorage.getItem(AuthBytelService.TOKENS_CACHE_KEY);
    // console.info('token is valid ? ', accessToken !== null);
    if (accessToken) return true;
    return false;
  }

  /** Indique si la configuration du serveur OIDC est chargée */
  get isLoaded(): boolean {
    return this._isLoaded;
  }

  /** Modifie l'état de chargement de la configuration du serveur OIDC */
  private set isLoaded(value: boolean) {
    this._isLoaded = value;
    this._onIsLoadedChanged.emit(this.isLoaded);
    this._onAuthenticationChanged.emit(this.isAuthenticated);
    this._onAuthenticatingChanged.emit(this.isAuthenticating);
  }

  /** Indique si l'authentification est en cours */
  get isAuthenticating() {
    return this._isLoaded && this._isAuthenticating;
  }

  /** Modifie l'état permettant de savoir si l'authentification est en cours */
  private set isAuthenticating(value: boolean) {
    this._isAuthenticating = value;
    this._onAuthenticatingChanged.emit(this.isAuthenticating);
  }

  /** Indique l'état d'authentification de l'utilisateur */
  get isAuthenticated() {
    return this._isLoaded && this._isAuthenticated;
  }

  /** Modifie l'état d'authentification de l'utilisateur */
  private set isAuthenticated(value: boolean) {
    this._isAuthenticated = value;
    this._onAuthenticationChanged.emit(this.isAuthenticated);
    if (!this._isAuthenticated) {
      AuthBytelService.cleanCache();
    }
  }

  /** Renvoi l'access_token */
  get accessToken(): string {
    return this._tokens?.access_token || '';
  }

  /** Renvoi l'id_token */
  get idToken(): string {
    return this._tokens?.id_token || '';
  }

  /** Renvoi le nom de l'utilisateur */
  get userName(): string {
    return this._userInfos?.designation || '';
  }

  /** Renvoi les rôles de l'utilisateur */
  get roles(): string[] {
    return this._userInfos?.utilisateurEffectif.roles || [];
  }

  /**
   * Renvoi les sirens de l'utilisateur
   */
  get sirens(): string[] {
    return this._userInfos?.utilisateurEffectif.sirens || [];
  }

  /** Renvoi l'objet userInfos du serveur d'identité */
  get userInfos(): UserData | undefined {
    return this._userInfos;
  }

  // #endregion

  /**
   * Gère la mémorisation temporaire de l'erreur
   * @param error erreur rencontrée et catchée
   */
  private onError(error: Error): void {
    this._lastError = error.message;
    this._onErrorRaised.emit(error.message);
  }

  /**
   * Callback suite à la demande des token de l'utilisateur connecté
   * @param response Réponse avec le jeton JWT de l'utilisateur
   */
  private onTokenResponseComplete(response: UserToken): void {
    try {
      this._expiresAt = new Date().getTime() + (response.expires_in || 0) * 1000;
      localStorage.setItem(AuthBytelService.EXPIRESAT_CACHE_KEY, this._expiresAt.toString());

      this._tokens = response;
      localStorage.setItem(AuthBytelService.TOKENS_CACHE_KEY, JSON.stringify(this._tokens));

      this._onAccessTokenChanged.emit(this.accessToken);
      this.fetchUserInfos();

      this.initialiseRefreshSequence();
    } catch (error) {
      console.error('failed to parse token response: ', error);
    }
  }

  /**
   * Récupère les données de l'utilisateur
   */
  private fetchUserInfos(): Promise<void> {
    if (this._environment?.disableAuth) {
      this.isAuthenticating = false;
      return Promise.resolve();
    }

    // console.info('fetch user infos');
    return Axios.get(`${this._environment?.userDataUrl}${this.accessToken}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.onUserInfoFetched(data);
        } else {
          this.isAuthenticated = false;
          this.isAuthenticating = false;
        }
      })
      .catch((error) => {
        console.error('failed to decode user infos: ', error);
      });
  }

  /**
   * Callback suite à la récupération des données de l'utilisateur
   * @param response Réponse avec le jeton JWT de l'utilisateur
   */
  private async onUserInfoFetched(user: UserData): Promise<void> {
    try {
      if (user.success) {
        const userInfo = user;
        this._userInfos = userInfo;
        localStorage.setItem(AuthBytelService.USERINFOS_CACHE_KEY, JSON.stringify(userInfo));
        this._onUserNameChanged.emit(this.userName);
        this._onRolesChanged.emit(this.roles);
        this._onSirensChanged.emit(this.sirens);
        this._onUserInfosChanged.emit(this.userInfos);
        this.isAuthenticated = true;
      } else {
        this.onError(new Error("Echec de la récupération des données de l'utilisateur"));
      }
      this.isAuthenticating = false;
    } catch (error) {
      console.error('failed to parse token response: ', error);
    }
  }

  /**
   * Méthode démarrant le processus d'authentification
   */
  login(): void {
    if (this._environment?.disableAuth) return;

    // /** Vide du cache */
    // AuthBytelService.cleanCache();

    const uri = `${this._environment?.loginUrl}${OAUTH_CALLBACK}`;
    window.location.href = encodeURI(uri);
  }

  /**
   * Méthode démarrant le processus de rafraichissement du token
   */
  refreshToken(): void {
    Axios.get(`${this._environment?.refreshTokensUrl}${this._tokens?.refresh_token}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.onTokenResponseComplete(data);
        } else {
          console.error('impossible de rafraichir le token');
          this._isAuthenticating = false;
        }
      })
      .catch((error) => {
        console.error('failed to refresh token: ', error);
      });
    this.onTokenRefresh.emit();
  }

  /**
   * Méthode démarrant le processus de contrôle de la redirection effectuée par le serveur d'autorisation
   */
  checkCallBack(): void {
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (!code) {
      console.error("Impossible de récupérer le code d'authorization");
      return;
    }

    const redirectUri = `${window.location.protocol}//${window.location.host}`;
    // on appel le back pour récupérer le token
    Axios.get(`${this._environment?.tokenUrl}${code}&redirectUri=${redirectUri}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.onTokenResponseComplete(data);
        } else {
          console.error('impossible de récupérer le token');
          this._isAuthenticating = false;
        }
      })
      .catch((error) => {
        console.error('failed to st: ', error);
      });
  }

  /**
   * Méthode permettant de contrôler que l'utilisateur à bien les rôles souhaités
   * @param rolesAllowed Liste des rôles souhaités
   * @returns true si c'est le cas, false sinon
   */
  hasRoles(rolesAllowed: string[]): boolean {
    if (this._environment?.type === 'PosteDEV' || this._environment?.type === 'OnlineDEV') return true;

    if (this._userInfos === undefined) return false;

    const roles = rolesAllowed.map((value) => {
      switch (value) {
        case UserRoles.ADMIN:
          return this._environment?.roleAdmin;
        case UserRoles.USER1:
          return this._environment?.roleUser1;
        default:
          return value;
      }
    });

    return this._userInfos?.utilisateurEffectif.roles.some((rolePossede) => roles.includes(rolePossede));
  }

  /**
   * Méthode de déconnexion
   */
  logout(): void {
    AuthBytelService.cleanCache();
    this.isAuthenticated = false;
    window.location.href = `${this._environment?.logoutUrl}`;
  }
}

export default AuthBytelService;
