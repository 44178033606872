import ErrorBoundary from '@components/error-boundary';
import { AllProviders } from '@contexts';
import App from '@domain/app';
import '@shared/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

const sc = document.createElement('script');
sc.type = 'text/javascript';
sc.src = 'https://cdn.tagcommander.com/4447/tc_BouyguesTelecomEntreprises_21.js';

fetch('/config/config.json')
  .then((res) => res.json())
  .then((config) => {
    if (config.ACTIVE_COOKIE) document.head.appendChild(sc);
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AllProviders>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AllProviders>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
