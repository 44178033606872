/* eslint-disable react/jsx-props-no-spreading */
import { useIsAuthenticated, useIsAuthenticating, useOAuth } from '@contexts/auth-provider';
import ErrorPage from '@domain/app/error';
import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { OAUTH_LOGIN } from './paths';

export type AuthenticatedRouteProps = RouteProps & { rolesAllowed?: string[]; [prop: string]: unknown };
export type AuthenticatedRouteType = React.FC<AuthenticatedRouteProps>;

const AuthenticatedRoute: AuthenticatedRouteType = ({
  rolesAllowed,
  component: Component,
  children,
  render: renderMethod,
  ...props
}: AuthenticatedRouteProps) => {
  const auth = useOAuth();
  const isAuthenticating = useIsAuthenticating();
  const isAuthenticated = useIsAuthenticated();

  if (!(Component || children || renderMethod)) {
    throw new Error("Aucun composant à rendre, merci de préciser un des attributs 'component', 'children' ou 'render'");
  }

  if (isAuthenticating) {
    return null;
  }

  if (isAuthenticated === false) {
    return <Redirect to={OAUTH_LOGIN} />;
  }

  if (rolesAllowed && !auth.hasRoles(rolesAllowed)) {
    return <ErrorPage />;
  }

  return (
    <Route
      {...props}
      render={(routeProps) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{Component ? <Component {...props} {...routeProps} /> : renderMethod?.(routeProps) || children}</>
      )}
    />
  );
};

export default AuthenticatedRoute;
