import {
  Columns,
  ColumnsItem,
  Hero,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  Title,
  TypographyColor,
} from '@bytel/trilogy-react-ts';
import NavigationItem from '@shared/components/navigation-item';
import YserForm from '@shared/components/ui-components/form/yser-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useData from './hook';

const NeutraliteCarbone: React.FC = () => {
  const { t } = useTranslation('domain/neutraliteCarbone');
  const { navigationItemConfig, config } = useData();

  return (
    <Section data-cy="neutraliteCarbone-container" className="is-paddingless">
      <NavigationItem config={navigationItemConfig} />
      <Hero backgroundSrc="/assets/images/headerNeutraliteCarbone.png" data-cy="hero">
        <Columns>
          <ColumnsItem className="is-offset-1 has-text-justified" size={6}>
            <Title typo={TypographyColor.TEXT_PRIMARY} data-cy="header-title" className="is-size-1 bold">
              {t('headTitle')}
            </Title>
            <Text level={TextLevels.TWO} typo={TypographyColor.TEXT_PRIMARY}>
              {t('headDescriptionOne')}
            </Text>
            <Text level={TextLevels.TWO} typo={TypographyColor.TEXT_PRIMARY}>
              {t('headDescriptionTwo')}
            </Text>
          </ColumnsItem>
        </Columns>
        <Columns>
          <ColumnsItem className="is-offset-1 has-text-justified" size={1} verticalCenter>
            <img
              src="/assets/images/label_bas_carbone.png"
              alt="Label Bas Carbone"
              className="navbar-logo is-aligned-center is-paddingless"
            />
          </ColumnsItem>
          <ColumnsItem size={5}>
            <Text
              level={TextLevels.THREE}
              className="is-italic is-selectable has-text-justified"
              typo={TypographyColor.TEXT_PRIMARY}
            >
              {t('headDescriptionDetailOne')}

              {t('headDescriptionDetailTwo')}
            </Text>
          </ColumnsItem>
        </Columns>
      </Hero>
      <Section>
        <Columns>
          <ColumnsItem className="is-offset-1 has-text-justified is-paddingless" size={10}>
            <Text level={TextLevels.THREE} typo={TypographyColor.TEXT_PRIMARY}>
              {t('upBandeau')}
            </Text>
          </ColumnsItem>
        </Columns>
      </Section>
      <Section className="has-background-grey-light has-text-justified">
        <Columns>
          <ColumnsItem className="is-offset-1 is-paddingless" size={10}>
            <Text level={TextLevels.THREE} typo={TypographyColor.TEXT_PRIMARY}>
              {t('bandeauDescription')}
            </Text>
          </ColumnsItem>
        </Columns>
      </Section>
      <Section>
        <Rows>
          <RowItem>
            <Columns>
              <ColumnsItem className="is-offset-1 has-text-justified is-paddingless" size={10}>
                <Text level={TextLevels.THREE} typo={TypographyColor.TEXT_PRIMARY}>
                  {t('downBandeau')}
                </Text>
              </ColumnsItem>
            </Columns>
          </RowItem>
          <RowItem>{/* space */}</RowItem>
          <RowItem className="is-paddingless">
            <Columns>
              <ColumnsItem className="is-offset-1 is-paddingless" size={6}>
                <YserForm dataCy="yser-neutraliteCarbone" config={config} t={t} direction="row" />
              </ColumnsItem>
            </Columns>
          </RowItem>
        </Rows>
      </Section>
    </Section>
  );
};

export default NeutraliteCarbone;
