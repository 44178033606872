/* eslint-disable @typescript-eslint/naming-convention */
import { buildContext, ContextFunction } from '@bytel/context-helpers';
import { PrismeLogger } from '@bytel/prisme-logger';
import { Text } from '@bytel/trilogy-react-ts';
import {
  MessageOrMessageBuilder,
  Severity,
  TypeError,
  TypeErrorContext,
  TypeErrorState,
} from '@components/error-boundary/types';
import { useLogger } from '@contexts/logger-provider';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';

const initialValue: TypeErrorState = {
  error: null,
  messageBuilder: (error) => (error ? error.message : null),
  severity: 'error',
  logToPrisme: false,
};

const setError: ContextFunction<TypeErrorState> = (
  store,
  setStore,
  errorState: Partial<TypeErrorState & { message?: string }>,
) => {
  const messageBuilder = errorState.message != null ? () => errorState.message as string : errorState.messageBuilder;
  setStore({
    ...initialValue,
    _logger: store._logger,
    ...errorState,
    messageBuilder,
  });

  if (errorState.error) {
    const message = messageBuilder?.(errorState.error) || 'Une erreur est survenue dans React';
    // eslint-disable-next-line no-console
    console.error(`${message}: `, errorState.error);
    if (errorState.logToPrisme) {
      store._logger?.logToPrisme(message, {
        erreur: errorState.error,
      });
    }
  }
};

const catchError: ContextFunction<TypeErrorState> = (
  store,
  setStore,
  messageOrMessageBuilder?: MessageOrMessageBuilder,
  severity: Severity = 'error',
) => {
  return (error?: TypeError) => {
    // eslint-disable-next-line no-console
    console.error(error);
    setError(store, setStore, {
      error,
      severity,
      messageBuilder:
        typeof messageOrMessageBuilder === 'string' ? () => messageOrMessageBuilder : messageOrMessageBuilder,
    } as TypeErrorState);
  };
};

const resetError: ContextFunction<TypeErrorState> = (store, setStore) => {
  setStore({ ...initialValue, _logger: store._logger });
};

const _setLogger: ContextFunction<TypeErrorState> = (store, setStore, logger: PrismeLogger) => {
  setStore({ ...store, _logger: logger });
};

const { context: ErrorContext, provider: ErrorContextProvider } = buildContext<TypeErrorState, TypeErrorContext>(
  initialValue,
  { setError, resetError, catchError, _setLogger },
);

const SnackBarHandler: React.FC = () => {
  const errorHandler = React.useContext(ErrorContext);

  const message = React.useMemo(() => {
    return errorHandler.messageBuilder?.(errorHandler.error);
  }, [errorHandler]);

  return errorHandler.severity !== 'hidden' ? (
    <Snackbar
      onClose={errorHandler.resetError}
      open={message != null && errorHandler.error?.response?.status !== 401}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Alert onClose={errorHandler.resetError} severity={errorHandler.severity}>
        <Text level="ONE" style={{ color: 'inherit' }}>
          {message}
        </Text>
        {process.env.NODE_ENV === 'development' && errorHandler.error && (
          <Text level="ONE" style={{ color: 'inherit' }}>
            {errorHandler.error.message}
          </Text>
        )}
      </Alert>
    </Snackbar>
  ) : null;
};

export const useErrorHandler = () => {
  return React.useContext(ErrorContext);
};

const ErrorLoggerProvider: React.FC = ({ children }) => {
  const logger = useLogger();
  const errorHandler = useErrorHandler();
  React.useEffect(() => {
    errorHandler._setLogger(logger);
  }, [errorHandler, logger]);

  return (
    <>
      {children}
      <SnackBarHandler />
    </>
  );
};

const ErrorHandler: React.FC = ({ children }) => {
  return (
    <ErrorContextProvider>
      <ErrorLoggerProvider>
        {children}
        <SnackBarHandler />
      </ErrorLoggerProvider>
    </ErrorContextProvider>
  );
};

export default ErrorContext;
export { ErrorHandler };
