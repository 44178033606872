/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/require-default-props */
import {
  Button,
  ButtonMarkup,
  InfoBlock,
  InfoBlockAction,
  InfoBlockContent,
  InfoBlockHeader,
  InfoBlockStatus,
  Section,
  Text,
  VariantState,
} from '@bytel/trilogy-react-ts';
import ErrorContext from '@contexts/error-context';
import * as React from 'react';
import { Link as routerLink } from 'react-router-dom';
import { TypeErrorContext, TypeErrorState } from './types';

export type ErrorBoundaryProps = {
  mode?: 'page';
  silent?: true;
};

export type StateType = {
  hasError: boolean;
  error?: Error;
  info?: React.ErrorInfo;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  // eslint-disable-next-line react/static-property-placement
  static contextType = ErrorContext;

  // eslint-disable-next-line react/state-in-constructor
  state: StateType = {
    hasError: false,
    error: undefined,
    info: undefined,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('[error-boundary]', { error, info });
    this.setState({
      hasError: true,
      error,
      info,
    });
    const setError = (this.context as TypeErrorContext & TypeErrorState)?.setError;
    if (setError) {
      setError({
        error,
        severity: 'hidden',
        logToPrisme: true,
      });
    }
  }

  render() {
    /* eslint-disable react/prop-types */
    const { children, mode, silent } = this.props;
    const { hasError, error, info } = this.state;
    if (!hasError) return children;
    if (silent) return null;
    if (mode === 'page') {
      return (
        <Section>
          <InfoBlock>
            <InfoBlockHeader status={InfoBlockStatus.WARNING}>Une erreur est survenue</InfoBlockHeader>
            <InfoBlockContent>
              <Text>
                La page à laquelle vous essayez d'accéder est momentanément indisponible. Veuillez réessayer
                ultérieurement.
              </Text>
              {process.env.NODE_ENV === 'development' && (
                <>
                  <pre className="has-text-left is-size-8" style={{ maxHeight: 160, whiteSpace: 'pre-wrap' }}>
                    {error?.name}
                    <br />
                    {error?.message || "Erreur d'origine inconnue"}
                    <br />
                    {info?.componentStack}
                  </pre>
                  <Text has-text-grey is-italic is-size-8 is-pulled-left>
                    ^ ces traces ne sont affichées qu'en environnement de développement
                  </Text>
                </>
              )}
            </InfoBlockContent>
            <InfoBlockAction>
              <Button variant={VariantState.PRIMARY} markup={ButtonMarkup.A} to="/" routerLink={routerLink}>
                Retour à l'accueil
              </Button>
            </InfoBlockAction>
          </InfoBlock>
        </Section>
      );
    }

    return (
      <InfoBlock>
        <InfoBlockHeader status={InfoBlockStatus.WARNING}>Une erreur est survenue</InfoBlockHeader>
        <InfoBlockContent>
          <Text>
            La page à laquelle vous essayez d'accéder est momentanément indisponible.
            <br />
            Veuillez réessayer ultérieurement.
          </Text>
        </InfoBlockContent>
      </InfoBlock>
    );
  }
}
