import React from 'react';

const AppVersion: React.FC = ({ children }) => {
  return (
    <div data-cy="container-app-version" style={{ display: 'none' }}>
      {children}
    </div>
  );
};

export default AppVersion;
