import { SirenList } from '@generated/YSER-v1';
import APIServicesBase from './APIServicesBase';
import { FiltersBase } from './types';
import { setQueryParams } from './utils';

export default class SirenAPIService extends APIServicesBase {
  listerSirens(href?: string, options?: FiltersBase): Promise<SirenList | undefined> {
    if (href === undefined) {
      return Promise.resolve(undefined);
    }

    const url = this.getUrl(href);
    setQueryParams(url, options);
    return this.bquery.get<SirenList>(url.toString());
  }
}
