import React from 'react';
import './style.css';
import { SplashScreenProps, SplashScreenStatus } from './types';

const SplashScreen: React.FC<SplashScreenProps> = ({ children, text, status = SplashScreenStatus.LOADING }) => {
  switch (status) {
    case SplashScreenStatus.ERROR:
      return (
        <div className="h-100vh flex flex-v-centered flex-h-centered" data-cy="splashScreen-error">
          <span className="cross cross-red" />
          &nbsp;&nbsp;{text ?? children ?? 'Une erreur est survenue'}
        </div>
      );

    case SplashScreenStatus.SUCCESS:
      return (
        <div className="h-100vh flex flex-v-centered flex-h-centered" data-cy="splashScreen-success">
          <span className="check" />
          &nbsp;&nbsp;{text ?? children ?? 'Action réussie'}
        </div>
      );

    case SplashScreenStatus.LOADING:
    default:
      return (
        <div className="h-100vh flex flex-v-centered flex-h-centered" data-cy="splashScreen-normal">
          <span className="icon h-100p">
            <i className="spinner spinner-icon" />
          </span>
          &nbsp;&nbsp;{text ?? children ?? 'Chargement en cours ...'}
        </div>
      );
  }
};

export default SplashScreen;
