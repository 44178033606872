/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */

import * as time from '@helpers/time';
import SirenAPIService from '@services/api/SirenAPIService';
import { FiltersBase } from '@services/api/types';
import { ErreurFonctionnelle, SirenList } from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React, { createContext } from 'react';
import { UseQueryResult } from 'react-query';
import { useOverrideQuery } from '../helpers';

const SirenAPIContext = createContext<SirenAPIService | null>(null);
const SirenCacheKey = 'Sirens';

export type SirenAPIProviderProps = {
  mockInstance?: SirenAPIService;
};

export const SirenAPIProvider: React.FC<SirenAPIProviderProps> = ({ children, mockInstance = undefined }) => {
  const bquery = useBQuery();

  const instanceAPI = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new SirenAPIService(bquery);
  }, [mockInstance, bquery]);

  return <SirenAPIContext.Provider value={instanceAPI}>{children}</SirenAPIContext.Provider>;
};

export const useSirenAPI = (): SirenAPIService => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return React.useContext(SirenAPIContext)!;
};

/**
 * Retourne la liste des sirens
 * @param href l'url pour les obtenir
 * @param options
 * @returns
 */
export const useListerSirens = (
  href?: string,
  options?: FiltersBase,
): UseQueryResult<SirenList | undefined, AxiosError<ErreurFonctionnelle>> => {
  const SirenAPI = useSirenAPI();
  return useOverrideQuery<SirenList | undefined, AxiosError<ErreurFonctionnelle>, SirenList | undefined>(
    [SirenCacheKey, href, options],
    () => SirenAPI.listerSirens(href, options),
    {
      cacheTime: time.fromMinutes(10),
      staleTime: time.fromMinutes(10),
    },
  );
};
