export type UserToken = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token: string;
  id_token: string;
  success: boolean;
};

export type UserType = {
  uid: number;
  civilite: string;
  prenom: string;
  nom: string;
  email: string;
};

export type UserData = {
  designation: string;
  utilisateurReel: UserType;
  utilisateurEffectif: UserType & {
    roles: string[];
    sirens: string[];
    infosLigne: string | null;
  };
  iss: string;
  auth_time: number;
  jwtLifetime: string | null;
  app: string;
  appFrom: string;
  appTo: string;
  sessionId: number;
  uid: string;
  euid: number;
  ruid: number;
  uidSubstituant: null;
  uidSubstitue: null;
  isValid: true;
  codeErreur: null;
  famille: string;
  civilite: string;
  nom: string;
  prenom: string;
  email: string;
  active: boolean;
  sub: string;
  success: boolean;
};

export type AuthenticationError = string | undefined | null;
export type OAuthConfiguration = {
  type: string;

  loginUrl: string;
  logoutUrl: string;

  refreshTokensUrl: string;
  tokenUrl: string;
  userDataUrl: string;

  roleAdmin: string;
  roleUser1: string;

  disableAuth: boolean;
};

export const EXPIRESAT_CACHE_KEY = 'expiresAt';
export const TOKENS_CACHE_KEY = 'tokens';
export const USERINFOS_CACHE_KEY = 'userInfos';
