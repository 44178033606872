import { assembly } from '@services';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import SplashScreen from '../splashscreen';
import AppContent from './content';
import Footer from './footer';
import AppHeader from './header';
import './style.css';
import AppVersion from './version';

const AppShell: React.FC = () => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'loading',
  });

  return (
    <Suspense fallback={<SplashScreen text={t('global')} />}>
      <AppHeader />
      <AppContent />
      <AppVersion>{assembly.version}</AppVersion>
      <Footer />
    </Suspense>
  );
};

export default AppShell;
