import { Link, Section, Text, TextLevels, Title, TitleLevels, TrilogyColor } from '@bytel/trilogy-react-ts';
import { useTranslation } from 'react-i18next';

const BoxConfigMobile: React.FC = () => {
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'configurationMobile',
  });

  return (
    <Section data-cy="configurationMobile-container" background={TrilogyColor.GREY_LIGHT}>
      <Title style={{ cursor: 'default', pointerEvents: 'none' }} level={TitleLevels.ONE} className="has-text-centered">
        {t('titre')}
        <Link className="is-plain" to="none">
          <Title level={TitleLevels.ONE} className="has-text-secondary">
            {t('titreSuite')}
          </Title>
        </Link>
      </Title>
      <Text level={TextLevels.ONE} data-cy="configurationMobile-description" className="has-text-centered">
        {t('description')}
      </Text>
      <Text level={TextLevels.ONE} data-cy="configurationMobile-description" className="has-text-centered">
        {t('descriptionOne')}
        <Link
          data-cy="link-configuration-mobile"
          className="has-text-secondary"
          onClick={() => {
            window.open(t('urlConfigurationMobile'), '_blank');
          }}
          to={t('urlConfigurationMobile')}
        >
          <Text markup="span" level={TextLevels.ONE}>
            {t('descriptionLink')}
          </Text>
        </Link>
        {t('descriptionTwo')}
      </Text>
    </Section>
  );
};

export default BoxConfigMobile;
