import {
  Alignable,
  Button,
  ButtonList,
  Card,
  CardContent,
  Columns,
  ColumnsItem,
  Text,
  TextLevels,
  VariantState,
  View,
} from '@bytel/trilogy-react-ts';
import { truncateText } from '@shared/misc/helpers/strings';
import React from 'react';
import useData from './hook';
import './style.css';
import { ItemPartenaireProps } from './type';

const ItemPartenaire: React.FC<ItemPartenaireProps> = ({ partenaire }) => {
  const isFetching = false;
  const isLoading = false;
  const { id, name, description, logo, estActif, url } = partenaire;
  const { togglePartenaireCallback, redirectToPartenaireCallback, t } = useData(partenaire);

  return (
    <ColumnsItem size={3} className="is-marginless">
      <Card
        align={Alignable.ALIGNED_CENTER}
        skeleton={isLoading || isFetching}
        className="tags has-addons is-fullheight cardBtn"
        data-cy={`partenaires-item-${id}`}
        onClick={redirectToPartenaireCallback}
      >
        <CardContent className="">
          <img
            data-cy={`partenaires-item-image-${id}`}
            src={logo}
            alt={name}
            style={{
              objectFit: 'contain',
              maxWidth: '120px',
              minWidth: '180px',
              maxHeight: '60px',
              minHeight: '60px',
              display: 'block',
              margin: 'auto',
            }}
          />

          <View style={{ minHeight: '40px', maxHeight: '40px', padding: '20px', paddingBottom: '70px' }}>
            <Text level={TextLevels.TWO} data-cy={`partenaires-item-description-${id}`}>
              {truncateText(description, 65)}{' '}
              {/*
               Pour le fut, on fait disparaitre ce lien et on ajoute du style en dur pour de l'ombre sur la carte
               Il faudra voir dans la V2 pour la nouvelle version
              */}
              {/* {url && (
                <Text level={TextLevels.TWO} className="link" typo={TypographyColor.TEXT_SECONDARY} markup="span">
                  {t('item.moreInfos')}
                </Text>
              )} */}
            </Text>
          </View>
          <Columns className={`${estActif ? '' : 'has-text-primary'}`}>
            <ColumnsItem className="is-6 is-offset-3">
              <ButtonList centered>
                <Button
                  data-cy={`partenaires-item-button-${id}`}
                  fullwidth
                  variant={estActif ? VariantState.SECONDARY : VariantState.PRIMARY}
                  className="is-outlined"
                  onClick={togglePartenaireCallback}
                  align={Alignable.ALIGNED_CENTER}
                  small
                >
                  {/*
                     Anno trilogy observée, malgré la MAJ du state l'icone n'est pas refresh 
                     <Icon name={estActif ? IconName.CHECK : IconName.TIMES} size={IconSize.SMALL} /> 
                  */}

                  <span className="icon is-small">
                    <i className={estActif ? 'tri-check' : 'tri-times'} />
                  </span>
                  <Text level={TextLevels.TWO}>{t(`item.${estActif ? 'activated' : 'deactivated'}`)}</Text>
                </Button>
              </ButtonList>
            </ColumnsItem>
          </Columns>
        </CardContent>
      </Card>
    </ColumnsItem>
  );
};

export default ItemPartenaire;
