import { ErreurFonctionnelle } from '@services/interfaces/generated/YSER-v1';
import { useNotify } from '@yama/shared-components/dist/components/notify';
import { NotifyDuration, NotifySeverity, NotifyType } from '@yama/shared-components/dist/components/notify/type';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  FetchQueryOptions,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { I18nConfigType } from '../types';

const stringFormat = (str: string, args?: string[]): string => {
  if (args === undefined) return str;

  return str.replace(/{(\d+)}/g, (match: string, number: number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

const handleApiError = (error: AxiosError<ErreurFonctionnelle>): NotifyType => {
  const apiError = error.response?.data;
  const text = apiError?.description ?? '';
  const params = apiError?.parametres ?? [];

  return {
    title: apiError?.categorie,
    text: stringFormat(text, params),
    duration: NotifyDuration.PERSISTANT,
    severity: NotifySeverity.ERROR,
  };
};

const handleApiSuccess = (message: string): NotifyType => {
  return {
    text: message,
    duration: NotifyDuration.LONG,
    severity: NotifySeverity.SUCCESS,
  };
};

const useOverrideQuery = <
  TQueryFnData,
  TError extends AxiosError<ErreurFonctionnelle>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFunc: QueryFunction<TQueryFnData, TQueryKey>,
  queryOpts?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> => {
  const { pushNotification } = useNotify();

  const handleError = (error: TError) => {
    if (queryOpts?.onError) queryOpts?.onError(error);

    const notif = handleApiError(error);
    if (notif.text) pushNotification(notif);
  };

  return useQuery(
    queryKey,
    queryFunc,
    queryOpts && {
      ...queryOpts,
      onError: handleError,
    },
  );
};

const useOverrideMutation = <
  TData,
  TError extends AxiosError<ErreurFonctionnelle>,
  TVariables = void,
  TContext = undefined,
>(
  i18nConfig: I18nConfigType<TData>,
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const { pushNotification } = useNotify();

  const { t } = useTranslation('shared/notify');

  const handleError = (error: TError, variables: TVariables, context?: TContext) => {
    if (options?.onError) options?.onError(error, variables, context);

    const notif = handleApiError(error);
    if (notif.text) pushNotification(notif);
  };

  const handleSuccess = (data: TData, variables: TVariables, context: TContext) => {
    if (options?.onSuccess) options?.onSuccess(data, variables, context);

    const opt = {};
    i18nConfig.options?.forEach((x) => {
      opt[x.key] = data[x.prop];
    });
    pushNotification(handleApiSuccess(t(i18nConfig.key, opt)));
  };
  return useMutation(mutationFn, { ...options, onError: handleError, onSuccess: handleSuccess });
};

const overrideFetchQuery = async <TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryClient: QueryClient,
  pushNotification: (n: NotifyType) => void,
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): Promise<TData> => {
  let result: TData;
  try {
    result = await queryClient.fetchQuery(queryKey, queryFn, options);
  } catch (error) {
    const notif = handleApiError(error);
    if (notif.text) pushNotification(notif);
    return error;
  }
  return result;
};

export { useOverrideQuery, useOverrideMutation, overrideFetchQuery };
