import { IconName } from '@bytel/trilogy-react-ts';
import {
  ACTUALITES_LIST_PATH,
  CONTRIBUTION_NEUTRALITE_PATH,
  ECOASTUCES_LIST_PATH,
  PARTENAIRES_PAGE_PATH,
} from '@router/paths';
import { RouteParameters } from '@router/types';
import { BtnNavigationProps } from '@shared/components/button-navigation/type';
import { useObtenirConsommationFlotte } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import { transformUnit } from '@shared/misc/helpers/data';
import { dateToFormat } from '@shared/misc/helpers/time';
import UrlToken from '@shared/misc/helpers/urlToken';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsommationFlotteData, ConsommationFlotteRecap } from './box-consommation-flotte/type';

const useData = () => {
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'navButtons',
  });

  const siren = useSiren();

  const buttonNeutraliteCarbone = useMemo<BtnNavigationProps>(() => {
    return {
      dataCy: 'home-neutralite-btn',
      titre: t('myNeutralite'),
      description: t('myNeutraliteDesc'),
      routeRef: {
        href: CONTRIBUTION_NEUTRALITE_PATH.replace(
          RouteParameters.sirenId,
          UrlToken.fromXLink(siren?._links?.listerActualites).toString(),
        ),
      },
      iconName: IconName.HANDS_HELPING,
    };
  }, [t, siren?._links?.listerActualites]);

  const { data } = useObtenirConsommationFlotte(siren?._links?.obtenirConsommationFlotte?.href);

  const last = useMemo<ConsommationFlotteRecap | undefined>(() => {
    if (data?.resultsCount) {
      const result = data.items[data.resultsCount - 1];
      const calculatedData = transformUnit(result.data, 'data');
      return { ...result, data: calculatedData, co2eq: transformUnit(result.co2eq, 'co2eq') };
    }
    return undefined;
  }, [data]);

  const consoData = useMemo<ConsommationFlotteData[] | undefined>(() => {
    return data?.items.map((item) => {
      return { x: dateToFormat(item.date), y: item.co2eq / 1000 };
    });
  }, [data?.items]);

  const buttons = useMemo<BtnNavigationProps[]>(() => {
    return [
      {
        dataCy: 'home-ecoAstuces-btn',
        titre: t('myEcoAstuces'),
        description: t('myEcoAstucesDesc'),
        routeRef: siren?._links?.listerEcoAstuces
          ? {
              href: ECOASTUCES_LIST_PATH.replace(
                RouteParameters.sirenId,
                UrlToken.fromXLink(siren?._links?.listerEcoAstuces).toString(),
              ),
            }
          : undefined,
        iconName: IconName.ECO_CONCEPTION,
        btnName: t('voirEcoAstuces'),
      },
      {
        dataCy: 'home-actualites-btn',
        titre: t('myActualite'),
        description: t('myActualiteDesc'),
        routeRef: siren?._links?.listerActualites
          ? {
              href: ACTUALITES_LIST_PATH.replace(
                RouteParameters.sirenId,
                UrlToken.fromXLink(siren?._links?.listerActualites).toString(),
              ),
            }
          : undefined,
        iconName: IconName.FILE_LIST,
        btnName: t('voirActualites'),
      },
      {
        dataCy: 'home-partenaires-btn',
        titre: t('myPartenaire'),
        description: t('myPartenaireDesc'),
        routeRef: siren?._links?.listerPartenaires
          ? {
              href: PARTENAIRES_PAGE_PATH.replace(
                RouteParameters.sirenId,
                // Fonctionne pas encore il faut le modifier
                UrlToken.fromXLink(siren?._links?.listerPartenaires).toString(),
              ),
            }
          : undefined,
        iconName: IconName.HANDS_HELPING,
        btnName: t('modifierPartenaire'),
      },
    ];
  }, [t, siren?._links?.listerEcoAstuces, siren?._links?.listerActualites, siren?._links?.listerPartenaires]);

  return {
    buttons,
    buttonNeutraliteCarbone,
    data,
    last,
    consoData,
  };
};

export default useData;
