import { Input, InputStatus } from '@bytel/trilogy-react-ts';
import { useMemo } from 'react';
import { executeFormikValidation } from '../../helpers';
import { BaseInputTextProps, TriggeredEvent } from '../../type';
import { isEnter, isSearchInput, matchEvents } from '../util';

const InputTrilogy = <T extends object>(props: BaseInputTextProps<T>) => {
  const { fieldConfig, inputName, dataCy, setInputValue, formik, placeholder, type, displayErrors } = props;
  const {
    customIcon,
    onIconClick,
    disabled,
    onChangeEvent,
    triggeredEvent = TriggeredEvent.OnMouseLeave,
  } = fieldConfig;
  const { touched, values, errors } = formik;

  const isSearch = useMemo(() => {
    return isSearchInput(placeholder);
  }, [placeholder]);

  return (
    <Input
      data-cy={dataCy}
      type={type}
      placeholder={placeholder}
      hasIcon={customIcon !== undefined || isSearch}
      customIcon={customIcon || undefined}
      status={errors[inputName] && displayErrors ? InputStatus.ERROR : InputStatus.DEFAULT}
      value={values[inputName]}
      onChange={({ inputValue }) => {
        setInputValue(inputName, inputValue);
        if (matchEvents(triggeredEvent, TriggeredEvent.OnChange) && onChangeEvent) {
          onChangeEvent(formik, values as T, inputValue);
        }
      }}
      onMouseLeave={() => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnMouseLeave) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      disabled={disabled ? executeFormikValidation(disabled, values as T) : false}
      onIconClick={() => onIconClick && onIconClick()}
      onBlur={() => {
        touched[inputName] = true;

        if (matchEvents(triggeredEvent, TriggeredEvent.OnBlur) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      onClick={() => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnClick) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      onFocus={() => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnFocus) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      onKeyPress={({ inputKeyCode }) => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnKeyPress) && isEnter(inputKeyCode) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      onKeyUp={({ inputKeyCode }) => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnKeyUp) && isEnter(inputKeyCode) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
      onMouseEnter={() => {
        if (matchEvents(triggeredEvent, TriggeredEvent.OnMouseEnter) && onChangeEvent) {
          onChangeEvent(formik, values as T, values[inputName]);
        }
      }}
    />
  );
};
export default InputTrilogy;
