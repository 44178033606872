// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setItem(key: string, value: any, persistance: 'local' | 'session' = 'local'): void {
  const storage: Storage = persistance === 'session' ? sessionStorage : localStorage
  storage.setItem(key, JSON.stringify(value))
}

export function getItem<T = any>(key: string, persistance: 'local' | 'session' = 'local'): T | null {
  const storage: Storage = persistance === 'session' ? sessionStorage : localStorage
  const value = storage.getItem(key)
  return value ? (JSON.parse(value) as T) : null
}

export function removeItem(key: string, persistance: 'local' | 'session' = 'local'): void {
  const storage: Storage = persistance === 'session' ? sessionStorage : localStorage
  storage.removeItem(key)
}
