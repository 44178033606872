/**
 * AutoComplete Interface
 */

export type AutoCompleteOption = {
  key: string;
  value: string;
};

export enum AutoCompleteResultState {
  // Default Value. Data is managed into AutoComplete
  OnSearch = 0,
  Data = 1,
}
export type AutoCompleteResult<T> = {
  items?: T[];
  state?: AutoCompleteResultState;
};

export type OnSearchDelegateType<T> = (value: string) => Promise<AutoCompleteResult<T>> | Promise<T[] | undefined>;

export interface AutoCompleteProps<T> {
  dataCy?: string;
  children?: React.ReactNode;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: T;
  data?: T[];
  onSelected: (value: T | undefined) => void;
  onSearchAsync: OnSearchDelegateType<T>;
  searchDelay?: number;
  allowEmptyValueOnBlur?: boolean;
  mapToOption: (value: T) => AutoCompleteOption;
  onBlur?: () => void;
}

export interface SearchItem<T> {
  text: string;
  suggestions: T[];
}
