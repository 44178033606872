import {
  Columns,
  ColumnsItem,
  Input,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import { ECOASTUCE_EDIT_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import EcoAstuceBox from '@shared/components/box';
import NavigationItem from '@shared/components/navigation-item';
import { KeyCodeEvent } from '@shared/constants';
import useUrlToken from '@shared/hooks/use-url-token';
import UrlToken from '@shared/misc/helpers/urlToken';
import React from 'react';
import useData from './hook';

const ListerEcoAstucesBTE: React.FC = () => {
  const { isFetching, isLoading, t, navigationItemConfig, handleSearchClick, ecoAstucesBTE } = useData();
  const sirenToken = useUrlToken(RouteParameters.sirenId);
  return (
    <Section data-cy="lister-ecoAstuces-bte-container" skeleton={isFetching || isLoading} className="is-paddingless">
      <NavigationItem config={navigationItemConfig} />
      <Section background={TrilogyColor.WHITE}>
        <Rows>
          <RowItem>
            <Section className="is-paddingless">
              <Text level={TextLevels.ONE} data-cy="ecoastuces-bte-search-description">
                {t('search.description')}
              </Text>
              <Input
                name="ecoastuce-bte-searchbar"
                placeholder={t('search.place-holder')}
                data-cy="ecoastuces-bte-searchbar"
                search
                hasIcon
                onIconClick={handleSearchClick}
                onKeyUp={(e) => e.inputKeyCode === KeyCodeEvent.OnEnterPressed && handleSearchClick(e)}
              />
            </Section>
          </RowItem>
          <RowItem />
          <RowItem>
            <Columns multiline>
              {ecoAstucesBTE.map(({ id, title, description, image, _links }) => (
                <ColumnsItem size={4} key={id}>
                  <EcoAstuceBox
                    dataCy={id}
                    title={title}
                    text={description}
                    src={image ?? ''}
                    detailLink={ECOASTUCE_EDIT_PATH.replace(RouteParameters.sirenId, sirenToken.toString()).replace(
                      RouteParameters.ecoAstuceId,
                      UrlToken.fromXLink(_links.self).toString(),
                    )}
                    skeleton={isLoading || isFetching}
                  />
                </ColumnsItem>
              ))}
            </Columns>
          </RowItem>
        </Rows>
      </Section>
    </Section>
  );
};

export default ListerEcoAstucesBTE;
