import { RouteParameters } from '@router/types';
import { useOAuth } from '@shared/contexts/auth-provider';
import useUrlToken from '@shared/hooks/use-url-token';
import { useCallback } from 'react';
import { UseErrorProps } from './type';

const useData = (): UseErrorProps => {
  const erreurToken = useUrlToken(RouteParameters.erreurId);

  const auth = useOAuth();
  const onClick = useCallback(() => {
    auth.logout();
  }, [auth]);

  return {
    onClick,
    erreurToken: erreurToken.toString() || '401',
  };
};

export default useData;
