import {
  Box,
  Button,
  ButtonMarkup,
  Container,
  Icon,
  IconName,
  IconSize,
  RowItem,
  Rows,
  Section,
  VariantState,
} from '@bytel/trilogy-react-ts';
import * as React from 'react';
import { Link as routerLink } from 'react-router-dom';

const WorkInProgressPage: React.FC = () => {
  const text = "Retour à l'accueil";

  return (
    <Section>
      <Container>
        <Box>
          <Rows>
            <RowItem>
              <Icon size={IconSize.MEDIUM} content="La page n'a pas encore été développée" name={IconName.TOOLS} />
            </RowItem>
            <RowItem>
              <Button variant={VariantState.PRIMARY} markup={ButtonMarkup.A} to="/" routerLink={routerLink}>
                {text}
              </Button>
            </RowItem>
          </Rows>
        </Box>
      </Container>
    </Section>
  );
};

export default WorkInProgressPage;
