import { EffectCallback, useEffect, useRef } from 'react';

const useEffectOnce = (callback: EffectCallback) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const destroyFunc = useRef<void | any>();
  const calledOnce = useRef(false);
  const renderAfterCalled = useRef(false);

  if (calledOnce.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    calledOnce.current = true;
    destroyFunc.current = callback();

    // eslint-disable-next-line consistent-return
    return () => {
      if (renderAfterCalled.current && destroyFunc.current) {
        destroyFunc.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useEffectOnce;
