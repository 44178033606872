import { ApiDescription } from '@generated/YSER-v1';
import APIServicesBase from './APIServicesBase';

export default class APIDescriptorService extends APIServicesBase {
  /**
   * Description de l'API
   */
  private descriptor?: ApiDescription;

  /**
   * Détermine si la description de l'API a déjà été chargée
   */
  isLoaded(): boolean {
    return !!this.descriptor;
  }

  /**
   * Charge la description de l'API
   */
  async loadAsync(): Promise<ApiDescription> {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!!this.descriptor) {
      this.descriptor = await this.bquery.get<ApiDescription>('/api');
    }

    return this.descriptor;
  }
}
