import { Columns, ColumnsItem, Switch } from '@bytel/trilogy-react-ts';
import React from 'react';
import { SwitchProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const SwitchComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label,
  fieldConfig,
  displayErrors,
  displayConfig,
}: SwitchProps<T>) => {
  const { onChangeEvent, required } = fieldConfig;
  const { values } = formik;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <Switch
          data-cy={`switch-type-${dataCy}-${inputName}`}
          name={inputName}
          label={label}
          checked={values[inputName]}
          onChange={({ switchState }) =>
            onChangeEvent
              ? [setInputValue(inputName, switchState), onChangeEvent(formik, values as T, switchState.toString())]
              : setInputValue(inputName, switchState)
          }
        />
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default SwitchComponent;
