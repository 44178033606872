import { Columns, Container, TextLevels, Title } from '@bytel/trilogy-react-ts';
import React from 'react';
import ItemPartenaire from '../item-partenaire';
import { GroupePartenaireProps } from './type';

const GroupePartenaire: React.FC<GroupePartenaireProps> = ({ category, items }) => {
  return (
    <Container fluid className="is-marginless">
      <Title level={TextLevels.TWO} data-cy={`partenaires-groupe-title-${category}`}>
        {category}
      </Title>
      <Columns multiline data-cy={`partenaires-groupe-columns-${category}`}>
        {items.map((item) => (
          <ItemPartenaire key={item.id} partenaire={item} />
        ))}
      </Columns>
      <br />
    </Container>
  );
};

export default GroupePartenaire;
