import { EcoAstuce } from '@services/interfaces/generated/YSER-v1';
import { useSupprimerEcoAstuces } from '@shared/contexts/api';
import { useCallback, useState } from 'react';
import { UseDataProps } from './type';

const useData = (ecoAstuce: EcoAstuce): UseDataProps => {
  const [isDeletable] = useState<boolean>(ecoAstuce._actions.supprimerEcoAstuce !== undefined);

  const supprimerEcoAstuces = useSupprimerEcoAstuces(ecoAstuce._actions.supprimerEcoAstuce);
  const confirmDelete = useCallback(async () => {
    await supprimerEcoAstuces.mutateAsync();
  }, [supprimerEcoAstuces]);

  return { confirmDelete, isDeletable };
};

export default useData;
