import {
  Box,
  Columns,
  ColumnsItem,
  RowItem,
  Rows,
  Text,
  TextLevels,
  Title,
  TitleLevels,
} from '@bytel/trilogy-react-ts';

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import GraphExporter from '../graph-exporter';
import useData from './hook';

import theme from '../../../shared/misc/helpers/theme';

const axisStyle = {
  axis: { stroke: 'transparent' },
  ticks: { stroke: 'transparent' },
  tickLabels: {
    fill: 'white',
    fontSize: 8,
    fontFamily: 'Bouygues Read',
  },
};

const toolTipStyle = {
  fill: theme.colors.blue,
  fontSize: 8,
};

const flyoutStyle = {
  stroke: theme.colors.blue,
  strokeWidth: 1,
};

const victoryLineStyle = {
  data: {
    stroke: theme.colors.lightBlue,
    strokeWidth: 2,
    strokeLinecap: 'round',
  },
};

const BoxConsommationFlotte: React.FC = () => {
  const { consoData, unitToShow } = useData();
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'consommationFlotte',
  });

  return (
    <Box
      className="is-fullheight is-fullwidth has-border-radius-large is-primary"
      data-cy="home-boxConsommationFlotte-box"
    >
      <Rows>
        <RowItem>
          <Columns>
            <ColumnsItem size={9}>
              <Title level={TitleLevels.ONE} inverted data-cy="boxConsommationFlotte-titre">
                {t('titre')}
              </Title>
            </ColumnsItem>
            <ColumnsItem size={3} className="has-text-right">
              <GraphExporter />
            </ColumnsItem>
          </Columns>
          <Title level={TitleLevels.THREE} inverted data-cy="boxConsommationFlotte-graphtitre">
            {t('graphtitre')}
          </Title>
        </RowItem>
        <RowItem>
          <Columns>
            <ColumnsItem className="is-paddingless">
              <VictoryChart
                height={180}
                padding={{ left: 55, right: 30, top: 30, bottom: 30 }}
                domainPadding={{ x: [10, 0] }}
                containerComponent={
                  <VictoryVoronoiContainer
                    style={{ fontSize: 8 }}
                    labels={({ datum }) => `${Number.parseFloat(datum.y).toFixed(2)}`}
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={4}
                        style={toolTipStyle}
                        flyoutPadding={4}
                        flyoutStyle={flyoutStyle}
                        pointerLength={6}
                        pointerWidth={0}
                        activateData
                      />
                    }
                  />
                }
              >
                <VictoryAxis
                  dependentAxis
                  style={axisStyle}
                  tickFormat={(tick) => `${tick} ${unitToShow}₂`}
                  tickCount={(consoData?.length ?? 0) > 1 ? 3 : 1}
                />
                <VictoryAxis style={axisStyle} tickCount={(consoData?.length ?? 0) > 1 ? 6 : 1} />

                <VictoryLine
                  groupComponent={<VictoryClipContainer clipPadding={{ top: 5, bottom: 5, right: 5 }} />}
                  style={victoryLineStyle}
                  data={consoData}
                />
              </VictoryChart>
            </ColumnsItem>
          </Columns>
        </RowItem>
        <RowItem>
          <Text level={TextLevels.ONE} className="has-text-centered" data-cy="page-sous-titre">
            {t('info')}
          </Text>
        </RowItem>
      </Rows>
    </Box>
  );
};
export default BoxConsommationFlotte;
