const filtresDefaut: Array<RegExp> = [/motPasse/, /compteBancaire/, /code/]
export const MASK = '*****'

export function filtrerInput<T = any>(input?: T, filtresAdditionnels: Array<string | RegExp> = []): T {
  const newInput = input == null ? input : JSON.parse(JSON.stringify(input))
  const filtres = [...filtresDefaut, ...filtresAdditionnels.map(f => new RegExp(f))]
  cleanObject(newInput, filtres)
  return newInput
}

function cleanObject(obj: any, filters: RegExp[]) {
  if (typeof obj === 'object' && obj != null) {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        cleanObject(obj[key], filters)
      } else {
        if (filters.some(regexp => regexp.test(key))) {
          obj[key] = MASK
        }
      }
    })
  }
}
