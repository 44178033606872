import { WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { CheckBoxOptionType, FormikConfigType, Shape } from '@shared/components/ui-components/form/type';
import useUrlToken from '@shared/hooks/use-url-token';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { NeutraliteCarboneIn, UseDataProps } from './type';

const useData = (): UseDataProps => {
  const { t } = useTranslation('domain/neutraliteCarbone');
  const sirenToken = useUrlToken(RouteParameters.sirenId);

  const history = useHistory();
  const navigationItemConfig = React.useMemo<NavigationItemConfig>(
    () => ({
      label: t('breadcrumb.current'),
      link: { href: WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString()) },
    }),
    [sirenToken, t],
  );

  const [distributionZone] = React.useState<CheckBoxOptionType<NeutraliteCarboneIn>[]>([
    { label: t('form.boisement'), value: t('form.boisement') },
    { label: t('form.grandeCulture'), value: t('form.grandeCulture') },
    { label: t('form.reboisement'), value: t('form.reboisement') },
    { label: t('form.vergers'), value: t('form.vergers') },
  ]);
  const onSubmit = React.useCallback(
    (neutraliteCarbonForm: NeutraliteCarboneIn) => {
      const request = { ...neutraliteCarbonForm };
      if (!neutraliteCarbonForm.volumeEmission) request.volumeEmission = t('form.GES');
      let emailBody = t('form.headerMail');
      emailBody += Object.keys(request)
        .map((key) => {
          let msg = t(`email.${key}`) !== `email.${key}` ? t(`email.${key}`) : t(`form.${key}`);
          msg += ` : \n${request[key]}\n\n`;
          return msg;
        })
        .join('');
      emailBody += t('form.signatureMail');
      window.location.href = `mailto:${t('mail')}?subject=${t('breadcrumb.current')}&body=${encodeURIComponent(
        emailBody,
      )}`;
      history.push(WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString()));
    },
    [history, sirenToken, t],
  );

  const config = React.useMemo<FormikConfigType<NeutraliteCarboneIn>>(
    () => ({
      configuration: [
        {
          inputName: 'volumeEmission',
          type: 'number',
          label: t('form.volumeEmission'),
          placeholder: t('form.placeholderVolumeEmission'),
          displayErrors: true,
          fieldConfig: {
            required: false,
          },
        },
        {
          inputName: 'geographicImplantation',
          type: 'text',
          label: t('form.geographicImplantation'),
          placeholder: t('form.placeHoldergeographicImplantation'),
          displayErrors: true,
          fieldConfig: {
            required: false,
          },
        },
        {
          inputName: 'averagePrice',
          type: 'number',
          label: t('form.averagePrice'),
          placeholder: t('form.placeholderPrice'),
          displayErrors: true,
          fieldConfig: {
            required: false,
          },
        },
        {
          inputName: 'distributionZone',
          type: 'select',
          label: t('form.distributionZone'),
          placeholder: t('form.placeholderDistribution'),
          displayErrors: true,
          fieldConfig: {
            required: false,
            nullable: true,
            options: distributionZone,
          },
        },
        {
          inputName: 'comment',
          type: 'textarea',
          label: t('form.comment'),
          displayErrors: true,
          fieldConfig: {
            required: true,
          },
        },
      ],
      validationSchema: Yup.object<Shape<NeutraliteCarboneIn>>({
        volumeEmission: Yup.number().min(500, t('form.minimumEmission')),
        averagePrice: Yup.number().min(30, t('form.minimumPrice')).max(80, t('form.maximumPrice')),
        comment: Yup.string().required(t('form.mandatoryField')),
      }),
      onSubmit,
    }),
    [distributionZone, onSubmit, t],
  );

  return {
    navigationItemConfig,
    config,
  };
};
export default useData;
