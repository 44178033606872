import SplashScreen from '@domain/app/splashscreen';
import { SplashScreenStatus } from '@domain/app/splashscreen/types';
import { WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import {
  useAuthenticationError,
  useIsAuthenticated,
  useIsAuthenticating,
  useIsLoaded,
  useOAuth,
} from '@shared/contexts/auth-provider';
import useUrlToken from '@shared/hooks/use-url-token';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const AppAuthenticationInProgress: React.FC = () => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'loading',
  });
  const auth = useOAuth();
  const isLoaded = useIsLoaded();
  const isAuthenticating = useIsAuthenticating();
  const isAuthenticated = useIsAuthenticated();
  const [hasError] = useAuthenticationError();
  const sirenToken = useUrlToken(RouteParameters.sirenId);

  useEffect(() => {
    if (isLoaded && isAuthenticating && !isAuthenticated) {
      auth.login();
    }
  }, [auth, isLoaded, isAuthenticating, isAuthenticated]);

  if (isAuthenticated && !isAuthenticating) {
    return <Redirect to={WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString())} />;
  }

  if (hasError) {
    return <SplashScreen text={t('authentication-failed')} status={SplashScreenStatus.ERROR} />;
  }

  return <SplashScreen text={t('authentication-in-progress')} />;
};

export default AppAuthenticationInProgress;
