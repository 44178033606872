import { Section, Text, TextLevels, Title, TrilogyColor } from '@bytel/trilogy-react-ts';
import NavigationItem from '@shared/components/navigation-item';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GroupePartenaire from './groupe-partenaire';
import useData from './hook';

const ListerPartenaires: React.FC = () => {
  const { t } = useTranslation('domain/partenaires');
  const { partenaires, isFetching, isLoading, navigationItemConfig } = useData();

  return (
    <Section data-cy="pages-partenaires-container" skeleton={isFetching || isLoading} className="is-paddingless">
      <NavigationItem config={navigationItemConfig} />
      <Section background={TrilogyColor.WHITE}>
        <Section className="is-paddingless">
          <Title level={TextLevels.ONE} data-cy="partenaires-title">
            {t('title')}
          </Title>
          <Text level={TextLevels.TWO} data-cy="partenaires-description">
            {t('avantDescription')}
          </Text>
          <Text level={TextLevels.TWO} data-cy="partenaires-description">
            {t('description')}
            <Text level={TextLevels.TWO} data-cy="partenaires-description">
              {t('guide')}
            </Text>
          </Text>
          {Object.keys(partenaires).map((group) => (
            <GroupePartenaire key={group} category={group} items={partenaires[group]} />
          ))}
        </Section>
      </Section>
    </Section>
  );
};

export default ListerPartenaires;
