import { Actualite } from '@services/interfaces/generated/YSER-v1';
import { useSupprimerActualites } from '@shared/contexts/api';
import { useCallback } from 'react';
import { UseDataProps } from './type';

const useData = (actualite: Actualite): UseDataProps => {
  const supprimerActualites = useSupprimerActualites(actualite._actions.supprimerActualite);
  const confirmDelete = useCallback(async () => {
    await supprimerActualites.mutateAsync();
  }, [supprimerActualites]);

  return { confirmDelete };
};

export default useData;
