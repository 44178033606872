/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */
import APIDescriptorService from '@services/api/APIDescriptorService';
import { ApiDescription, ErreurFonctionnelle } from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React, { useContext, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useOverrideQuery } from '../helpers';

const APIDescriptorContext = React.createContext<APIDescriptorService | null>(null);

export type APIDescriptorProviderProps = {
  mockInstance?: APIDescriptorService;
};
export const APIDescriptorProvider: React.FC<APIDescriptorProviderProps> = ({ children, mockInstance = undefined }) => {
  const BQuery = useBQuery();

  const instance = useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new APIDescriptorService(BQuery);
  }, [mockInstance, BQuery]);

  return <APIDescriptorContext.Provider value={instance}>{children}</APIDescriptorContext.Provider>;
};

export const useAPIDescriptor = (): APIDescriptorService => {
  const context = useContext(APIDescriptorContext);
  if (context === undefined || context === null) {
    throw new Error('The `useAPIDescriptor` hook must be used within a APIDescriptorProvider');
  }
  return context;
};
export const useAPIDescription = (): UseQueryResult<ApiDescription, AxiosError<ErreurFonctionnelle>> => {
  const descriptor = useAPIDescriptor();
  return useOverrideQuery<ApiDescription, AxiosError<ErreurFonctionnelle>, ApiDescription>('api-descriptor', () =>
    descriptor.loadAsync(),
  );
};
