import * as React from 'react'
import { getItem, setItem } from './storage'

export type ContextFunction<TS> = (store: TS, setStore: (newValues: Partial<TS>) => void, ...any) => any

export function buildContext<TS, TC>(
  initialValue: TS,
  fonctions: { [s in keyof TC]: ContextFunction<TS> },
  persistance: 'none' | 'session' | 'local' = 'none',
  cleStockage?: string
): { context: React.Context<TC & TS>; provider: React.FC } {
  type TCS = TC & TS
  const context = React.createContext<TCS>(initialValue as TCS)

  const Provider: React.FC = ({ children }) => {
    const [store, setStore] = React.useState<TCS>(initialValue as TCS)
    const [firstRenderDone, setFirstRenderDone] = React.useState(false)
    const stateRef = React.useRef<TCS>()
    stateRef.current = store

    const persiterStore = React.useCallback(
      value => persistance !== 'none' && setItem(cleStockage as string, value, persistance),
      []
    )
    const _setStore = React.useCallback((newValues: Partial<TS>) => {
      setStore(s => {
        const newStore = { ...s, ...newValues }
        persiterStore(newStore)
        return newStore
      })
    }, [])

    const initialiserStore = React.useCallback(() => {
      let state
      if (persistance !== 'none') {
        if (!cleStockage) {
          throw new Error('Une clé de stockage est nécessaire')
        }
        const storedValue = getItem<TCS>(cleStockage)
        if (storedValue != null) {
          state = storedValue
        }
      }
      if (state == null) {
        state = initialValue as TCS
      }
      persiterStore(state)
      setStore({ ...state })
    }, [])

    if (!firstRenderDone) {
      initialiserStore()
      setFirstRenderDone(true)
    }

    Object.keys(fonctions).forEach(functionKey => {
      store[functionKey] = (...props) => fonctions[functionKey](stateRef.current, _setStore, ...props)
    })

    return <context.Provider value={store}>{children}</context.Provider>
  }

  return { context, provider: Provider }
}
