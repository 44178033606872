import { Columns, ColumnsItem, Text } from '@bytel/trilogy-react-ts';
import React from 'react';
import { PreCompleteInputProps } from '../type';
import InputTrilogy from './base/inputTrilogy';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const PreCompleteInputComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label,
  placeholder,
  fieldConfig,
  displayConfig,
  displayErrors,
}: PreCompleteInputProps<T>) => {
  const { preCompleteValue, required } = fieldConfig;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <Columns verticalCentered>
          <ColumnsItem size={3} className="has-text-left">
            <Text data-cy={`preCompleteText-textType-${dataCy}-${inputName}`}>{preCompleteValue?.toString()}</Text>
          </ColumnsItem>
          <ColumnsItem size={9}>
            <InputTrilogy
              inputName={inputName}
              dataCy={`preCompleteInput-textType-${dataCy}-${inputName}`}
              setInputValue={setInputValue}
              formik={formik}
              label={label}
              fieldConfig={fieldConfig}
              displayConfig={displayConfig}
              placeholder={placeholder}
              displayErrors={displayErrors}
              type="text"
            />
          </ColumnsItem>
        </Columns>
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default PreCompleteInputComponent;
