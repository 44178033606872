// Pour ajouter des patterns, se référer à https://b2r-adm-1y.pin.dolmen.bouyguestelecom.fr/b2r_admin/confExtViewer.php

const patterns: Array<{ path: RegExp; remplacement: string }> = [
  {
    path: /\/(0|\+33)[1-9]\d{8}(\/|$)/g,
    remplacement: '/{numTel}$2'
  },
  {
    path: /\/([a-z0-9][a-z0-9_\.-]*@[a-z][a-z0-9_\.-]*\.[a-z]+)/gi,
    remplacement: '/{email}'
  },
  {
    path: /\/(\d+)+(T|P)/g,
    remplacement: '/{ligne}'
  },
  {
    path: /\/dossierretour\/[A-Z0-9]+/g,
    remplacement: '/dossierretour/{dossier}'
  },
  {
    path: /\/tickets\/[A-Z0-9-]+/g,
    remplacement: '/tickets/{ticket}'
  },
  {
    path: /\/produits\/[A-Z0-9_]+/g,
    remplacement: '/produits/{produit}'
  },
  {
    path: /\/fiches-apres-vente\/[a-z0-9-]+/g,
    remplacement: '/fiches-apres-vente/{fiche}'
  },
  {
    path: /\/messageries\/[^/]+/g,
    remplacement: '/messageries/{messagerie}'
  },
  {
    path: /\/factures\/[A-Z0-9]+/g,
    remplacement: '/factures/{facture}'
  },
  {
    path: /\/factures-manuelles\/[A-Z0-9]+/g,
    remplacement: '/factures-manuelles/{facture}'
  },
  {
    path: /\/documents\/[a-z0-9-]+/g,
    remplacement: '/documents/{document}'
  },
  {
    path: /\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gi,
    remplacement: '/{id}'
  },
  {
    path: /\/(\d+)/g,
    remplacement: '/{id}'
  }
]

export function extrairePatternUrl(url: string) {
  url = url.replace(/\?.+/, '') // QueryParams
  patterns.forEach(({ path, remplacement }) => {
    url = url.replace(path, remplacement)
  })
  return url
}
