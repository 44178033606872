/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */
import * as time from '@helpers/time';
import { ActualitesAPIService } from '@services/api';
import { ActualitesSearchFilters, QueryParams } from '@services/api/types';
import {
  Actualite,
  ActualiteIn,
  ActualiteList,
  ErreurFonctionnelle,
  ImageUrl,
  UploadImageIn,
  XAction,
} from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React from 'react';
import { UseMutationResult, useQueryClient, UseQueryResult } from 'react-query';
import { useOAuth } from '../auth-provider';
import { useOverrideMutation, useOverrideQuery } from '../helpers';
import { emptyAction } from './type';

const ActualitesAPIContext = React.createContext<ActualitesAPIService | null>(null);
const ActualitesCacheKey = 'Actualites';

export type ActualitesAPIProviderProps = {
  mockInstance?: ActualitesAPIService;
};

export const ActualitesAPIProvider: React.FC<ActualitesAPIProviderProps> = ({ children, mockInstance = undefined }) => {
  const BQuery = useBQuery();

  const instance = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new ActualitesAPIService(BQuery);
  }, [mockInstance, BQuery]);

  return <ActualitesAPIContext.Provider value={instance}>{children}</ActualitesAPIContext.Provider>;
};

export const useActualitesAPI = (): ActualitesAPIService => {
  const context = React.useContext(ActualitesAPIContext);
  if (context === undefined || context === null) {
    throw new Error('The `useActualitesAPI` hook must be used within a ActualitesAPIProvider');
  }
  return context;
};

export const useListerActualites = (
  href?: string,
  options?: ActualitesSearchFilters,
): UseQueryResult<ActualiteList | undefined, AxiosError<ErreurFonctionnelle>> => {
  const ActualitesAPI = useActualitesAPI();
  return useOverrideQuery<ActualiteList | undefined, AxiosError<ErreurFonctionnelle>, ActualiteList | undefined>(
    [ActualitesCacheKey, href, options],
    () => ActualitesAPI.listerActualites(href, options),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};

export const useObtenirActualites = (
  href?: string,
): UseQueryResult<Actualite | undefined, AxiosError<ErreurFonctionnelle>> => {
  const ActualitesAPI = useActualitesAPI();
  return useOverrideQuery<Actualite | undefined, AxiosError<ErreurFonctionnelle>, Actualite | undefined>(
    [ActualitesCacheKey, href],
    () => ActualitesAPI.obtenirActualite(href),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};

export const useCreateActualite = (
  link?: XAction,
): UseMutationResult<Actualite, AxiosError<ErreurFonctionnelle>, ActualiteIn> => {
  const ActualiteAPI = useActualitesAPI();
  const queryClient = useQueryClient();
  const auth = useOAuth();
  const qs: QueryParams = { idToken: auth.idToken };
  return useOverrideMutation<Actualite, AxiosError<ErreurFonctionnelle>, ActualiteIn>(
    { key: 'actualite.creer', options: [{ key: 'title', prop: 'title' }] },
    (input: ActualiteIn) => ActualiteAPI.createActualite(link || emptyAction, input, qs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ActualitesCacheKey);
      },
    },
  );
};

export const useModifierActualite = (
  link?: XAction,
): UseMutationResult<Actualite, AxiosError<ErreurFonctionnelle>, ActualiteIn> => {
  const ActualiteAPI = useActualitesAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation<Actualite, AxiosError<ErreurFonctionnelle>, ActualiteIn>(
    { key: 'actualite.modifier', options: [{ key: 'title', prop: 'title' }] },
    (input: ActualiteIn) => ActualiteAPI.modifierActualite(link || emptyAction, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ActualitesCacheKey);
      },
    },
  );
};

export const useSupprimerActualites = (
  href: XAction | undefined,
): UseMutationResult<void, AxiosError<ErreurFonctionnelle>, void> => {
  const ActualitesAPI = useActualitesAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation(
    { key: 'actualite.supprimer' },
    () => ActualitesAPI.supprimerActualite(href || emptyAction),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(ActualitesCacheKey);
      },
    },
  );
};

export const useEnvoyerImageActualites = (
  href: XAction | undefined,
): UseMutationResult<ImageUrl, AxiosError<ErreurFonctionnelle>, any> => {
  const ActualitesAPI = useActualitesAPI();
  return useOverrideMutation(
    { key: 'actualite.envoyerImage' },
    (input: UploadImageIn) => ActualitesAPI.envoyerImage(href || emptyAction, input),
    {
      onSuccess: () => {},
    },
  );
};
