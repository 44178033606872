import { RouteParameters } from './types';

export const WELCOME_PATH = `/sirens/${RouteParameters.sirenId}`;

export const ECOASTUCES_LIST_PATH = `${WELCOME_PATH}/ecoAstuces`;
export const ECOASTUCES_LIST_BTE_PATH = `${ECOASTUCES_LIST_PATH}/bte`;
export const ECOASTUCE_PATH = `${ECOASTUCES_LIST_PATH}/${RouteParameters.ecoAstuceId}`;
export const ECOASTUCE_NEW_PATH = `${ECOASTUCE_PATH}/new`;
export const ECOASTUCE_EDIT_PATH = `${ECOASTUCE_PATH}/edit`;

export const ACTUALITES_LIST_PATH = `${WELCOME_PATH}/actualites`;
export const ACTUALITE_PATH = `${ACTUALITES_LIST_PATH}/${RouteParameters.actualiteId}`;
export const ACTUALITE_NEW_PATH = `${ACTUALITE_PATH}/new`;
export const ACTUALITE_EDIT_PATH = `${ACTUALITE_PATH}/edit`;

export const CONTRIBUTION_NEUTRALITE_PATH = `${WELCOME_PATH}/neutralite`;

export const PARTENAIRES_PAGE_PATH = `${WELCOME_PATH}/partenaires`;

export const ERREUR_PATH = `/erreur/${RouteParameters.erreurId}`;
export const LOGOUT_PATH = `/logout`;
export const OAUTH_BASE = `/oauth`;
export const OAUTH_LOGIN = `${OAUTH_BASE}/login`;
export const OAUTH_CALLBACK = `${OAUTH_BASE}/redirect`;
