import { DecimalTransform, unitCO2eq, unitData } from '@shared/constants';

export type TransformUnitType = 'data' | 'co2eq';

export type TransformBaseType = {
  value: string;
  unit: string;
  index: number;
};

/**
 * Gère le formatage d'une donnée de type volume de data
 * pour la mettre dans l'unité la plus adaptée.
 * @param value
 * @returns
 */
export const transformUnit = (
  value: number,
  unit: TransformUnitType,
  askedLevel?: number,
  enumDecimal?: DecimalTransform,
): TransformBaseType => {
  const availableUnits = unit === 'data' ? unitData : unitCO2eq;
  const valueThreshold = unit === 'data' ? 1024 : 1000;
  let valueToCalculate = value * valueThreshold * valueThreshold;
  let index = 0;
  let result = {
    value: valueToCalculate.toFixed(1).replace('.0', ''),
    unit: availableUnits[index],
    index,
  };

  const level = askedLevel ?? availableUnits.length - 1;

  while ((askedLevel || valueToCalculate >= valueThreshold) && index < level) {
    valueToCalculate /= valueThreshold;
    index += 1;
    let decimal = '';

    decimal = parseFloat(valueToCalculate.toString()).toFixed(DecimalTransform.Export).replace('.00', '');

    result = {
      value: decimal,
      unit: availableUnits[index],
      index,
    };
  }
  return result;
};
