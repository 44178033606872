import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { InitialValuesType } from '../type';

/**
 * Vérifie si le champ a la même valeur que @valueToCheck et lève une erreur
 * @param isDuplicate
 * @param valueToChek
 * @param duplicateMessage
 * @param requiredMessage
 * @returns
 */
export const checkDuplication = (
  isDuplicate: boolean,
  valueToChek: string | undefined,
  duplicateMessage: string,
  requiredMessage: string,
): RequiredStringSchema<string | undefined, AnyObject> => {
  return isDuplicate
    ? Yup.string().lowercase().notOneOf([valueToChek?.toLocaleLowerCase()], duplicateMessage).required(requiredMessage)
    : Yup.string().required(requiredMessage);
};

/**
 * Formik appel la fonction pour valider la règle
 * Utiliser lorsqu'on fait un disabled
 * @param func
 * @param data
 * @returns
 */
export const executeFormikValidation = <T extends object>(
  func: boolean | ((formik: InitialValuesType<T>) => boolean),
  data: InitialValuesType<T>,
) => {
  if (typeof func === 'function') return func(data);
  return func;
};
