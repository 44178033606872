import { RouteParameters } from '@router/types';
import UrlToken from '@shared/misc/helpers/urlToken';
import { useParams } from 'react-router-dom';

/**
 * Retourne l'URL token demandé
 * @param paramName
 * @returns
 */
function useUrlToken(paramName: RouteParameters): UrlToken {
  const params = useParams();
  const value = params[paramName.replace(':', '')];
  return UrlToken.fromToken(value);
}

/**
 * Retourne les URLs token demandés
 * @param paramsName
 * @returns
 */
export function useUrlTokens(paramsName: RouteParameters[]): UrlToken[] {
  const params = useParams();

  return paramsName.map((pn) => {
    const value = pn.replace(':', '');
    return UrlToken.fromToken(params[value]);
  });
}

export default useUrlToken;
