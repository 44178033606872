import { TypeConfig } from './type';

const promiseConfig = fetch('/config/config.json').then((res) => res.json());
let configuration = null as TypeConfig | null;

export default async function getConfig(): Promise<TypeConfig> {
  if (configuration === null) {
    configuration = (await promiseConfig) as TypeConfig;
  }
  return configuration;
}
