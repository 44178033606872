import {
  Alignable,
  Button,
  Card,
  CardContent,
  Columns,
  ColumnsItem,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import NavigationItem from '@shared/components/navigation-item';
import YserForm from '@shared/components/ui-components/form/yser-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useData from './hook';

const EditerEcoAstuces: React.FC = () => {
  const { config, navigationItemConfig, isFetching, isLoading, ecoAstuce, imageDimensionForCreationForm, tEcoAstuce } =
    useData();
  const { t } = useTranslation('shared/generic-form');
  return (
    <>
      <NavigationItem config={navigationItemConfig} />
      {ecoAstuce && (ecoAstuce.isBTE || !ecoAstuce._actions.modifierEcoAstuce) ? (
        <Rows>
          <RowItem>
            <Columns className="is-centered fullwidth">
              <ColumnsItem size={6}>
                <Card align={Alignable.ALIGNED_CENTER} skeleton={isLoading || isFetching} className="has-text-centered">
                  <img
                    style={{ ...imageDimensionForCreationForm, paddingTop: '10px' }}
                    src={ecoAstuce?.image}
                    alt="imageToDisplay"
                  />
                  <CardContent>
                    <Columns centered>
                      <ColumnsItem>
                        <Rows>
                          <RowItem>
                            <Title level={TitleLevels.TWO}>{ecoAstuce?.title} </Title>
                            <Text level={TextLevels.TWO}>{ecoAstuce?.description} </Text>
                          </RowItem>
                          <RowItem>
                            {ecoAstuce?.url && (
                              <Button
                                onClick={() => {
                                  window.open(ecoAstuce?.url, '_blank');
                                }}
                                className="is-tertiary"
                              >
                                {tEcoAstuce('consulter')}
                              </Button>
                            )}
                          </RowItem>
                        </Rows>
                      </ColumnsItem>
                    </Columns>
                  </CardContent>
                </Card>
              </ColumnsItem>
            </Columns>
          </RowItem>
          <RowItem>{/* space */}</RowItem>
        </Rows>
      ) : (
        <Section
          data-cy="editer-ecoAstuces-container"
          skeleton={isFetching || isLoading}
          background={TrilogyColor.WHITE}
        >
          <Columns className="is-centered fullwidth">
            <ColumnsItem size={6}>
              <YserForm dataCy="yser-editer-ecoAstuce" config={config} t={t} direction="row" />
            </ColumnsItem>
          </Columns>
        </Section>
      )}
    </>
  );
};

export default EditerEcoAstuces;
