import {
  Columns,
  ColumnsItem,
  IconName,
  Input,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import { ACTUALITE_NEW_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import HeadbandCreation from '@shared/components/headband-creation';
import NavigationItem from '@shared/components/navigation-item';
import { KeyCodeEvent } from '@shared/constants';
import useUrlToken from '@shared/hooks/use-url-token';
import ActualitesList from './actualite';
import useData from './hook';

const ListerActualites: React.FC = () => {
  const { actualites, urlCreate, handleSearchClick, isFetching, isLoading, navigationItemConfig, t } = useData();
  const sirenToken = useUrlToken(RouteParameters.sirenId);

  return (
    <Section data-cy="lister-actualites-container" skeleton={isFetching || isLoading} className="is-paddingless">
      <NavigationItem config={navigationItemConfig} />
      <HeadbandCreation
        PATH={ACTUALITE_NEW_PATH.replace(RouteParameters.sirenId, sirenToken.toString())}
        dataCy="actualites"
        id={RouteParameters.actualiteId}
        t={t}
        urlCreate={urlCreate}
      />
      <Section background={TrilogyColor.WHITE}>
        <Rows>
          <RowItem>
            <Section className="is-paddingless">
              <Text level={TextLevels.ONE} data-cy="actualites-search-description">
                {t('search.description')}
              </Text>
              <Input
                name="actualite-searchbar"
                placeholder={t('search.place-holder')}
                data-cy="actualites-searchbar"
                search
                customIcon={IconName.SEARCH}
                onIconClick={handleSearchClick}
                onKeyUp={(e) => e.inputKeyCode === KeyCodeEvent.OnEnterPressed && handleSearchClick(e)}
              />
            </Section>
          </RowItem>
          <RowItem>{/* space */}</RowItem>
          <RowItem>
            <Columns>
              <ColumnsItem size={12}>
                {actualites.length === 0 ? (
                  <Section>
                    <RowItem>
                      <Columns>
                        <ColumnsItem size={3}>{/* space */}</ColumnsItem>
                        <ColumnsItem size={6}>
                          <Text level={TextLevels.ONE} className="has-text-centered">
                            {t('empty.message')}
                          </Text>
                          <RowItem>{/* space */}</RowItem>
                          <Text level={TextLevels.ONE} className="has-text-centered">
                            {t('empty.info')}
                          </Text>
                        </ColumnsItem>
                        <ColumnsItem size={3}>{/* space */}</ColumnsItem>
                      </Columns>
                    </RowItem>
                  </Section>
                ) : (
                  actualites &&
                  actualites.length > 0 && <ActualitesList actualites={actualites} skeleton={isLoading || isFetching} />
                )}
              </ColumnsItem>
            </Columns>
          </RowItem>
        </Rows>
      </Section>
    </Section>
  );
};

export default ListerActualites;
