import {
  ActualitesAPIProvider,
  APIDescriptorProvider,
  BQueryProvider,
  ConsommationFlotteAPIProvider,
  EcoAstucesAPIProvider,
  PartenaireAPIProvider,
} from '@contexts/api';
import * as time from '@helpers/time';
import { NotifyContextProvider } from '@yama/shared-components/dist/components/notify';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SirenAPIProvider } from './api/siren-provider';
import { AuthProvider } from './auth-provider';
import { LoadingContext } from './loading-context';
import { LoggerProvider } from './logger-provider';
import { SirenProvider } from './siren-context';
import { AllProviderProps } from './types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: time.fromMinutes(1),
      staleTime: time.fromMinutes(15),
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const AllProviders: React.FC<AllProviderProps> = ({
  children,
  logger,
  reactQueryInstance,
  apiDescriptorInstance,
  partenaireAPIInstance,
  ecoAstuceAPIInstance,
  actualiteAPIInstance,
  consommationFlotteAPIInstance,
  sirenAPIInstance,
}) => {
  return (
    <NotifyContextProvider>
      <AuthProvider>
        <QueryClientProvider client={reactQueryInstance || queryClient}>
          <LoggerProvider logger={logger}>
            <BQueryProvider>
              <LoadingContext>
                <APIDescriptorProvider mockInstance={apiDescriptorInstance}>
                  <SirenAPIProvider mockInstance={sirenAPIInstance}>
                    <ConsommationFlotteAPIProvider mockInstance={consommationFlotteAPIInstance}>
                      <PartenaireAPIProvider mockInstance={partenaireAPIInstance}>
                        <ActualitesAPIProvider mockInstance={actualiteAPIInstance}>
                          <EcoAstucesAPIProvider mockInstance={ecoAstuceAPIInstance}>
                            <SirenProvider>{children}</SirenProvider>
                          </EcoAstucesAPIProvider>
                        </ActualitesAPIProvider>
                      </PartenaireAPIProvider>
                    </ConsommationFlotteAPIProvider>
                  </SirenAPIProvider>
                </APIDescriptorProvider>
              </LoadingContext>
            </BQueryProvider>
          </LoggerProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </NotifyContextProvider>
  );
};

export default AllProviders;
