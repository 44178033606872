import { PartenaireList, PartenaireToggleIn, XAction } from '@generated/YSER-v1';
import APIServicesBase from './APIServicesBase';
import { PartenaireSearchFilters } from './types';
import { setQueryParams } from './utils';

export default class PartenaireAPIService extends APIServicesBase {
  listerPartenaires(href?: string, options?: PartenaireSearchFilters): Promise<PartenaireList | undefined> {
    if (href === undefined) {
      return Promise.resolve(undefined);
    }

    const url = this.getUrl(href);
    setQueryParams(url, options);
    return this.bquery.get<PartenaireList>(url.toString());
  }

  tooglePartenaire(link: XAction, request: PartenaireToggleIn): Promise<void> {
    return super.executeAsync<void, PartenaireToggleIn>(link, request);
  }
}
