import { useOAuth } from '@shared/contexts/auth-provider';
import * as React from 'react';

const LogoutPage: React.FC = () => {
  const auth = useOAuth();

  React.useEffect(() => {
    auth.logout();
  }, [auth]);
  return <div />;
};

export default LogoutPage;
