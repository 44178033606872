import { useAPIDescription } from '@shared/contexts/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SplashScreen from '../../splashscreen';

const APILoader: React.FC = ({ children }) => {
  const { isFetched, isError } = useAPIDescription();
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'loading',
  });

  if (!isFetched) {
    return <SplashScreen text={t('api')} />;
  }

  if (isError) {
    console.error(t('api-failure'));
    // return <SplashScreen text={t('api-failure')} status={SplashScreenStatus.ERROR} />;
  }

  // eslint-disable-next-line
  return <React.Fragment>{children}</React.Fragment>;
};

export default APILoader;
