/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */

import * as time from '@helpers/time';
import PartenaireAPIService from '@services/api/PartenaireAPIService';
import { PartenaireSearchFilters } from '@services/api/types';
import {
  ErreurFonctionnelle,
  PartenaireList,
  PartenaireToggleIn,
  XAction,
} from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React, { createContext } from 'react';
import { UseMutationResult, useQueryClient, UseQueryResult } from 'react-query';
import { useOverrideMutation, useOverrideQuery } from '../helpers';
import { emptyAction } from './type';

const PartenaireAPIContext = createContext<PartenaireAPIService | null>(null);
const PartenaireCacheKey = 'Partenaires';

export type PartenaireAPIProviderProps = {
  mockInstance?: PartenaireAPIService;
};

export const PartenaireAPIProvider: React.FC<PartenaireAPIProviderProps> = ({ children, mockInstance = undefined }) => {
  const bquery = useBQuery();

  const instanceAPI = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new PartenaireAPIService(bquery);
  }, [mockInstance, bquery]);

  return <PartenaireAPIContext.Provider value={instanceAPI}>{children}</PartenaireAPIContext.Provider>;
};

export const usePartenaireAPI = (): PartenaireAPIService => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return React.useContext(PartenaireAPIContext)!;
};

export const useListerPartenaires = (
  href?: string,
  options?: PartenaireSearchFilters,
): UseQueryResult<PartenaireList | undefined, AxiosError<ErreurFonctionnelle>> => {
  const PartenaireAPI = usePartenaireAPI();
  return useOverrideQuery<PartenaireList | undefined, AxiosError<ErreurFonctionnelle>, PartenaireList | undefined>(
    [PartenaireCacheKey, href, options],
    () => PartenaireAPI.listerPartenaires(href, { ...options, withToken: true }),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};

export const useTooglePartenaire = (
  href: XAction | undefined,
): UseMutationResult<void, AxiosError<ErreurFonctionnelle>, PartenaireToggleIn> => {
  const PartenaireAPI = usePartenaireAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation<void, AxiosError<ErreurFonctionnelle>, PartenaireToggleIn>(
    { key: 'partenaire.toogle' },
    (input: PartenaireToggleIn) => PartenaireAPI.tooglePartenaire(href || emptyAction, input),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(PartenaireCacheKey);
      },
    },
  );
};
