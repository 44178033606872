import { IconName } from '@bytel/trilogy-react-ts';
import { ACTUALITES_LIST_PATH, ECOASTUCES_LIST_PATH, PARTENAIRES_PAGE_PATH, WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { PopupConfig } from '@shared/components/popup/type';
import { GLOBAL_CONSO_ID } from '@shared/constants';
import { useAPIDescription } from '@shared/contexts/api';
import { useListerSirens } from '@shared/contexts/api/siren-provider';
import { useOAuth, useUserName } from '@shared/contexts/auth-provider';
import { useSirenContext } from '@shared/contexts/siren-context';
import { encodeYserUri } from '@shared/misc/helpers/strings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UseDataHeaderProps } from './type';

const urlToIgnore = ['/erreur', '/logout'];
const useData = (): UseDataHeaderProps => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'header',
  });
  const auth = useOAuth();

  const username = useUserName();
  const { data: apiDescription } = useAPIDescription();
  const { data: sirens } = useListerSirens(apiDescription?._links.listerSirens?.href);
  const { siren, setSiren } = useSirenContext();
  const history = useHistory();
  const match = useRouteMatch(WELCOME_PATH);

  const createNewBaseUrlCallback = useCallback((routeName: string, sirenId: string) => {
    const uri = encodeYserUri(routeName.replace(RouteParameters.sirenId, sirenId));
    const target = routeName.replace(RouteParameters.sirenId, uri);
    return target;
  }, []);

  useEffect(() => {
    if (!siren) {
      setSiren(sirens?.items[0]);
    }
    // on est pas en erreur
    // cependant on est pas non plus sur une uri /sirens
    // on redirige sur le /siren..
    const current = window.location.href;
    const currentLength = current.length;
    const sirenKey = '/sirens/';
    const indexSiren = current.indexOf(sirenKey);

    if (
      sirens?.resultsCount &&
      sirens?.resultsCount > 0 &&
      urlToIgnore.every((url) => !current.includes(url)) &&
      (!current.includes(sirenKey) || currentLength === indexSiren + sirenKey.length)
    ) {
      const uri = encodeYserUri(WELCOME_PATH.replace(RouteParameters.sirenId, siren?.id ?? sirens?.items[0].id));
      const target = WELCOME_PATH.replace(RouteParameters.sirenId, uri);
      history.push(target);
    } else if (sirens && sirens.resultsCount === 0) {
      // nous sommes sensés redirigé vers la page d'erreur car nous n'avons pas de sirens
      console.error("l'utilisateur n'a rien à faire là");
    }
  }, [history, setSiren, siren, sirens, sirens?.items]);

  useEffect(() => {
    if (sirens && siren?.id === GLOBAL_CONSO_ID && match && !match.isExact) {
      setSiren({ ...sirens.items[0] });
    }
  }, [siren, sirens, sirens?.items, setSiren, match]);

  const [showPopup, setShowPopup] = useState(false);

  const config = useMemo<PopupConfig>(
    () => ({
      title: username,
      ctaContent: t('popup.deconnection'),
      dataCy: 'header-panel',
      panelConfig: {
        panel: true,
      },
      ctaOnClick: () => {
        auth.logout();
        setShowPopup(false);
      },
      onClose: () => setShowPopup(false),
      t,
      hideCancelButton: true,
      btnValidIconName: IconName.POWER,
    }),
    [auth, t, username],
  );

  const updateSirenCallback = useCallback(
    (sirenId: string) => {
      const selectedSiren = sirens?.items.find((s) => s.id === sirenId);
      if (selectedSiren) {
        setSiren(selectedSiren);

        if (history.location.pathname.endsWith('ecoAstuces')) {
          history.push(createNewBaseUrlCallback(ECOASTUCES_LIST_PATH, selectedSiren.id));
        } else if (history.location.pathname.endsWith('actualites')) {
          history.push(createNewBaseUrlCallback(ACTUALITES_LIST_PATH, selectedSiren.id));
        } else if (history.location.pathname.endsWith('partenaires')) {
          history.push(createNewBaseUrlCallback(PARTENAIRES_PAGE_PATH, selectedSiren.id));
        } else {
          history.push(createNewBaseUrlCallback(WELCOME_PATH, selectedSiren.id));
        }
      } else if (sirenId === GLOBAL_CONSO_ID) {
        if (siren) {
          setSiren({ ...siren, id: GLOBAL_CONSO_ID });
          history.push(WELCOME_PATH.replace(RouteParameters.sirenId, siren.id));
        }
      }
    },
    [history, setSiren, sirens, siren, createNewBaseUrlCallback],
  );

  const routeRefLogoBTE = useMemo(
    () => encodeYserUri(WELCOME_PATH.replace(RouteParameters.sirenId, siren?.id ?? sirens?.items[0].id ?? '')),
    [siren?.id, sirens?.items],
  );

  const sirensWithGlobal = useMemo(() => {
    if (sirens?.items && siren) {
      const globalSirens = { ...siren, id: GLOBAL_CONSO_ID, raisonSociale: '' };
      return [globalSirens, ...sirens.items];
    }
    return [];
  }, [sirens?.items, siren]);

  return {
    config,
    showPopup,
    setShowPopup,
    username,
    siren,
    sirens: sirensWithGlobal,
    routeRefLogoBTE,
    updateSirenCallback,
    t,
  };
};

export default useData;
