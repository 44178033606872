/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */
import AuthBytelService from '@services/api/auth';
import { AuthenticationError, UserData } from '@services/api/auth/type';
import React, { createContext, useContext, useEffect, useState } from 'react';

const defaultInstance = new AuthBytelService();
const AuthContext = createContext<AuthBytelService>(defaultInstance);

export type AuthProviderProps = {
  mockInstance?: AuthBytelService;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, mockInstance = undefined }) => {
  const instanceAPI = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return defaultInstance;
  }, [mockInstance]);

  return <AuthContext.Provider value={instanceAPI}>{children}</AuthContext.Provider>;
};

export const useOAuth = (): AuthBytelService => {
  return useContext(AuthContext);
};

export const useIsLoaded = (): boolean => {
  const auth = useContext(AuthContext);
  const [isLoaded, setIsLoaded] = useState<boolean>(auth.isLoaded);

  useEffect(() => {
    const sub = auth.onIsLoadedChanged.subscribe((value) => {
      setIsLoaded(value);
    });
    setIsLoaded(auth.isLoaded);
    return () => {
      sub.cancel();
    };
  }, [auth, setIsLoaded]);

  return isLoaded;
};
export const useIsAuthenticating = (): boolean => {
  const auth = useContext(AuthContext);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(auth.isAuthenticating);

  useEffect(() => {
    const sub = auth.onAuthenticatingChanged.subscribe((value) => {
      setIsAuthenticating(value);
    });
    setIsAuthenticating(auth.isAuthenticating);
    return () => {
      sub.cancel();
    };
  }, [auth, setIsAuthenticating]);

  return isAuthenticating;
};
export const useIsAuthenticated = (): boolean => {
  const auth = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(auth.isAuthenticated);

  useEffect(() => {
    const sub = auth.onAuthenticationChanged.subscribe((value) => {
      setIsAuthenticated(value);
    });
    setIsAuthenticated(auth.isAuthenticated);
    return () => {
      sub.cancel();
    };
  }, [auth, setIsAuthenticated]);

  return isAuthenticated;
};
export const useAuthenticationError = (): [boolean, AuthenticationError] => {
  const auth = useContext(AuthContext);
  const [authenticationError, setAuthenticationError] = useState<AuthenticationError>(auth.lastError);

  useEffect(() => {
    const sub = auth.onErrorRaised.subscribe((value) => {
      setAuthenticationError(value);
    });
    setAuthenticationError(auth.lastError);
    return () => {
      sub.cancel();
    };
  }, [auth, setAuthenticationError]);

  return [!!authenticationError, authenticationError];
};

export const useAccessToken = (): string => {
  const auth = useContext(AuthContext);
  const [accessToken, setAccessToken] = useState<string>(auth.accessToken);

  useEffect(() => {
    const sub = auth.onAccessTokenChanged.subscribe(() => {
      setAccessToken(auth.accessToken);
    });
    setAccessToken(auth.accessToken);
    return () => {
      sub.cancel();
    };
  }, [auth, setAccessToken]);

  return accessToken;
};

export const useUserName = (): string => {
  const auth = useContext(AuthContext);
  const [userName, setUserName] = useState<string>(auth.userName || 'Thomas GICQUEL');

  useEffect(() => {
    const sub = auth.onUserNameChanged.subscribe(() => {
      setUserName(auth.userName);
    });
    setUserName(auth.userName);
    return () => {
      sub.cancel();
    };
  }, [auth, setUserName]);
  return userName;
};

export const useRoles = (): string[] => {
  const auth = useContext(AuthContext);
  const [roles, setRoles] = useState<string[]>(auth.roles);

  useEffect(() => {
    const sub = auth.onRolesChanged.subscribe(() => {
      setRoles(auth.roles);
    });
    setRoles(auth.roles);
    return () => {
      sub.cancel();
    };
  }, [auth, setRoles]);

  return roles;
};

export const useSirens = (): string[] => {
  const auth = useContext(AuthContext);
  const [sirens, setSirens] = useState<string[]>(auth.sirens);

  useEffect(() => {
    const sub = auth.onRolesChanged.subscribe(() => {
      setSirens(auth.sirens);
    });
    setSirens(auth.sirens);
    return () => {
      sub.cancel();
    };
  }, [auth, setSirens]);

  return sirens;
};

export const useUserInfos = (): UserData | undefined => {
  const auth = useContext(AuthContext);
  const [userInfos, setUserInfos] = useState<UserData | undefined>(auth.userInfos);

  useEffect(() => {
    const sub = auth.onUserInfosChanged.subscribe(() => {
      setUserInfos(auth.userInfos);
    });
    setUserInfos(auth.userInfos);
    return () => {
      sub.cancel();
    };
  }, [auth, setUserInfos]);

  return userInfos;
};
