import { ColumnsItem, Title, TitleLevels } from '@bytel/trilogy-react-ts';
import React from 'react';
import { LabelProps } from '../type';

const LabelComponent: React.FC<LabelProps> = ({ label, inputName, dataCy, displayConfig, required }) => {
  const isPopup = !!displayConfig?.isPopupDisplay;
  const className = required ? 'is-required' : '';
  const columnSize = isPopup ? 4 : 12;
  const popupLocation = isPopup ? '' : 'has-text-left';

  return !displayConfig?.noTitle ? (
    <ColumnsItem size={columnSize} className={popupLocation}>
      <Title level={TitleLevels.THREE} data-cy={`title-${dataCy}-${inputName}`} className={className}>
        {label}
      </Title>
    </ColumnsItem>
  ) : null;
};
export default LabelComponent;
