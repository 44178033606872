import { WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { useListerPartenaires } from '@shared/contexts/api/partenaire-provider';
import { useSiren } from '@shared/contexts/siren-context';
import useUrlToken from '@shared/hooks/use-url-token';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { GroupeType } from './groupe-partenaire/type';
import { UseListerPartenaireProps } from './type';

const useData = (): UseListerPartenaireProps => {
  const siren = useSiren();
  const sirenToken = useUrlToken(RouteParameters.sirenId);
  const { data: partenaires, isLoading, isFetching } = useListerPartenaires(siren?._links?.listerPartenaires?.href);
  const { t } = useTranslation('domain/partenaires', {
    keyPrefix: 'list',
  });

  const grouped = useMemo<GroupeType>(
    () =>
      (partenaires?.items ?? []).reduce((category, partenaire) => {
        const categorie = partenaire.categorie ?? '';

        // eslint-disable-next-line no-param-reassign
        category[categorie] = category[categorie] ?? [];

        category[categorie].push(partenaire);
        return category;
      }, {}),
    [partenaires?.items],
  );

  const navigationItemConfig = React.useMemo<NavigationItemConfig>(
    () => ({
      label: t('breadcrumb.title'),
      link: { href: WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString()) },
    }),
    [sirenToken, t],
  );

  return {
    partenaires: grouped,
    isLoading,
    isFetching,
    navigationItemConfig,
  };
};

export default useData;
