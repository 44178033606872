import { getPublicIp } from './helpers/ipHelper'
import { uuid } from './helpers/uuid'
import { Contexte, Environnement } from './interfaces/notifierMessagePrisme'

type UserInfos = Pick<
  Contexte,
  | 'authentType'
  | 'scope'
  | 'clientId'
  | 'userType'
  | 'habilit'
  | 'login'
  | 'site'
  | 'idIdentity'
  | 'idPersonne'
  | 'idCT'
  | 'idCP'
  | 'idContrats'
>
export interface PartialPrismeConfig extends Omit<Environnement, 'pid' | 'host' | 'nom'> {
  urlEndpoint: string
  environnement: string
  timeoutEnvoiQueueMessagesMs?: number
  tailleMaxEnvoiQueueMessages?: number
  trackerIdProvider?: () => string
  userInfoProvider?: () => UserInfos
  disableInput?: boolean
  filtresInputAdditionnels?: Array<string | RegExp>
  storageMethod?: Storage | null
}

const NULL_STORAGE: Storage = {
  clear: () => {},
  length: 0,
  getItem: () => null,
  key: () => null,
  removeItem: () => {},
  setItem: () => {}
}

export class PrismeConfig {
  static DEFAULT_TAILLE_MAX_QUEUE = 10
  static DEFAULT_TIMEOUT_ENVOI_MS = 1000
  static TRACKERID_KEY = '@bytel/prisme-logger_trackerId'

  st: string
  urlEndpoint: string
  environnement: Environnement
  timeoutEnvoiQueueMessagesMs: number
  tailleMaxEnvoiQueueMessages: number
  trackerIdProvider: () => string
  userInfoProvider: () => UserInfos
  disableInput: boolean
  filtresInputAdditionnels: (string | RegExp)[]
  storageMethod: Storage

  constructor(partialConfig: PartialPrismeConfig) {
    this.urlEndpoint = partialConfig.urlEndpoint
    this.st = partialConfig.st
    this.environnement = {
      st: this.st,
      composant: partialConfig.composant,
      compte: partialConfig.compte,
      domaine: partialConfig.domaine,
      serveur: partialConfig.serveur,
      version: partialConfig.version,
      application: partialConfig.application || partialConfig.composant || this.st,
      nom: partialConfig.environnement
    }
    this.filtresInputAdditionnels = partialConfig.filtresInputAdditionnels || []
    this.disableInput = !!partialConfig.disableInput

    this.tailleMaxEnvoiQueueMessages =
      partialConfig.tailleMaxEnvoiQueueMessages || PrismeConfig.DEFAULT_TAILLE_MAX_QUEUE
    this.timeoutEnvoiQueueMessagesMs =
      partialConfig.timeoutEnvoiQueueMessagesMs || PrismeConfig.DEFAULT_TIMEOUT_ENVOI_MS

    if (partialConfig.storageMethod === null) {
      this.storageMethod = NULL_STORAGE
    } else if (partialConfig.storageMethod === undefined) {
      this.storageMethod = sessionStorage
    } else {
      this.storageMethod = partialConfig.storageMethod
    }

    this.trackerIdProvider = partialConfig.trackerIdProvider || this.getTrackerIdStorage
    this.userInfoProvider = partialConfig.userInfoProvider || (() => ({}))

    getPublicIp(this.storageMethod).then(ip => (this.environnement.host = ip))
  }

  getTrackerId() {
    return this.trackerIdProvider()
  }

  private getTrackerIdStorage() {
    let trackerId = this.storageMethod.getItem(PrismeConfig.TRACKERID_KEY)
    if (!trackerId) {
      trackerId = uuid()
      this.storageMethod.setItem(PrismeConfig.TRACKERID_KEY, trackerId)
    }
    return trackerId
  }

  setTrackerId(trackerId: string) {
    this.trackerIdProvider = () => trackerId
  }
}
