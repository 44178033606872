import { InputClickEvent } from '@bytel/trilogy-react-ts/lib/components/input/InputProps';
import { WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { EcoAstucesSearchFilters } from '@services/api/types';
import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { useListerEcoAstuces } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import useUrlToken from '@shared/hooks/use-url-token';
import { defaultPagination } from '@shared/misc/helpers/pagination';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseListerEcoAstucesProps } from './type';

const useData = (): UseListerEcoAstucesProps => {
  const { t } = useTranslation('domain/eco-astuces', {
    keyPrefix: 'list',
  });

  const siren = useSiren();
  const sirenToken = useUrlToken(RouteParameters.sirenId);

  const inputRef = useRef<HTMLInputElement | undefined>();
  const [searchFilters, setSearchFilters] = useState<EcoAstucesSearchFilters>({
    ...defaultPagination,
    ...{ limit: 50, limitBTE: 6 },
  });
  const handleSearchClick = useCallback(({ inputValue }: InputClickEvent) => {
    if (inputValue === '') return;
    setSearchFilters({
      ...defaultPagination,
      ...{ limit: 12, limitBTE: 6, title: inputValue },
    });
  }, []);

  const {
    data: ecoAstuces,
    isLoading,
    isFetching,
  } = useListerEcoAstuces(siren?._links?.listerEcoAstuces?.href, searchFilters);

  const ecoAstucesGestionnaire = useMemo(() => ecoAstuces?.items.filter(({ isBTE }) => !isBTE), [ecoAstuces?.items]);
  const ecoAstucesBTE = useMemo(() => ecoAstuces?.items.filter(({ isBTE }) => isBTE), [ecoAstuces?.items]);

  const navigationItemConfig = React.useMemo<NavigationItemConfig>(
    () => ({
      label: t('breadcrumb.title'),
      link: { href: WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString()) },
    }),
    [sirenToken, t],
  );
  return {
    urlCreate: siren?._actions.ajouterEcoAstuce,
    isLoading,
    isFetching,
    ecoAstucesGestionnaire: ecoAstucesGestionnaire ?? [],
    ecoAstucesBTE: ecoAstucesBTE ?? [],
    searchFilters,
    setSearchFilters,
    inputRef,
    handleSearchClick,
    navigationItemConfig,
    t,
  };
};

export default useData;
