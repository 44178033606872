import { XAction } from '@generated/YSER-v1';

export const emptyAction: XAction = {
  action: '/404',
  method: 'post',
  type: undefined,
};

export type MockAPIProviderProps<TApi> = {
  mockInstance?: TApi;
};
