/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */
import * as time from '@helpers/time';
import { EcoAstucesAPIService } from '@services/api';
import { EcoAstucesSearchFilters } from '@services/api/types';
import {
  EcoAstuce,
  EcoAstuceIn,
  EcoAstuceList,
  ErreurFonctionnelle,
  ImageUrl,
  UploadImageIn,
  XAction,
} from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React from 'react';
import { UseMutationResult, useQueryClient, UseQueryResult } from 'react-query';
import { useOverrideMutation, useOverrideQuery } from '../helpers';
import { emptyAction } from './type';

const EcoAstucesAPIContext = React.createContext<EcoAstucesAPIService | null>(null);
const EcoAstucesCacheKey = 'EcoAstuces';

export type EcoAstucesAPIProviderProps = {
  mockInstance?: EcoAstucesAPIService;
};

export const EcoAstucesAPIProvider: React.FC<EcoAstucesAPIProviderProps> = ({ children, mockInstance = undefined }) => {
  const BQuery = useBQuery();

  const instance = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new EcoAstucesAPIService(BQuery);
  }, [mockInstance, BQuery]);

  return <EcoAstucesAPIContext.Provider value={instance}>{children}</EcoAstucesAPIContext.Provider>;
};

export const useEcoAstucesAPI = (): EcoAstucesAPIService => {
  const context = React.useContext(EcoAstucesAPIContext);
  if (context === undefined || context === null) {
    throw new Error('The `useEcoAstucesAPI` hook must be used within a EcoAstucesAPIProvider');
  }
  return context;
};

export const useListerEcoAstuces = (
  href?: string,
  options?: EcoAstucesSearchFilters,
): UseQueryResult<EcoAstuceList | undefined, AxiosError<ErreurFonctionnelle>> => {
  const EcoAstucesAPI = useEcoAstucesAPI();
  return useOverrideQuery<EcoAstuceList | undefined, AxiosError<ErreurFonctionnelle>, EcoAstuceList | undefined>(
    [EcoAstucesCacheKey, href, options],
    () => EcoAstucesAPI.listerEcoAstuces(href, options),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};

export const useObtenirEcoAstuces = (
  href?: string,
): UseQueryResult<EcoAstuce | undefined, AxiosError<ErreurFonctionnelle>> => {
  const EcoAstucesAPI = useEcoAstucesAPI();
  return useOverrideQuery<EcoAstuce | undefined, AxiosError<ErreurFonctionnelle>, EcoAstuce | undefined>(
    [EcoAstucesCacheKey, href],
    () => EcoAstucesAPI.obtenirEcoAstuce(href),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};

export const useCreateEcoAstuce = (
  link?: XAction,
): UseMutationResult<EcoAstuce, AxiosError<ErreurFonctionnelle>, EcoAstuceIn> => {
  const EcoAstuceAPI = useEcoAstucesAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation<EcoAstuce, AxiosError<ErreurFonctionnelle>, EcoAstuceIn>(
    { key: 'ecoAstuce.creer', options: [{ key: 'title', prop: 'title' }] },
    (input: EcoAstuceIn) => EcoAstuceAPI.createEcoAstuce(link || emptyAction, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EcoAstucesCacheKey);
      },
    },
  );
};

export const useModifierEcoAstuce = (
  link?: XAction,
): UseMutationResult<EcoAstuce, AxiosError<ErreurFonctionnelle>, EcoAstuceIn> => {
  const EcoAstuceAPI = useEcoAstucesAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation<EcoAstuce, AxiosError<ErreurFonctionnelle>, EcoAstuceIn>(
    { key: 'ecoAstuce.modifier', options: [{ key: 'title', prop: 'title' }] },
    (input: EcoAstuceIn) => EcoAstuceAPI.modifierEcoAstuce(link || emptyAction, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(EcoAstucesCacheKey);
      },
    },
  );
};

export const useSupprimerEcoAstuces = (
  href: XAction | undefined,
): UseMutationResult<void, AxiosError<ErreurFonctionnelle>, void> => {
  const EcoAstucesAPI = useEcoAstucesAPI();
  const queryClient = useQueryClient();
  return useOverrideMutation(
    { key: 'ecoAstuce.supprimer' },
    () => EcoAstucesAPI.supprimerEcoAstuce(href || emptyAction),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(EcoAstucesCacheKey);
      },
    },
  );
};

export const useEnvoyerImageEcoAstuces = (
  href: XAction | undefined,
): UseMutationResult<ImageUrl, AxiosError<ErreurFonctionnelle>, any> => {
  const EcoAstucesAPI = useEcoAstucesAPI();
  return useOverrideMutation(
    { key: 'ecoAstuce.envoyerImage' },
    (input: UploadImageIn) => EcoAstucesAPI.envoyerImage(href || emptyAction, input),
    {
      onSuccess: () => {},
    },
  );
};
