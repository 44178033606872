import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  ButtonList,
  ButtonType,
  Columns,
  ColumnsItem,
  RowItem,
  Rows,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  VariantState,
} from '@bytel/trilogy-react-ts';
import React from 'react';
import RenderInputType from '../fields';
import useData from './hook';
import { FormProps, FormWrappedProps } from './type';

const Form = <T extends object>({ t, config, dataCy, withFilter, direction, isFetching }: FormProps<T>) => {
  const { formik, setInputValue, cancelCallback } = useData(config);
  const [displayErrors, setDisplayErrors] = React.useState<boolean>(false);

  return (
    <form
      data-cy={`form-${dataCy}`}
      onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await formik.validateForm();
        if (formik.isValid) {
          formik.handleSubmit(e);
        } else {
          setDisplayErrors(true);
        }
      }}
    >
      <Columns>
        <ColumnsItem className="has-background-white">
          <Rows data-cy={`container-${dataCy}`}>
            {withFilter && (
              <RowItem narrow>
                <Title className="is-marginless" data-cy={`form-title-${dataCy}`} level={TitleLevels.TWO}>
                  {t('filters')}
                </Title>
              </RowItem>
            )}
            <RowItem narrow>
              <Columns multiline data-cy={`form-fields-${dataCy}`} flex>
                <ColumnsItem>
                  {config?.configuration?.map(({ inputName, type, label, fieldConfig, displayConfig, placeholder }) => (
                    <ColumnsItem
                      data-cy={`form-${dataCy}-${inputName.toString()}`}
                      key={`${dataCy}-${inputName.toString()}`}
                      size={direction === 'row' ? 12 : 3}
                    >
                      {RenderInputType<T>({
                        inputName: inputName.toString(),
                        placeholder,
                        dataCy,
                        setInputValue,
                        formik,
                        type,
                        label,
                        fieldConfig,
                        displayConfig: {
                          ...displayConfig,
                        },
                        displayErrors,
                      })}
                    </ColumnsItem>
                  ))}
                  <ButtonList>
                    <ColumnsItem className="is-4">
                      <Button
                        fullwidth
                        className={`${isFetching ? 'is-loading' : 'is-loaded'} is-outlined`}
                        onClick={cancelCallback}
                        data-cy={`btn-cancel-${dataCy}`}
                        variant={VariantState.PRIMARY}
                        type={ButtonType.RESET}
                      >
                        <Text level={TextLevels.TWO}>{t(`cancel`)}</Text>
                      </Button>
                    </ColumnsItem>
                    <ColumnsItem className="is-4 is-offset-4">
                      <Button
                        fullwidth
                        className={`${isFetching ? 'is-loading' : 'is-loaded'}`}
                        variant={VariantState.TERTIARY}
                        data-cy={`btn-submit-${dataCy}`}
                        type={ButtonType.SUBMIT}
                      >
                        <Text level={TextLevels.TWO}>{t(`submit`)}</Text>
                      </Button>
                    </ColumnsItem>
                  </ButtonList>
                </ColumnsItem>
              </Columns>
            </RowItem>
          </Rows>
        </ColumnsItem>
      </Columns>
    </form>
  );
};

const FormWrapped = <T extends object>({
  t,
  config,
  dataCy,
  withFilter,
  direction,
  isFetching,
}: FormWrappedProps<T>) => {
  return (
    <Accordion data-cy={`accordion-${dataCy}`}>
      <AccordionItem active>
        <AccordionHeader toggle data-cy={`accordion-header-${dataCy}`}>
          <Title className="is-marginless" data-cy={`form-title-${dataCy}`} level={TitleLevels.TWO}>
            {t('filters')}
          </Title>
        </AccordionHeader>
        <AccordionBody>
          <Form
            t={t}
            config={config}
            dataCy={dataCy}
            withFilter={withFilter}
            direction={direction}
            isFetching={isFetching}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

const YserForm = <T extends object>({ t, config, dataCy, withFilter, direction, isFetching }: FormProps<T>) => {
  return direction === 'row' ? (
    <Form t={t} config={config} dataCy={dataCy} withFilter={withFilter} direction={direction} isFetching={isFetching} />
  ) : (
    <FormWrapped
      t={t}
      config={config}
      dataCy={dataCy}
      withFilter={withFilter}
      direction={direction}
      isFetching={isFetching}
    />
  );
};

export default YserForm;
