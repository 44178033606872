import { useTranslation } from 'react-i18next';

const useData = () => {
  const { t } = useTranslation('domain/eco-astuces', {
    keyPrefix: 'list',
  });

  return {
    t,
  };
};

export default useData;
