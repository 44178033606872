import { Button, ButtonMarkup, Icon, IconName, IconSize } from '@bytel/trilogy-react-ts';
import { useSiren } from '@shared/contexts/siren-context';

import { CSVLink } from 'react-csv';

import { useTranslation } from 'react-i18next';

import useDataGraph from './hook';

const GraphExporter: React.FC = () => {
  const siren = useSiren();

  const { t } = useTranslation('domain/home', {
    keyPrefix: 'consommationFlotte',
  });

  const { graphExporterProps, valueLabelCO2, valueLabelData } = useDataGraph();

  const now = new Date();

  const date = `${now.getUTCDate()}_${now.toLocaleString('default', { month: '2-digit' })}_${now.getUTCFullYear()}`;

  const header = [
    { label: 'Date', key: 'consoDataGraphDate' },
    { label: valueLabelCO2, key: 'consoDataGraphValue' },
    { label: valueLabelData, key: 'valueData' },
  ];

  return (
    <Button
      className="is-primary has-background-grey-light has-text-primary has-icon"
      markup={ButtonMarkup.BUTTON}
      data-cy="home-boxConsommationFlotte-graphExporter"
    >
      <Icon name={IconName.DOWNLOAD} size={IconSize.SMALL} />
      <CSVLink
        data={graphExporterProps}
        separator=";"
        headers={header}
        filename={`export-${siren?.id}-${date}.csv`}
        className="has-text-primary"
        data-cy="graphExporter-csvLink"
      >
        {t('export.download')}
      </CSVLink>
    </Button>
  );
};
export default GraphExporter;
