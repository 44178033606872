import { Columns, ColumnsItem } from '@bytel/trilogy-react-ts';
import { BaseInputTextProps } from '../type';
import InputTrilogy from './base/inputTrilogy';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const BaseInputComponent = <T extends object>(props: BaseInputTextProps<T>) => {
  const {
    fieldConfig,
    inputName,
    dataCy,
    setInputValue,
    formik,
    label,
    placeholder,
    displayConfig,
    displayErrors,
    type,
  } = props;
  const { required } = fieldConfig;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <InputTrilogy
          inputName={inputName}
          dataCy={`${type}Type-${dataCy}-${inputName}`}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
          type={type}
        />
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default BaseInputComponent;
