import { Columns, ColumnsItem, Section, TrilogyColor } from '@bytel/trilogy-react-ts';
import NavigationItem from '@shared/components/navigation-item';
import YserForm from '@shared/components/ui-components/form/yser-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useData from './hook';

const EditerActualites: React.FC = () => {
  const { config, navigationItemConfig, isFetching, isLoading } = useData();
  const { t } = useTranslation('shared/generic-form');

  return (
    <>
      <NavigationItem config={navigationItemConfig} />
      <Section data-cy="editer-actualite-container" skeleton={isFetching || isLoading} background={TrilogyColor.WHITE}>
        <Columns className="is-centered fullwidth">
          <ColumnsItem size={6} offset={3}>
            <YserForm dataCy="yser-editer-actualite" config={config} t={t} direction="row" />
          </ColumnsItem>
        </Columns>
      </Section>
    </>
  );
};

export default EditerActualites;
