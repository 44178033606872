import { ConsommationFlotte } from '@services/interfaces/generated/YSER-v1';
import { GLOBAL_CONSO_ID } from '@shared/constants';
import { useAPIDescription, useObtenirConsommationFlotte } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import { TransformBaseType, transformUnit } from '@shared/misc/helpers/data';
import { useCallback, useMemo } from 'react';
import { ConsommationFlotteData, ConsommationFlotteRecap, UseConsommationFlotteProps } from './type';

const useData = (): UseConsommationFlotteProps => {
  const siren = useSiren();
  const { data: apiDescription } = useAPIDescription();

  const consommationUrl =
    siren?.id !== GLOBAL_CONSO_ID
      ? siren?._links?.obtenirConsommationFlotte?.href
      : apiDescription?._links?.obtenirConsommationGlobale?.href;

  const { data } = useObtenirConsommationFlotte(consommationUrl);

  const last = useMemo<ConsommationFlotteRecap | undefined>(() => {
    if (data?.resultsCount) {
      const result = data.items[data.resultsCount - 1];
      const calculatedData = transformUnit(result.data, 'data');
      return { ...result, data: calculatedData, co2eq: transformUnit(result.co2eq, 'co2eq') };
    }
    return undefined;
  }, [data]);

  const selectedUnit = useMemo<TransformBaseType>(() => {
    const tabNumbersCo2 = data?.items.map((item) => item.co2eq) ?? [];
    const valeurCo2 = Math.max(...tabNumbersCo2);
    const tempUnit = transformUnit(valeurCo2, 'co2eq');
    return tempUnit;
  }, [data?.items]);

  const convertDataToPoint = useCallback(
    (item?: ConsommationFlotte): ConsommationFlotteData => {
      const value = item?.co2eq ?? 0;
      const dateToFormat = new Date(item?.date ?? new Date());
      const month = dateToFormat.toLocaleString('default', { month: 'short' }).replace('.', '');
      const year = dateToFormat.getFullYear() % 100;
      const valueWithSelectedUnit = transformUnit(value, 'co2eq', selectedUnit.index);
      return {
        x: `${month.charAt(0).toUpperCase() + month.slice(1)}.${year}`,
        y: +valueWithSelectedUnit.value,
      };
    },
    [selectedUnit.index],
  );

  const consoData = useMemo<ConsommationFlotteData[] | undefined>(() => {
    if (data?.resultsCount) {
      return data?.items.map((item) => {
        return convertDataToPoint(item);
      });
    }
    return [convertDataToPoint()];
  }, [data?.items, convertDataToPoint, data?.resultsCount]);

  return {
    data,
    last,
    consoData,
    unitToShow: selectedUnit.unit,
  };
};

export default useData;
