import {
  Box,
  BoxContent,
  Button,
  Columns,
  ColumnsItem,
  Icon,
  IconSize,
  Link,
  RowItem,
  Rows,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  VariantState,
} from '@bytel/trilogy-react-ts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as routerLink } from 'react-router-dom';
import { BtnNavigationProps } from '../button-navigation/type';

const BoxContentNavigation: React.FC<BtnNavigationProps> = ({
  routeRef,
  btnName,
  description,
  iconName,
  dataCy,
  titre,
}) => {
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'navButtons',
  });
  return (
    <Box>
      <BoxContent className="is-marginless">
        <Columns className="is-flex is-justified-center">
          <ColumnsItem size={10}>
            <Rows className="is-aligned-center is-justified-center">
              <Columns verticalCentered centered flex className="is-paddingless">
                <Icon name={iconName} size={IconSize.HUGE} data-cy={`icon-${dataCy}`} />
                <Title className="is-unselectable" level={TitleLevels.THREE} data-cy={`title-${dataCy}`}>
                  {titre}
                </Title>
              </Columns>
              <RowItem className="has-text-centered has-text-primary ">
                <Text className="is-unselectable" level={TextLevels.TWO} data-cy={`description-${dataCy}`}>
                  {description}
                </Text>
              </RowItem>
              {/* Ajout de l'espace quand c'est le bouton partenaires pour tout aligner */}
              {btnName === t('modifierPartenaire') ? <RowItem>{/* space */}</RowItem> : ''}
              <RowItem>{/* space */}</RowItem>
              <RowItem>
                {routeRef !== undefined && routeRef.href ? (
                  <Link to={routeRef?.href} routerLink={routerLink} data-cy={`link-${dataCy}`} className="is-plain">
                    <Button variant={VariantState.TERTIARY} className=" has-border-radius">
                      <Text level={TextLevels.TWO}>{btnName}</Text>
                    </Button>
                  </Link>
                ) : (
                  <Button variant={VariantState.TERTIARY} className=" has-border-radius" disabled>
                    <Text level={TextLevels.TWO}>{btnName}</Text>
                  </Button>
                )}
              </RowItem>
            </Rows>
          </ColumnsItem>
        </Columns>
      </BoxContent>
    </Box>
  );
};

export default BoxContentNavigation;
