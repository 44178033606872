import {
  Columns,
  ColumnsItem,
  Icon,
  IconName,
  IconSize,
  RowItem,
  Rows,
  Text,
  TextLevels,
  TypographyColor,
  View,
} from '@bytel/trilogy-react-ts';
import React, { useCallback, useState } from 'react';
import { ImagePickerInputProps } from '../type';

const ImagePickerComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  placeholder,
  label,
  fieldConfig,
  displayConfig,
}: ImagePickerInputProps<T>) => {
  const { onChangeEvent, required, imagePlaceholder, imageStyle } = fieldConfig;
  const { values } = formik;

  const fileReader = React.useMemo(() => {
    return new FileReader();
  }, []);

  const [myFile, setMyFile] = useState<File | null>(null);
  const [myEncodedFile, setMyEncodedFile] = useState<string | null>(null);

  const inputImageRef = React.useRef<HTMLInputElement | null>(null);

  const onClick = useCallback(() => {
    inputImageRef?.current?.click();
  }, [inputImageRef]);

  const onLoadEvent = React.useCallback(() => {
    if (fileReader.result) {
      setMyEncodedFile(fileReader.result.toString());
      setInputValue(inputName, myFile);
      if (onChangeEvent) onChangeEvent(formik, values as T, values[inputName]);
    }
  }, [fileReader.result, formik, inputName, myFile, onChangeEvent, setInputValue, values]);

  React.useEffect(() => {
    fileReader.addEventListener('load', onLoadEvent);
    return () => {
      fileReader.removeEventListener('load', onLoadEvent);
    };
  }, [fileReader, onLoadEvent]);

  const handleImageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const imgFile = event.target.files?.[0];
      if (imgFile) {
        fileReader.readAsDataURL(imgFile);
        setMyFile(imgFile);
      }
    },
    [fileReader],
  );

  return (
    <Columns verticalCentered={displayConfig?.isPopupDisplay}>
      <ColumnsItem className="is-centered">
        <Rows className={`is-marginless ${!imagePlaceholder ? '' : ' has-background-white'}`}>
          <RowItem className="has-background-white is-flex is-centered is-vcentered">
            <Icon name={IconName.PLUS_CIRCLE} size={IconSize.SMALL} />
            <Text
              className="is-unselectable link"
              style={{ cursor: 'pointer' }}
              level={TextLevels.ONE}
              data-cy={`preCompleteInput-textType-${dataCy}-${inputName}`}
              onClick={onClick}
            >
              {label}{' '}
              <Text typo={TypographyColor.TEXT_ERROR} className="has-text-tertiary">
                &nbsp;*
              </Text>
            </Text>
          </RowItem>
          <RowItem>
            <input
              name={inputName}
              ref={inputImageRef}
              id="files"
              type="file"
              alt={label}
              hidden
              required={required}
              onChange={handleImageChange}
            />
            <View className="is-fullwidth" onClick={onClick}>
              {(imagePlaceholder || myEncodedFile || values[inputName]) && (
                <img
                  style={imageStyle}
                  src={myEncodedFile ?? values[inputName] ?? imagePlaceholder}
                  alt={placeholder}
                />
              )}
            </View>
          </RowItem>
        </Rows>
      </ColumnsItem>
    </Columns>
  );
};

export default ImagePickerComponent;
