import {
  Button,
  ButtonList,
  ButtonMarkup,
  Columns,
  ColumnsItem,
  Container,
  Hero,
  Icon,
  IconName,
  IconPosition,
  IconSize,
  Section,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  VariantState,
  View,
} from '@bytel/trilogy-react-ts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useData from './hook';

const Error: React.FC = () => {
  const { onClick, erreurToken } = useData();
  const { t } = useTranslation('shared/error-page');

  return (
    <div style={{ height: 'calc(100vh - 125px)' }}>
      <Section className="is-marginless is-paddingless is-fullheight" data-cy="erreur-section">
        <Container className="is-fluid is-fullwidth is-fullheight" data-cy="erreur-container">
          <Hero
            backgroundSrc={t<string>(`errors.${erreurToken}.image`)}
            className="is-fullheight"
            data-cy="erreur-background"
          >
            <Columns className="is-fullwidth">
              <ColumnsItem size={4} className="has-background-white has-border-radius-large is-offset-2">
                <View style={{ paddingLeft: '10px' }}>
                  <Title
                    level={TitleLevels.ONE}
                    className="has-text-secondary"
                    data-cy="erreur-title"
                    style={{ paddingTop: '10px' }}
                  >
                    {t(`errors.${erreurToken}.titre`)}
                  </Title>
                  <Text level={TextLevels.ONE} className="has-text-primary" data-cy="erreur-description">
                    {t(`errors.${erreurToken}.description`)}
                  </Text>
                  <Text level={TextLevels.ONE} className="has-text-primary" data-cy="erreur-gestionnaire">
                    {t(`errors.${erreurToken}.gestionnaire`)}
                  </Text>
                </View>
                <ColumnsItem className="is-12">
                  <ButtonList centered>
                    <Button
                      data-cy="erreur-button"
                      markup={ButtonMarkup.BUTTON}
                      variant={VariantState.PRIMARY}
                      small
                      onClick={onClick}
                      className="is-centered"
                    >
                      <Icon
                        name={IconName.POWER}
                        size={IconSize.SMALL}
                        content={t('deconnection')}
                        position={IconPosition.LEFT}
                      />
                    </Button>
                  </ButtonList>
                </ColumnsItem>
              </ColumnsItem>
            </Columns>
          </Hero>
        </Container>
      </Section>
    </div>
  );
};

export default Error;
