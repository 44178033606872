/* eslint-disable react/require-default-props */
import AxiosPrismeLogger from '@bytel/prisme-logger/lib/axios';
import { useUserInfos } from '@shared/contexts/auth-provider';
import getConfig from '@shared/misc/configuration';
import { TypeConfig } from '@shared/misc/configuration/type';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

const LoggerContext = React.createContext<AxiosPrismeLogger | null>(null);

export type LoggerProviderProps = {
  logger?: AxiosPrismeLogger;
};

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children, logger }) => {
  const userInfos = useUserInfos();
  const [config, setConfig] = useState<TypeConfig | null>(null);

  useEffect(() => {
    const load = async () => {
      const c = await getConfig();
      setConfig(c);
    };

    if (logger) {
      // Si un logger est déjà passé en paramètre, pas besoin d'aller charger la configuration
      return;
    }

    load();
  }, [setConfig, logger]);

  const LOGGER = React.useMemo(() => {
    if (logger) return logger;
    if (config === null) return null;

    return new AxiosPrismeLogger({
      environnement: config?.ENVIRONNEMENT || '',
      st: config?.ST || '',
      urlEndpoint: config?.PRISME_ENDPOINT || '',
      composant: 'ihm',
      version: config?.VERSION,
      userInfoProvider: () => {
        return {
          habilit: userInfos?.utilisateurEffectif.roles?.join('; '),
          login: userInfos?.email,
          userType: userInfos?.famille,
        };
      },
    });
  }, [config, userInfos, logger]);

  if (LOGGER === undefined || LOGGER === null) return null;

  return <LoggerContext.Provider value={LOGGER}>{children}</LoggerContext.Provider>;
};

export const useLogger = (): AxiosPrismeLogger => {
  const context = useContext(LoggerContext);
  if (context === undefined || context === null) {
    throw new Error('The `useLogger` hook must be used within a LoggerProvider');
  }
  return context;
};
