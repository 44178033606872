import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { PrismeLogger } from '..'
import { construireContexte, construireExecAppelRest, construireHeadersTracabilite } from '../builders/messageBuilder'
import { Erreur } from '../interfaces/notifierMessagePrisme'
export class AxiosPrismeLogger extends PrismeLogger {
  wrapAxiosInstance(axios: AxiosInstance) {
    axios.interceptors.request.use(r => this.onRequest(r))
    axios.interceptors.response.use(
      r => this.onResponseSuccess(r),
      r => this.onResponseError(r)
    )
  }

  private onRequest(request: AxiosRequestConfig) {
    try {
      request.headers = construireHeadersTracabilite(this.config, request.headers)
    } catch (error) {
      console.log("Erreur lors de l'ajout des headers de traçabilité", error)
    }
    return request
  }

  private onResponseSuccess(response: AxiosResponse): AxiosResponse {
    if (response.request.fromCache) {
      this._onCachedResponse(response)
    } else {
      this._onResponse(response)
    }
    return response
  }

  private onResponseError(error: AxiosError) {
    if (error.response) {
      this._onResponse(error.response, error)
    }
    throw error
  }

  private _onResponse(response: AxiosResponse, error: AxiosError | null = null) {
    try {
      const currentTime = Date.now()

      const axiosConfig = response?.config || error?.config
      let parsedData
      try {
        parsedData = JSON.parse(axiosConfig.data)
      } catch {
        parsedData = axiosConfig.data
      }

      const execAppelRest = construireExecAppelRest(
        this.config,
        currentTime,
        axiosConfig.headers,
        axiosConfig.url,
        axiosConfig.method,
        parsedData,
        response.status,
        construireErreurAxios(error)
      )
      const ctx = construireContexte(this.config, { headers: axiosConfig.headers })
      this.sender.logMessagePrisme({
        ctx,
        events: [execAppelRest]
      })
    } catch (error) {
      console.error("Erreur lors de l'envoie des logs de l'appel Rest", error)
    }
  }

  private _onCachedResponse(response: AxiosResponse) {
    // On ne fait rien si l'appel est en cache
  }
}

function construireErreurAxios(error: AxiosError | null): Erreur | undefined {
  if (error == null) {
    return undefined
  }
  const parameters = error.response?.data?.error_parameters
  const parametersList = typeof parameters === 'string' ? [parameters] : parameters
  return {
    code: error.code || 'ERREUR_APPEL_RESS',
    type:
      error.response?.status && error.response?.status >= 400 && error.response?.status < 500
        ? 'FONCTIONNELLE'
        : 'TECHNIQUE',
    description: error.response?.data?.error_description || error.message,
    parametres: parametersList,
    criticite: error.response?.status && [502, 503, 504].includes(error.response?.status) ? 'INDISPO' : 'FATALE'
  }
}
