import {
  Columns,
  ColumnsItem,
  Container,
  Link,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import BoxBilanCarbone from '@shared/components/box-bilan-carbone';
import BoxContentNavigation from '@shared/components/navigation-box-content';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoxConfigurationMobile from './box-configuration-mobile';
import BoxConsommationFlotte from './box-consommation-flotte';
import useData from './hook';

const Home: React.FC = () => {
  const { buttons } = useData();
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'page',
  });
  return (
    <Container className="is-fullwidth">
      <Section data-cy="home-container" background={TrilogyColor.GREY_LIGHT}>
        <Container className="is-fullwidth">
          <Rows>
            <RowItem>
              <Title
                style={{ cursor: 'default', pointerEvents: 'none' }}
                level={TitleLevels.ONE}
                className="has-text-centered"
              >
                {t('titre')}
                <Link className="is-plain" to="none">
                  <Title level={TitleLevels.ONE} className="has-text-secondary">
                    {t('titreSuite')}
                  </Title>
                </Link>
              </Title>
            </RowItem>
            <RowItem>
              <Text level={TextLevels.ONE} className="has-text-centered">
                {t('sousTitre')}
              </Text>
            </RowItem>
            <RowItem>
              <RowItem className="is-paddingless" data-cy="home-box-consommation">
                <BoxConsommationFlotte />
              </RowItem>
              <RowItem>
                <Text level={TextLevels.THREE} className="has-text-justified">
                  {t('asterix')}
                </Text>
              </RowItem>
            </RowItem>
            <RowItem data-cy="home-pile-nav">
              <BoxBilanCarbone />
            </RowItem>
          </Rows>
        </Container>
      </Section>
      <Section background={TrilogyColor.WHITE} />
      <Section data-cy="home-container" background={TrilogyColor.GREY_LIGHT}>
        <Container>
          <Rows>
            <RowItem>
              <BoxConfigurationMobile />
            </RowItem>
            <RowItem>
              <Columns>
                {buttons.map(({ dataCy, titre, description, routeRef, iconName, btnName }) => (
                  <ColumnsItem key={dataCy} className="is-flex is-fullheigth">
                    <BoxContentNavigation
                      dataCy={dataCy}
                      titre={titre}
                      description={description}
                      routeRef={routeRef}
                      iconName={iconName}
                      btnName={btnName}
                    />
                  </ColumnsItem>
                ))}
              </Columns>
            </RowItem>
          </Rows>
        </Container>
      </Section>
    </Container>
  );
};

export default Home;
