import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useFormikData from '../hook';
import { FormikConfigType, UseFormikData } from '../type';

const useData = <T,>(config: FormikConfigType<T>): UseFormikData<T> => {
  const { formik, setInputValue } = useFormikData<T>(config);

  const history = useHistory();

  const cancelCallback = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    formik,
    setInputValue,
    cancelCallback,
  };
};

export default useData;
