import { ConsommationFlotteList } from '@generated/YSER-v1';
import { emptyGuid } from '@shared/constants';
import APIServicesBase from './APIServicesBase';
import { ConsommationFlotteOptions } from './types';
import { setQueryParams } from './utils';

export default class ConsommationFlotteAPIService extends APIServicesBase {
  obtenirConsommationFlotte(
    href?: string,
    options?: ConsommationFlotteOptions,
  ): Promise<ConsommationFlotteList | undefined> {
    if (href === undefined || href === emptyGuid) {
      return Promise.resolve(undefined);
    }

    const url = this.getUrl(href);
    setQueryParams(url, options);
    return this.bquery.get<ConsommationFlotteList>(url.toString());
  }
}
