import { construireContexte, construireLog } from './builders/messageBuilder'
import { PartialPrismeConfig, PrismeConfig } from './config'
import { Erreur } from './interfaces/notifierMessagePrisme'
import { SenderPrisme } from './sender'

export class PrismeLogger {
  config: PrismeConfig
  sender: SenderPrisme

  constructor(config: PartialPrismeConfig) {
    this.config = new PrismeConfig(config)
    this.sender = new SenderPrisme(this.config)
  }

  async logToPrisme(
    message: string,
    { erreur, action, ...specifiqueMetier }: { erreur?: Erreur | Error; action?: string; [x: string]: unknown } = {}
  ) {
    try {
      const currentTime = Date.now()
      const ctx = construireContexte(this.config, specifiqueMetier)
      const log = construireLog(this.config, currentTime, message, erreur, action)
      this.sender.logMessagePrisme({
        ctx,
        events: [log]
      })
    } catch (error) {
      console.error("Erreur lors de l'envoie du message dans prisme", error)
    }
  }

  forcerEnvoiQueueMessages() {
    return this.sender.forcerEnvoiQueueMessages()
  }

  setTrackerId(trackerId: string) {
    return this.config.setTrackerId(trackerId)
  }
}

export { PartialPrismeConfig, PrismeConfig } from './config'
