import { truncateText } from '@shared/misc/helpers/strings';
import { useMemo } from 'react';

const MAX_TEXT_CHARACTERS = 120;
const MAX_TITLE_CHARACTERS = 30;

const useData = (text: string | undefined, title: string | undefined) => {
  const truncatedTextContent = useMemo<string>(() => {
    return truncateText(text, MAX_TEXT_CHARACTERS);
  }, [text]);

  const truncatedTitleContent = useMemo<string>(() => {
    return truncateText(title, MAX_TITLE_CHARACTERS);
  }, [title]);

  return { truncatedTextContent, truncatedTitleContent };
};

export default useData;
