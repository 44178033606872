import React from 'react';
import { AutoCompleteMenuProps } from './AutoCompleteMenuProps';

/**
 * AutoCompleteMenu Component
 * @param children {ReactNode} Children
 */
const AutoCompleteMenu = ({ children }: AutoCompleteMenuProps): JSX.Element => {
  return (
    <div role="list" className="autocomplete-menu">
      {children}
    </div>
  );
};

export default AutoCompleteMenu;
