import { ColumnsItem, Text, TypographyColor } from '@bytel/trilogy-react-ts';
import { ErrorProps } from '../type';

const ErrorComponent = <T extends object>({
  inputName,
  dataCy,
  formik,
  displayConfig,
  displayErrors,
}: ErrorProps<T>) => {
  const { errors } = formik;
  const isPopup = !!displayConfig?.isPopupDisplay;
  const popupSize = isPopup ? 4 : 12;
  const className = isPopup ? '' : 'has-text-left';
  return errors[inputName] && displayErrors ? (
    <ColumnsItem size={popupSize} className={className}>
      <Text
        data-cy={`textError-${dataCy}-${inputName}`}
        typo={TypographyColor.TEXT_ERROR}
        className="has-text-tertiary"
      >
        {errors[inputName]}
      </Text>
    </ColumnsItem>
  ) : null;
};
export default ErrorComponent;
