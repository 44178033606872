import { Columns, ColumnsItem, Input, InputAutoCompleteType } from '@bytel/trilogy-react-ts';
import { InputChangeEvent } from '@bytel/trilogy-react-ts/lib/components/input/InputProps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEscape, isSearchInput, isTab } from '../form/fields/util';
import useDebounce from '../hooks/use-debounce';
import useEffectOnce from '../hooks/use-effect-once';
import { AutoCompleteProps, AutoCompleteResultState } from './AutoCompleteProps';
import AutoCompleteItem from './item/AutoCompleteItem';
import AutoCompleteMenu from './menu/AutoCompleteMenu';

const autocompleteClasses = 'is-autocomplete is-active';

const AutoComplete = <T extends object>({
  dataCy,
  defaultValue,
  data,
  onSelected,
  onSearchAsync,
  mapToOption,
  placeholder,
  disabled,
  name,
  searchDelay,
  onBlur,
  allowEmptyValueOnBlur,
}: AutoCompleteProps<T>): JSX.Element => {
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [isAutocompleteMenuVisible, setIsAutocompleteMenuVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(() => {
    let result = '';
    if (defaultValue) result = mapToOption(defaultValue).value;
    return result;
  });
  const debouncedSearch = useDebounce<string>(search, searchDelay ?? 350);
  const [suggestions, setSuggestions] = useState<T[]>(data ?? []);
  const [selection, setSelection] = useState<T | undefined>(defaultValue);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const [isBlured, setIsBlured] = useState(false);
  const debouncedBlur = useDebounce(isBlured, 250);

  // Permet d'executer l'init de formik
  useEffectOnce(() => {
    if (onSelected && defaultValue) onSelected(defaultValue);
  });

  // Permet d'initialiser lors d'un rerender
  useEffect(() => {
    if (defaultValue && !isInit) {
      setIsInit(true);
      setSearch(mapToOption(defaultValue).value);
    }
  }, [defaultValue, mapToOption, isInit]);

  // Prépare relance la recherche à l'entrée dans le composant, si aucune recherche n'a été lancée
  const startSearch = useCallback(
    (searchedValue: string) => {
      setIsAutocompleteMenuVisible(true);
      setIsSearching(true);
      setSearch(searchedValue);
    },
    [setIsAutocompleteMenuVisible, setIsSearching, setSearch],
  );

  // Prépare la recherche lors de la modification d'un texte
  const onTextChangedInternal = useCallback(
    (e: InputChangeEvent) => {
      if (search !== e.inputValue) {
        startSearch(e.inputValue);
      }
    },
    [search, startSearch],
  );

  // Prépare relance la recherche à l'entrée dans le composant, si aucune recherche n'a été lancée
  const onFocusInternal = useCallback(() => {
    setIsFocuses(true);
    setIsAutocompleteMenuVisible(true);
    if (search === '' && search === debouncedSearch) {
      if (suggestions?.length === 0 || !selection) {
        startSearch('');
      }
    }
  }, [search, selection, debouncedSearch, suggestions, startSearch]);

  // Prépare relance la recherche à l'entrée dans le composant, si aucune recherche n'a été lancée
  const onBlurInternal = useCallback(() => {
    setIsBlured(true);
    if (onBlur) onBlur();
  }, [onBlur, setIsBlured]);

  // Permet de détecter les sortie du champs (Tab + Esc)
  const onKeyPressInternal = useCallback(
    (e: KeyboardEvent) => {
      if (isAutocompleteMenuVisible && (isEscape(e.key) || isTab(e.key))) {
        setIsAutocompleteMenuVisible(false);
        setIsSearching(false);
      }
    },
    [isAutocompleteMenuVisible, setIsAutocompleteMenuVisible, setIsSearching],
  );

  // Ferme l'autocomplete après sortie de celui-ci, si ce n'est pas pour sélectionner un élément
  useEffect(() => {
    if (data !== undefined) {
      setSuggestions(data ?? []);
      if (!isFocuses) {
        if (selection) {
          const { key } = mapToOption(selection);
          const current = data.find((item) => mapToOption(item).key === key);

          if (current) {
            const { key: currentKey } = mapToOption(current);
            if (key !== currentKey) {
              setSelection(current);
              setSearch(mapToOption(current).value);
              onSelected?.(current);
            }
          } else {
            setSelection(undefined);
            setSearch('');
            onSelected?.(undefined);
          }
        }
      }
    }
  }, [data, selection, isFocuses, mapToOption, onSelected, setSuggestions, setSearch, setSelection]);

  // Effectue une recherche après la modification du texte
  useEffect(() => {
    let isCanceled = false;
    const searchAsync = async () => {
      if (onSearchAsync !== undefined && isSearching && search === debouncedSearch) {
        const result = await onSearchAsync(debouncedSearch);
        if (!isCanceled) {
          if (Array.isArray(result)) {
            // Équivaut à un result?.state === AutoCompleteResultState.OnSearch
            setSuggestions(result || []);
          } else if (result?.state === undefined || result?.state === AutoCompleteResultState.OnSearch) {
            setSuggestions(result?.items || []);
          }
          setIsSearching(false);
        }
      }
    };
    searchAsync();
    return () => {
      isCanceled = true;
    };
  }, [search, debouncedSearch, isSearching, onSearchAsync]);

  // Ferme l'autocomplete après sortie de celui-ci, si ce n'est pas pour sélectionner un élément
  useEffect(() => {
    if (debouncedBlur) {
      setIsAutocompleteMenuVisible(false);
      if (selection !== undefined) {
        const selectedValue = selection !== undefined ? mapToOption(selection).value : '';
        if ((search === '' || search !== selectedValue) && allowEmptyValueOnBlur === true) {
          setSelection(undefined);
          setSearch('');
          onSelected?.(undefined);
        } else {
          setSearch(selectedValue);
        }
      }
      setIsBlured(false);
    }

    setIsFocuses(false);
  }, [
    selection,
    debouncedBlur,
    isBlured,
    search,
    allowEmptyValueOnBlur,
    setSelection,
    onSelected,
    mapToOption,
    setIsBlured,
    setSearch,
    setIsAutocompleteMenuVisible,
  ]);

  // Lance la détection des appuies sur les touches Tab et Échap
  useEffect(() => {
    document.addEventListener('keydown', onKeyPressInternal, false);
    return () => {
      document.removeEventListener('keydown', onKeyPressInternal, false);
    };
  }, [onKeyPressInternal]);

  // Handler de sélection d'une valeur
  const suggestionSelected = useCallback(
    (selected: T, text: string) => {
      setSearch(text);
      setSuggestions([]);
      onSelected?.(selected);
      setSelection(selected);
      setIsAutocompleteMenuVisible(false);
      setIsSearching(false);
    },
    [setSearch, setSuggestions, onSelected, setSelection, setIsAutocompleteMenuVisible, setIsSearching],
  );

  const isSearch = useMemo(() => {
    return isSearchInput(placeholder);
  }, [placeholder]);

  const mappedSelection = selection ? mapToOption(selection) : undefined;

  return (
    <div className="field">
      <div className="control">
        <Columns>
          <ColumnsItem>
            <Input
              data-cy={`autocomplete-input-${dataCy}-${name}`}
              type="text"
              name={name}
              placeholder={placeholder}
              search={isSearch}
              hasIcon={isSearch}
              disabled={disabled}
              className="autocomplete-input"
              value={search}
              onChange={onTextChangedInternal}
              onFocus={onFocusInternal}
              autoCompleteType={InputAutoCompleteType.OFF}
              onBlur={onBlurInternal}
            />
          </ColumnsItem>
          {isSearching && (
            <ColumnsItem narrow className="pl-0">
              <span className="icon h-100p" data-cy={`autocomplete-spinner-${dataCy}-${name}`}>
                <i className="spinner spinner-icon" />
              </span>
            </ColumnsItem>
          )}
        </Columns>

        {isAutocompleteMenuVisible && (
          <div className={autocompleteClasses}>
            {suggestions.length > 0 && (
              <AutoCompleteMenu>
                {suggestions.map((item) => {
                  const mapped = mapToOption(item);
                  return (
                    <AutoCompleteItem<T>
                      dataCy={`${dataCy}-${mapped.key}`}
                      key={mapped.key}
                      item={item}
                      onSelected={(selected) => suggestionSelected(selected, mapped.value)}
                    >
                      {mapped.value}
                    </AutoCompleteItem>
                  );
                })}
              </AutoCompleteMenu>
            )}
            {suggestions.length === 0 && selection !== undefined && mappedSelection !== undefined && (
              <AutoCompleteMenu>
                <AutoCompleteItem<T>
                  dataCy={`${dataCy}-${mappedSelection.key}`}
                  key={mappedSelection.key}
                  item={selection}
                  onSelected={(selected) => suggestionSelected(selected, mappedSelection.value)}
                >
                  {mappedSelection.value}
                </AutoCompleteItem>
              </AutoCompleteMenu>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoComplete;
