import { FormikHelpers, useFormik } from 'formik';
import { useCallback } from 'react';
import { FormikConfigType, InitialValuesType, UseFormikData } from './type';

const useFormikData = <T,>(config: FormikConfigType<T>): UseFormikData<T> => {
  const formik = useFormik<InitialValuesType<InitialValuesType<T>>>({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: config?.initialValues ?? {},
    validationSchema: config?.validationSchema,
    onSubmit: (obj: InitialValuesType<T>, formikHelpers: FormikHelpers<InitialValuesType<T>>) => {
      config?.onSubmit(obj as T, formikHelpers);
    },
    onReset: () => {
      console.log('reset not implemented');
    },
  });
  const setInputValue = useCallback((key, value) => formik.setFieldValue(key, value), [formik]);

  const cancelCallback = useCallback(() => {
    console.log('cancel callback not implemented');
  }, []);
  return {
    formik,
    setInputValue,
    cancelCallback,
  };
};

export default useFormikData;
