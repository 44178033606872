import { EditerActualites, ListerActualites } from '@domain/actualites';
import Error from '@domain/app/error';
import { EditerEcoAstuces, ListerEcoAstuces, ListerEcoAstucesBTE } from '@domain/ecoAstuces';
import Home from '@domain/home';
import LogoutPage from '@domain/logout';
import NeutraliteCarbone from '@domain/neutraliteCarbone';
import ListerPartenaires from '@domain/partenaires/lister-partenaires';
import {
  ACTUALITES_LIST_PATH,
  ACTUALITE_EDIT_PATH,
  ACTUALITE_NEW_PATH,
  CONTRIBUTION_NEUTRALITE_PATH,
  ECOASTUCES_LIST_BTE_PATH,
  ECOASTUCES_LIST_PATH,
  ECOASTUCE_EDIT_PATH,
  ECOASTUCE_NEW_PATH,
  ERREUR_PATH,
  LOGOUT_PATH,
  PARTENAIRES_PAGE_PATH,
  WELCOME_PATH,
} from './paths';
import { RouteLocations, RouteType, UserRoles } from './types';

const ecoAstuceRoutes: RouteType[] = [
  {
    path: ECOASTUCES_LIST_PATH,
    title: 'ecoAstuces',
    location: RouteLocations.Global,
    composant: ListerEcoAstuces,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
  {
    path: ECOASTUCES_LIST_BTE_PATH,
    title: 'ecoAstucesBTE',
    location: RouteLocations.IsSubMenu,
    composant: ListerEcoAstucesBTE,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
  {
    path: ECOASTUCE_NEW_PATH,
    title: 'newEcoAstuces',
    location: RouteLocations.IsSubMenu,
    composant: EditerEcoAstuces,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
  {
    path: ECOASTUCE_EDIT_PATH,
    title: 'editEcoAstuces',
    location: RouteLocations.IsSubMenu,
    composant: EditerEcoAstuces,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
];

const actualiteRoutes: RouteType[] = [
  {
    path: ACTUALITES_LIST_PATH,
    title: 'Actualites',
    location: RouteLocations.Global,
    composant: ListerActualites,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
  {
    path: ACTUALITE_NEW_PATH,
    title: 'newActualites',
    location: RouteLocations.IsSubMenu,
    composant: EditerActualites,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
  {
    path: ACTUALITE_EDIT_PATH,
    title: 'editActualites',
    location: RouteLocations.IsSubMenu,
    composant: EditerActualites,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
];

const pagePartenairesRoutes: RouteType[] = [
  {
    path: PARTENAIRES_PAGE_PATH,
    title: 'partenaires',
    location: RouteLocations.IsSubMenu,
    composant: ListerPartenaires,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
];

const contributionNeutraliteRoutes: RouteType[] = [
  {
    path: CONTRIBUTION_NEUTRALITE_PATH,
    title: 'contributionNeutralite',
    location: RouteLocations.Global,
    composant: NeutraliteCarbone,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
];

const commonRoutes: RouteType[] = [
  {
    path: ERREUR_PATH,
    title: `Erreur`,
    location: RouteLocations.Global,
    composant: Error,
    roles: [],
  },
  {
    path: LOGOUT_PATH,
    title: 'logout',
    location: RouteLocations.Global,
    composant: LogoutPage,
    roles: [UserRoles.ADMIN, UserRoles.USER1],
  },
];

export const ROUTES: RouteType[] = [
  ...commonRoutes,

  ...ecoAstuceRoutes,

  ...actualiteRoutes,

  ...contributionNeutraliteRoutes,

  ...pagePartenairesRoutes,

  // Insérez votre nouvelle routes ici
  {
    path: WELCOME_PATH,
    title: 'home',
    location: RouteLocations.Global,
    composant: Home,
  },
  {
    path: '',
    title: 'home',
    location: RouteLocations.Global,
    composant: Home,
  },
];

export default ROUTES;
