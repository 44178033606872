const assets = {
  urls: {
    logos: {
      desktop: '/assets/logos/logo-bte.png',
      mobile: '/assets/logos/bouygues-telecom-mobile.svg',
    },
  },
};

const emptyGuid = '00000000-0000-0000-0000-000000000000';

const imageDimensionForCreationForm: React.CSSProperties = {
  display: 'block',
  margin: 'auto',
  objectFit: 'contain',
  maxWidth: '500px',
  minWidth: '350px',
  maxHeight: '500px',
  minHeight: '350px',
};

enum KeyCodeEvent {
  OnEnterPressed = 13,
}

const unitCO2eq = ['mgCO', 'gCO', 'kgCO', 'TCO'];

const unitData = ['Ko', 'Mo', 'Go', 'To', 'Po'];

enum DecimalTransform {
  Default = 1,
  Export = 2,
}

const GLOBAL_CONSO_ID = 'Tous les SIRENS';

export {
  DecimalTransform,
  GLOBAL_CONSO_ID,
  KeyCodeEvent,
  assets,
  emptyGuid,
  imageDimensionForCreationForm,
  unitCO2eq,
  unitData,
};
