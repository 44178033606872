const Data = {
  email: 'admin_rse_bytel_client',
  expireAt: '7200000',
  tokens: {
    access_token: 'YTRlMWVmYWNiMWMwNDVlODNlMTFiZDMzZDJlYmFlZDY6aWhtX3JzZQ',
    expires_in: 7200,
    token_type: 'Bearer',
    scope: 'profile openid offline_access',
    refresh_token:
      'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJzdWIiOiIyMDAwMjQ0NDA1IiwiYXVkIjoiaWhtX3JzZSIsImlhdCI6MTY2OTcxODIwNywiZXhwIjoxNjg1NDg2MjA3LCJqdGkiOiIxNzgzNTYwNjAiLCJydWlkIjoyMDAwMjQ0NDA1fQ.Nmkj3ZBoZ6o1XJYW3PESkgO2fH6aNuxQHK0BrvL86lc',
    id_token:
      'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJpc3MiOiJDYXNFbnQiLCJ2X2lzcyI6MjUzNjA0ODcsInN1YiI6IjIwMDAyNDQ0MDUiLCJhdWQiOiJpaG1fcnNlIiwiaWF0IjoxNjY5NzE4MjA3LCJleHAiOjE2Njk3MjU0MDcsImp0aSI6IjM1MjEzMDk3MSIsImF1dGhfdGltZSI6MTY2OTcxODIwNn0.3tyNV3a7jk35y1mHA9D-V-N_Pob_rcNTzLrU_bTxvGA',
    success: true,
  },
  userInfos: {
    designation: 'Mathieu CHEVALIER',
    utilisateurReel: {
      uid: 2000244405,
      civilite: 'M.',
      prenom: 'Mathieu',
      nom: 'CHEVALIER',
      email: 'macheval@bouyguestelecom.fr',
    },
    utilisateurEffectif: {
      uid: 2000244405,
      civilite: 'M.',
      prenom: 'Mathieu',
      nom: 'CHEVALIER',
      email: 'macheval@bouyguestelecom.fr',
      roles: ['ROLE_RSE_GESTIONNAIRE'],
      sirens: ['397480930', '397480931', '397480932', '397480933', '397480934'],
      infosLigne: null,
    },
    iss: 'CasEnt',
    auth_time: 1669718206,
    jwtLifetime: null,
    app: 'ihm_rse',
    appFrom: 'ihm_rse',
    appTo: 'ihm_rse',
    sessionId: 18167343,
    uid: '2000244405',
    euid: 2000244405,
    ruid: 2000244405,
    uidSubstituant: null,
    uidSubstitue: null,
    isValid: true,
    codeErreur: null,
    famille: 'FAMILLE_CLIENT_PROSPECT',
    civilite: 'M.',
    nom: 'CHEVALIER',
    prenom: 'Mathieu',
    email: 'macheval@bouyguestelecom.fr',
    active: true,
    sub: '2000244405',
    success: true,
  },
};

export default Data;
