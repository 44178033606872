import { XAction, XLink } from '@generated/YSER-v1';

export default class UrlToken {
  private href: string;

  private token: string;

  private search: URLSearchParams;

  constructor(href: string, token: string) {
    this.href = href;
    this.token = token;
    this.search = new URLSearchParams(href);
  }

  getParamValue(propertyName: string): string | null {
    return this.search.get(propertyName);
  }

  toUrlString(): string {
    return this.href;
  }

  toString(): string {
    return this.token;
  }

  static fromXLink(link?: XLink) {
    const href = link?.href || '';
    return new UrlToken(href, encodeURIComponent(btoa(href)));
  }

  static fromXAction(link?: XAction) {
    const href = link?.action || '';
    return new UrlToken(href, encodeURIComponent(btoa(href)));
  }

  static fromToken(token?: string): UrlToken {
    const tokenValue = token || '';
    try {
      const urlToken = new UrlToken(atob(decodeURIComponent(tokenValue)), tokenValue);
      return urlToken;
    } catch (error) {
      console.error('failed to decode :', error);
      return new UrlToken('', tokenValue);
    }
  }
}
