import { FormMode } from '@shared/enum';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useFormMode = (): FormMode => {
  const { pathname } = useLocation();
  return useMemo<FormMode>(() => {
    if (pathname.includes(FormMode.CREATE)) return FormMode.CREATE;

    return FormMode.EDIT;
  }, [pathname]);
};

export default useFormMode;
