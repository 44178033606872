import {
  Columns,
  ColumnsItem,
  Headline,
  HeadlineLevel,
  Icon,
  IconSize,
  Link,
  Navbar,
  NavbarEnd,
  NavbarItem,
  NavbarLink,
  NavbarMenu,
  NavbarStart,
  RowItem,
  Rows,
  Select,
  SelectOption,
  Text,
  TextLevels,
  View,
} from '@bytel/trilogy-react-ts';
import Popup from '@shared/components/popup';
import { assets, GLOBAL_CONSO_ID } from '@shared/constants';

import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import useData from './hook';

/**
 * Composant d'en-tête de l'application, servant aussi à la navigation
 */
const AppHeader: React.FC = () => {
  const { config, username, showPopup, setShowPopup, t, sirens, updateSirenCallback, siren, routeRefLogoBTE } =
    useData();
  return (
    <header data-cy="container-app-header" className="app-header">
      <Navbar className="navbar-text-size-fix app-header-nav section has-background-primary">
        <NavbarMenu>
          <div className="is-flex is-spaced-between is-aligned-center is-fullwidth">
            <NavbarItem className="is-paddingless">
              <div className="topbar-right is-flex is-aligned-center">
                <Link href={t('menu.urlClient')} data-cy="topbar-urlClient" className="has-text-secondary">
                  <Text className="is-static is-marginless">{t('menu.client')}</Text>
                </Link>
              </div>
            </NavbarItem>
            <NavbarItem className="is-paddingless">
              <div className="topbar-left is-flex is-positionned-right is-aligned-center has-text-white">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  removeLinkClass
                  className="has-text-white"
                  onClick={() => setShowPopup(!showPopup)}
                  data-cy="topbar-open-drawer"
                >
                  <div className="topbar-item">
                    <div className="is-flex is-aligned-center">
                      <Icon name="tri-user" size={IconSize.SMALL} />
                      <Text>
                        {t('menu.hello')} <strong> {username} </strong>
                      </Text>
                    </div>
                  </div>
                </Link>
              </div>
            </NavbarItem>
          </div>
        </NavbarMenu>
      </Navbar>
      <Navbar className="navbar-text-size-fix app-header-nav section has-background-white">
        <NavbarMenu>
          <NavbarItem className="is-paddingless">
            <NavbarLink to={routeRefLogoBTE} routerLink={routerLink} data-cy="header-link-home-page">
              <img
                data-cy="header-logo"
                src={assets.urls.logos.desktop}
                alt="Bouygues Telecom"
                className="navbar-logo"
              />
            </NavbarLink>
          </NavbarItem>
          <View style={{ paddingLeft: 'calc(40% - 200px)' }}>
            <NavbarStart>
              <NavbarItem>
                <Headline level={HeadlineLevel.LEVEL2} data-cy="header-title">
                  {t('menu.title')}
                </Headline>
              </NavbarItem>
            </NavbarStart>
          </View>
          <NavbarEnd>
            <Columns className="is-aligned-center">
              <ColumnsItem>
                <View className="is-fullwidth is-fullheight">
                  {/* Enlever le padding de la navbar pour coller à droite mais pas au centre */}
                  <NavbarItem className="is-paddingless">
                    <Select
                      data-cy="selectSirens"
                      onChange={(e) => updateSirenCallback(e.selectValue || '')}
                      label={t('popup.siren')}
                      value={siren?.id}
                      dynamicPlaceholder
                      className="has-text-primary"
                    >
                      {sirens.map(({ id, raisonSociale }) => (
                        <SelectOption data-cy={`optionSiren-${id}`} key={id} value={id}>
                          {`${raisonSociale} ${id === GLOBAL_CONSO_ID ? id : `(${id})`}`}
                        </SelectOption>
                      ))}
                    </Select>
                  </NavbarItem>
                </View>
              </ColumnsItem>
            </Columns>
          </NavbarEnd>
        </NavbarMenu>
      </Navbar>
      {showPopup && (
        <Popup config={config}>
          <Rows>
            <RowItem className="has-text-center">
              <Text level={TextLevels.TWO}>{t('popup.welcome')}</Text>
            </RowItem>
          </Rows>
        </Popup>
      )}
    </header>
  );
};

export default AppHeader;
