import { TriggeredEvent } from '../type';

const toInputDateFormat = (date?: Date) => {
  if (!date) return '';

  let month = (date.getMonth() + 1).toString();
  if (month.length <= 1) {
    month = `0${month}`;
  }

  let day = date.getDate().toString();
  if (day.length <= 1) {
    day = `0${day}`;
  }

  return `${date.getFullYear()}-${month}-${day}`;
};

export const matchEvents = (events: TriggeredEvent | Array<TriggeredEvent>, match: TriggeredEvent): boolean => {
  if (Array.isArray(events)) {
    return events.includes(match);
  }
  return events === match;
};

// La propriété "search" du composant Input permet de définir sur un padding doit être réservé pour le placeholder ... (ノ ゜Д゜)ノ ︵ ┻━┻
export const isSearchInput = (placeholder?: string): boolean =>
  placeholder !== undefined ? placeholder.length > 0 : true;

export const isEscape = (key: string | number): boolean => key === 27 || key === 'Escape';
export const isTab = (key: string | number): boolean => key === 9 || key === 'Tab';
export const isEnter = (key: string | number): boolean => key === 13 || key === 'Enter';

export default toInputDateFormat;
