import { Button, Link, Text, TextLevels } from '@bytel/trilogy-react-ts';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import { BtnNavigationProps } from './type';

const ButtonNavigation: React.FC<BtnNavigationProps> = ({ routeRef, titre, dataCy }) => {
  return routeRef?.href !== undefined ? (
    <Link to={routeRef?.href} routerLink={routerLink} data-cy={`link-${dataCy}`} removeLinkClass>
      <Button variant="PRIMARY" className="is-fullwidth" data-cy={`button-${dataCy}`}>
        <Text
          className="is-unselectable"
          level={TextLevels.TWO}
          data-cy={`title-${dataCy}`}
          inverted
          style={{ textAlign: 'center' }}
        >
          {titre}
        </Text>
      </Button>
    </Link>
  ) : null;
};

export default ButtonNavigation;
