import {
  Button,
  ButtonMarkup,
  Columns,
  ColumnsItem,
  Container,
  Icon,
  IconPosition,
  IconSize,
  Modal,
  RowItem,
  Rows,
  Section,
  Title,
  TitleLevels,
  TrilogyColor,
  VariantState,
} from '@bytel/trilogy-react-ts';
import React, { useMemo } from 'react';
import { PopupProps } from './type';

const Popup: React.FC<PopupProps> = ({ children, config }) => {
  const {
    title,
    ctaContent,
    btnValidIconName,
    btnValidColor,
    ctaOnClick,
    ctaCancelContent,
    ctaCancelOnClick,
    onClose,
    dataCy,
    t,
    autoClose,
    panelConfig,
    hideCancelButton,
  } = config;

  const popupPanelConfig = panelConfig ?? {};

  // eslint-disable-next-line no-nested-ternary
  const alignElementClass = popupPanelConfig.panel
    ? popupPanelConfig.panelAlignLeft
      ? 'has-text-left'
      : 'has-text-centered'
    : '';

  const popupContent = useMemo<JSX.Element>(
    () => (
      <Container className="is-marginless" fluid>
        <Rows>
          {title && (
            <RowItem className="has-text-centered">
              <Title data-cy="title-app-modal" level={TitleLevels.ONE}>
                {title}
              </Title>
            </RowItem>
          )}
          <RowItem className={alignElementClass}>{children}</RowItem>
          <RowItem>
            <Columns centered>
              {!hideCancelButton && (
                <ColumnsItem size={6} className={alignElementClass}>
                  <Button
                    data-cy="button-app-secondary"
                    markup={ButtonMarkup.BUTTON}
                    variant={VariantState.PRIMARY}
                    small
                    onClick={() => {
                      if (ctaCancelOnClick) ctaCancelOnClick();
                      onClose();
                    }}
                  >
                    {ctaCancelContent ?? t('popupCancel')}
                  </Button>
                </ColumnsItem>
              )}
              <ColumnsItem size={hideCancelButton ? 12 : 6} className={alignElementClass}>
                <Button
                  data-cy="button-app-primary"
                  markup={ButtonMarkup.BUTTON}
                  variant={btnValidColor ?? VariantState.PRIMARY}
                  small
                  onClick={async () => {
                    let isSuccess = true;
                    try {
                      await ctaOnClick();
                    } catch (error) {
                      isSuccess = false;
                    }
                    // AutoClose non défini ou oui -> on ferme
                    // autoClose à faux, on doit réussir l'appel pour fermer
                    if ((isSuccess && autoClose === false) || autoClose === undefined || autoClose === true) onClose();
                  }}
                >
                  {btnValidIconName && (
                    <Icon
                      name={btnValidIconName}
                      size={IconSize.SMALL}
                      content={ctaContent ?? t('popupConfirm')}
                      position={IconPosition.LEFT}
                    />
                  )}
                  {!btnValidIconName && (ctaContent ?? t('popupConfirm'))}
                </Button>
              </ColumnsItem>
            </Columns>
          </RowItem>
        </Rows>
      </Container>
    ),
    [
      alignElementClass,
      autoClose,
      btnValidIconName,
      btnValidColor,
      children,
      ctaCancelContent,
      ctaCancelOnClick,
      ctaContent,
      ctaOnClick,
      hideCancelButton,
      onClose,
      t,
      title,
    ],
  );

  return (
    <Modal data-cy={`modal-app-content-${dataCy}`} active closeIcon onClose={onClose} panel={popupPanelConfig.panel}>
      {panelConfig?.panel && <Section background={TrilogyColor.WHITE}>{popupContent}</Section>}
      {!panelConfig?.panel && popupContent}
    </Modal>
  );
};

export default Popup;
