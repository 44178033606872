import { IconName } from '@bytel/trilogy-react-ts';
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
  FormikErrors,
  FormikHelpers,
  FormikState,
  FormikTouched,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { AutoCompleteOption, OnSearchDelegateType } from '../autocomplete/AutoCompleteProps';

/**
 * Type de champ pris en cha rge
 */
export type InputAllowedType =
  | 'text'
  | 'preCompleteText'
  | 'number'
  | 'select'
  | 'radio'
  | 'checkBox'
  | 'date'
  | 'switch'
  | 'dropDown'
  | 'autocomplete'
  | 'datetime-local'
  | 'image'
  | 'textarea';

/**
 * Options disponible
 */
export type CheckBoxOptionType<T> = {
  value: string;
  label: string;
  disabled?: boolean | ((obj: InitialValuesType<T>) => boolean);
};

export enum TriggeredEvent {
  OnMouseLeave = 0,
  OnChange = 1,
  OnBlur = 2,
  OnClick = 3,
  OnFocus = 4,
  OnKeyPress = 5,
  OnKeyUp = 6,
  OnMouseEnter = 7,
}

/**
 * Configuration par défaut
 */
export type BaseConfig<T> = {
  /**
   * Le champ est nécessaire (Si c'est le cas, il faut définir un yup required dans `validationSchema`)
   */
  required: boolean;
  /**
   * Le champ doit être désactivée (boolean et règle de validation)
   */
  disabled?: boolean | ((obj: InitialValuesType<T>) => boolean);
  /**
   * Lorsque la valeur du champ change déclenche l'event
   * formikContext: La configuration formik
   * obj: L'objet demandé T
   * value: la valeur du champ
   */
  onChangeEvent?: (
    formikContext: FormikContextType<InitialValuesType<T>>,
    obj: InitialValuesType<T>,
    value: string,
  ) => void;
  /**
   * Permet de changer l'event qui trigger la fonction 'onChangeEvent' sur certains inputs
   * Il est possible de passer un Array d'évènements
   */
  triggeredEvent?: TriggeredEvent | Array<TriggeredEvent>;
};

/**
 * Configuration des champs input
 */
export type InputConfig<T> = BaseConfig<T> & {
  customIcon?: IconName;
  onIconClick?: () => void;
};

/**
 * Configuration des champs input pré remplis
 */
export type PreCompleteInputConfig<T> = InputConfig<T> & {
  preCompleteValue?: string;
};

/**
 * Configuration des champs imagePicker pré remplis
 */
export type ImagePickerInputConfig<T> = PreCompleteInputConfig<T> & {
  imagePlaceholder?: string;
  imageStyle: React.CSSProperties;
};

/**
 * Configuration pour Radio, Checkbox et Select
 */
export type ListConfig<T> = BaseConfig<T> & {
  options?: CheckBoxOptionType<T>[];
  /** Détermine si l'affichage du placeholder est dynamique */
  dynamicPlaceholder?: boolean;
  /**
   *  Autorise une sélection `null`
   *  Si non défini, le champ est nullable par défaut
   */
  nullable?: boolean;
};
/**
 * Configuration pour AutoComplete
 */
export type AutoCompleteConfig<T> = BaseConfig<T> & {
  name?: string;
  defaultValue?: T;
  value?: T;
  data?: T[];
  /** Événement déclenché lors de la saisie d'une utilisateur, pour effectuer à nouveau une recherche */
  onSearchAsync: OnSearchDelegateType<T>;
  /** Délai à attendre avant l'appel au callback de recherche */
  searchDelay?: number;
  /** Indique si l'AutoComplete est autorisé à supprimer la valeur précédemment sélectionnée, en cas de sortie d'un champ avec une valeur différente de celle précédemment sélectionnée */
  allowEmptyValueOnBlur?: boolean;
  /** Fonction de conversion d'un object T en OptionBase */
  mapToOption: (value: T) => AutoCompleteOption;
};

/**
 * Configuration pour Dropdown
 */
export type DropDownConfig<T> = ListConfig<T> & {
  buttonContent?: string;
};

/**
 * Type de configuration de champs disponible
 */
export type FieldConfig<TBase, TComplete> =
  | BaseConfig<TBase>
  | InputConfig<TBase>
  | ListConfig<TBase>
  | DropDownConfig<TBase>
  | AutoCompleteConfig<TComplete>;

/**
 * Configuration pour l'affichage
 */
export type DisplayConfig = {
  withDivider?: boolean;
  dividerTop?: boolean;
  noTitle?: boolean;
  isPopupDisplay?: boolean;
};

/**
 * Configuration d'un Input
 */
export type ConfigurationType<TBase, TComplete = object> = {
  inputName: keyof TBase;
  label: string;
  type: InputAllowedType;
  fieldConfig: FieldConfig<TBase, TComplete>;
  displayConfig?: DisplayConfig;
  placeholder?: string;
};

/**
 * Définition de l'objet conte nant les valeurs par défaut
 */
export type InitialValuesType<T> = Partial<Record<keyof T, any>>;

export type ObjectShapeValues = ObjectShape extends Record<string, infer V> ? V : never;
export type Shape<T extends Record<any, any>> = Partial<Record<keyof T, ObjectShapeValues>>;

export type FormikConfigType<T> = {
  /**
   * La configuration pour le formulaire
   */
  configuration: ConfigurationType<T, object>[];
  /**
   * Les valeurs initials en fonction dy type
   */
  initialValues?: InitialValuesType<T>;
  /** Si quelqu'un arrive à mieux le typer.. */
  /**
   * Les règles de validations pour les props du types
   */
  validationSchema?: Yup.AnySchema;
  /**
   * Le retour lorsqu'on clique sur Sauvegarder
   */
  onSubmit: (FormikValues: T, formikHelpers?: FormikHelpers<any>) => void | Promise<any>;
};

export type BaseProps = {
  label: string;
  dataCy: string;
  displayConfig?: DisplayConfig;
  inputName: string;
  type?: string;
};

export type LabelProps = BaseProps & {
  required?: boolean;
};

export type ErrorProps<T> = BaseProps & {
  formik: FormikContextType<InitialValuesType<T>>;
  displayErrors: boolean;
};

export type InputBaseProps<T> = ErrorProps<T> & {
  setInputValue?: any;
  /** Texte du placeholder */
  placeholder?: string;
};

export type RenderInputProps<T> = InputBaseProps<T> & {
  type: InputAllowedType;
  fieldConfig: BaseConfig<T>;
};

export type BaseInputTextProps<T> = InputBaseProps<T> & {
  type: 'number' | 'text';
  fieldConfig: InputConfig<T>;
};

export type PreCompleteInputProps<T> = InputBaseProps<T> & {
  fieldConfig: PreCompleteInputConfig<T>;
};

export type ImagePickerInputProps<T> = InputBaseProps<T> & {
  fieldConfig: ImagePickerInputConfig<T>;
};

export type RadioProps<T> = InputBaseProps<T> & {
  fieldConfig: ListConfig<T>;
};

export type SelectProps<T> = InputBaseProps<T> & {
  fieldConfig: ListConfig<T>;
};

export type CheckboxProps<T> = InputBaseProps<T> & {
  fieldConfig: ListConfig<T>;
};

export type DateProps<T> = InputBaseProps<T> & {
  fieldConfig: BaseConfig<T>;
};

export type SwitchProps<T> = InputBaseProps<T> & {
  fieldConfig: BaseConfig<T>;
};

export type DropDownProps<T> = InputBaseProps<T> & {
  fieldConfig: DropDownConfig<T>;
};

export type AutoCompleteProps<T> = InputBaseProps<T> & {
  fieldConfig: AutoCompleteConfig<T>;
};

export type TextAreaProps<T> = InputBaseProps<T> & {
  fieldConfig: BaseConfig<T>;
};

export type UseFormikData<T> = {
  formik: FormikContextType<InitialValuesType<T>>;
  setInputValue: (key: InitialValuesType<T>, value: any) => void;
  cancelCallback: () => void;
};

export type FormikType = {
  initialValues: FormikValues;
  initialErrors: FormikErrors<unknown>;
  initialTouched: FormikTouched<unknown>;
  initialStatus: any;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <TT = string | React.ChangeEvent<any>>(field: TT): TT extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleReset: (e: any) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  resetForm: (nextState?: Partial<FormikState<FormikValues>> | undefined) => void;
  setErrors: (errors: FormikErrors<FormikValues>) => void;
  setFormikState: (
    stateOrCb: FormikState<FormikValues> | ((state: FormikState<FormikValues>) => FormikState<FormikValues>),
  ) => void;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  setTouched: (
    touched: FormikTouched<FormikValues>,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  setValues: (
    FormikValues: React.SetStateAction<FormikValues>,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  submitForm: () => Promise<any>;
  validateForm: (FormikValues?: FormikValues) => Promise<FormikErrors<FormikValues>>;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  isValid: boolean;
  dirty: boolean;
  unregisterField: (name: string) => void;
  registerField: (name: string, { validate }: any) => void;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  getFieldMeta: (name: string) => FieldMetaProps<any>;
  getFieldHelpers: (name: string) => FieldHelperProps<any>;
  validateOnBlur: boolean;
  validateOnChange: boolean;
  validateOnMount: boolean;
  FormikValues: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  isValidating: boolean;
  status?: any;
  submitCount: number;
};
