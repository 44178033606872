import { Columns, ColumnsItem, RowItem, Rows, Text, TextLevels } from '@bytel/trilogy-react-ts';
import Popup from '@shared/components/popup';

import React from 'react';
import Card from './card-item';
import useData from './hooks';
import { EcoAstucesGestionnaireProps } from './type';

const EcoAstucesGestionnaire: React.FC<EcoAstucesGestionnaireProps> = ({ ecoAstucesGestionnaire, skeleton }) => {
  const { showPopup, config, activatePopup, t, currentEcoastuceTitle } = useData();

  return (
    <>
      <Columns multiline>
        {ecoAstucesGestionnaire.map((ecoAstuce) => (
          <ColumnsItem size={6} key={ecoAstuce.id}>
            <Card ecoAstuce={ecoAstuce} skeleton={skeleton} activatePopup={activatePopup} />
          </ColumnsItem>
        ))}
      </Columns>
      {showPopup && (
        <Popup config={config}>
          <Rows>
            <RowItem>
              <Text level={TextLevels.TWO}>{t('description', { name: currentEcoastuceTitle })}</Text>
            </RowItem>
          </Rows>
        </Popup>
      )}
    </>
  );
};

export default EcoAstucesGestionnaire;
