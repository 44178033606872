/* eslint-disable no-bitwise */
import { IconName } from '@bytel/trilogy-react-ts';

export enum UserRoles {
  ADMIN = 'Administrator',
  USER1 = 'Gestionnaire',
}

export type RouteType = {
  path: string;
  title?: string;
  location: RouteLocations;
  icon?: IconName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  composant?: React.FC<any>;
  sousTitre?: string;
  children?: RouteType[];
  operationName?: string;
  roles?: UserRoles[];
};

export enum RouteLocations {
  /**
   * Aucune, cette route n'est pas encore affecté à un emplacement
   */
  None = 0,

  Global = 1 << 0, // 00000001
  /**
   * Route potentiellement dangereuse
   */
  IsWarningField = 1 << 1, // 0000010
  /**
   * Route sans mutation de données (lecture seule)
   */
  IsGreenField = 1 << 2, // 00000100
  /**
   * Route accessible depuis le menu principale
   */
  IsMainMenu = 1 << 3, // 00001000
  /**
   * Route accessible depuis le sous-menu
   */
  IsSubMenu = 1 << 4, // 00010000
  // .......... =    1 << 7,      // 10000000
}

export type RouteParamsType = {
  trackerId?: string;
  accessToken?: string;
  login?: string;
};

const values = Object.keys(RouteLocations).map(Number).filter(Boolean).reverse();
export const getFlags: (value: RouteLocations) => RouteLocations[] = (value) => {
  let curr = value;
  const flags: RouteLocations[] = [];

  values.forEach((v) => {
    if (v <= curr) {
      flags.push(v as RouteLocations);
      curr -= v;
    }
  });

  return flags;
};

export const is: (currentValue: RouteLocations, toMatch: RouteLocations) => boolean = (
  currentValue: RouteLocations,
  toMatch: RouteLocations,
) => {
  const currentFlags = getFlags(currentValue);
  return getFlags(toMatch)
    .map((match) => currentFlags.includes(match))
    .reduce((l, r) => l && r);
};

export enum RouteParameters {
  sirenId = ':sirenId',
  ecoAstuceId = ':ecoAstuceId',
  erreurId = ':erreurId',
  actualiteId = ':actualiteId',
}
