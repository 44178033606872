import { VariantState } from '@bytel/trilogy-react-ts';
import { Actualite } from '@services/interfaces/generated/YSER-v1';
import { PopupConfig } from '@shared/components/popup/type';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useData = () => {
  const { t } = useTranslation('domain/actualites', {
    keyPrefix: 'popup',
  });

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [currentActualite, setCurrentActualite] = useState<Actualite>();
  const [deleteActualite, setDeleteActualite] = useState<() => () => Promise<void>>();

  const activatePopup = useCallback((confirmDelete: () => Promise<void>, actualite: Actualite) => {
    setDeleteActualite(() => confirmDelete);
    setCurrentActualite(actualite);
    setShowPopup(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowPopup(false);
    setCurrentActualite(undefined);
    setDeleteActualite(undefined);
  }, []);

  const handleClick = useCallback(() => {
    if (deleteActualite) deleteActualite()();
    handleClose();
  }, [deleteActualite, handleClose]);

  const config = useMemo<PopupConfig>(
    () => ({
      title: t('title'),
      ctaContent: t('confirm'),
      ctaCancelContent: t('cancel'),
      btnValidColor: VariantState.TERTIARY,
      ctaOnClick: handleClick,
      onClose: handleClose,
      ctaCancelOnClick: handleClose,
      t,
      dataCy: `actualites-${currentActualite?.id}-popup`,
    }),
    [handleClick, currentActualite?.id, handleClose, t],
  );

  return {
    showPopup,
    activatePopup,
    config,
    t,
    currentActualiteTitle: currentActualite?.title,
  };
};

export default useData;
