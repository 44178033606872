import { PaginationFilters } from '@services/api/types';
import { PaginationType } from '@yama/shared-components/dist/components/data-table/type';
import { TableState } from 'react-table';

const defaultSize = 5;
const defaultStart = 0;

export const defaultPagination: PaginationFilters = {
  offset: defaultStart,
  limit: defaultSize,
};

export const defaultDataTablePaginationState: Partial<TableState> = {
  pageIndex: defaultStart,
  pageSize: defaultSize,
};

export const setPagination = <D extends PaginationFilters>(
  askedPagination: PaginationType,
  filters: D | undefined,
  setter: (filters: D) => void,
  isFetched: boolean,
  resultsCount: number,
): void => {
  const { page, size } = askedPagination;

  const targetOffset = page * size;
  const offset = resultsCount > targetOffset ? targetOffset : resultsCount;

  /**
   * On est en cours de recherche ? Pas de modification de la pagination
   * On a pas de filtres ? On utilise la pagination par défaut
   * La page cible n'est pas possible au vu du nombre de data, on reste sur la page que nous avions
   */
  if (isFetched && filters && targetOffset === offset && filters.offset !== targetOffset) {
    setter({
      ...filters,
      offset,
      limit: size,
    });
  }
};
