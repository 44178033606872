import {
  Columns,
  ColumnsItem,
  IconName,
  Input,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import { ECOASTUCE_NEW_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import HeadbandCreation from '@shared/components/headband-creation';
import NavigationItem from '@shared/components/navigation-item';
import { KeyCodeEvent } from '@shared/constants';
import useUrlToken from '@shared/hooks/use-url-token';
import React from 'react';
import EcoAstucesBTE from './eco-astuces-bte';
import EcoAstucesGestionnaire from './eco-astuces-gestionnaire';
import useData from './hook';

const ListerEcoAstuces: React.FC = () => {
  const {
    urlCreate,
    isFetching,
    isLoading,
    ecoAstucesGestionnaire,
    ecoAstucesBTE,
    handleSearchClick,
    navigationItemConfig,
    t,
  } = useData();

  const sirenToken = useUrlToken(RouteParameters.sirenId);
  return (
    <Section data-cy="lister-ecoAstuces-container" skeleton={isFetching || isLoading} className="is-paddingless">
      <NavigationItem config={navigationItemConfig} />
      <HeadbandCreation
        PATH={ECOASTUCE_NEW_PATH.replace(RouteParameters.sirenId, sirenToken.toString())}
        dataCy="ecoastuces"
        id={RouteParameters.ecoAstuceId}
        t={t}
        urlCreate={urlCreate}
      />
      <Section background={TrilogyColor.WHITE}>
        <Rows>
          <RowItem>
            <Text level={TextLevels.ONE} data-cy="ecoastuces-search-description">
              {t('search.description')}
            </Text>
            <Input
              name="ecoastuces-searchbar"
              placeholder={t('search.place-holder')}
              data-cy="ecoastuces-searchbar"
              search
              customIcon={IconName.SEARCH}
              onIconClick={handleSearchClick}
              onKeyUp={(e) => e.inputKeyCode === KeyCodeEvent.OnEnterPressed && handleSearchClick(e)}
            />
          </RowItem>
          <RowItem className="is-offset-1">
            <Columns>
              <ColumnsItem size={8}>
                {ecoAstucesGestionnaire.length === 0 ? (
                  <Section size="LARGE">
                    <RowItem>{/* space */}</RowItem>
                    <RowItem>
                      <Columns>
                        <ColumnsItem size={3}>{/* space */}</ColumnsItem>
                        <ColumnsItem size={6}>
                          <Text level={TextLevels.TWO} className="has-text-centered">
                            {t('empty.message')}
                          </Text>
                          <RowItem>{/* space */}</RowItem>
                          <Text level={TextLevels.TWO} className="has-text-centered">
                            {t('empty.info')}
                          </Text>
                        </ColumnsItem>
                        <ColumnsItem size={3}>{/* space */}</ColumnsItem>
                      </Columns>
                    </RowItem>
                  </Section>
                ) : (
                  ecoAstucesGestionnaire &&
                  ecoAstucesGestionnaire.length > 0 && (
                    <EcoAstucesGestionnaire
                      ecoAstucesGestionnaire={ecoAstucesGestionnaire}
                      skeleton={isLoading || isFetching}
                    />
                  )
                )}
              </ColumnsItem>
              <ColumnsItem size={4}>
                {ecoAstucesBTE && ecoAstucesBTE.length > 0 && (
                  <EcoAstucesBTE ecoAstucesBTE={ecoAstucesBTE} skeleton={isLoading || isFetching} />
                )}
              </ColumnsItem>
            </Columns>
          </RowItem>
        </Rows>
      </Section>
    </Section>
  );
};

export default ListerEcoAstuces;
