import { ConsommationFlotte } from '@services/interfaces/generated/YSER-v1';
import { DecimalTransform } from '@shared/constants';
import { useObtenirConsommationFlotte } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import { TransformBaseType, transformUnit } from '@shared/misc/helpers/data';
import { capitalizeFirstLetter } from '@shared/misc/helpers/strings';
import moment from 'moment';
import 'moment/locale/fr';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphExporterColumns, UseGraphExporterProps } from './type';

const useDataGraph = (): UseGraphExporterProps => {
  const siren = useSiren();
  const { data } = useObtenirConsommationFlotte(siren?._links?.obtenirConsommationFlotte?.href);
  const { t } = useTranslation('domain/home', {
    keyPrefix: 'consommationFlotte',
  });

  // Créer un useMemo qui retourne un tableau de string pour avoir les valeurs des unités
  const valuesExportUnit = useMemo<TransformBaseType[]>(() => {
    /** Partie de récupération des unités de CO2 */
    // Création d'un tableau de nombre pour passer dans le max
    const tabNumbersCo2 = data?.items.map((item) => item.co2eq) || [];

    // On prend la plus grande valeur pour avoir l'unité la plus haute correspondante
    const valeurCo2 = Math.max(...tabNumbersCo2);

    const tempUnitC02 = transformUnit(valeurCo2, 'co2eq');

    /** Partie de récupération des unités de data */
    const tabNumbersData = data?.items.map((item) => item.data) || [];

    // On prend la plus grande valeur pour avoir l'unité la plus haute correspondante
    const valeurData = Math.max(...tabNumbersData);

    const tempUnitData = transformUnit(valeurData, 'data');

    return [tempUnitC02, tempUnitData];
  }, [data?.items]);

  const exportUnitCO2 = useMemo<TransformBaseType>(() => {
    return valuesExportUnit[0];
  }, [valuesExportUnit]);
  const exportUnitData = useMemo<TransformBaseType>(() => {
    return valuesExportUnit[1];
  }, [valuesExportUnit]);

  const graphExporterProps = useMemo<GraphExporterColumns[]>(() => {
    // Ligne qui permet d'éviter les données undefined pour le CSVLink
    const dataFlotte: ConsommationFlotte[] = data?.items || [];

    return dataFlotte.map((item) => {
      const dateToFormat = new Date(item.date);

      const fullDate = moment(dateToFormat).format('MMMM\xa0YYYY');

      // Récupération de la valeur décimal de la transformation de données
      const tempUnitCO2 = transformUnit(item.co2eq, 'co2eq', exportUnitCO2.index, DecimalTransform.Export);
      const tempUnitData = transformUnit(item.data, 'data', exportUnitData.index, DecimalTransform.Export);

      return {
        consoDataGraphDate: capitalizeFirstLetter(fullDate),
        consoDataGraphValue: tempUnitCO2.value,
        valueData: tempUnitData.value,
      };
    });
  }, [data?.items, exportUnitCO2, exportUnitData]);

  const valueLabelCO2 = useMemo<string>(() => {
    return t('export.labelCO2', { exportUnitCO2: exportUnitCO2.unit });
  }, [t, exportUnitCO2]);

  const valueLabelData = useMemo<string>(() => {
    return t('export.labelData', { exportUnitData: exportUnitData.unit });
  }, [t, exportUnitData]);

  return {
    graphExporterProps,
    valueLabelCO2,
    valueLabelData,
  };
};

export default useDataGraph;
