import { Columns, ColumnsItem, Select, SelectOption } from '@bytel/trilogy-react-ts';
import { useCallback, useEffect } from 'react';
import { executeFormikValidation } from '../helpers';
import { SelectProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const SelectComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label: labelTitle,
  placeholder,
  fieldConfig,
  displayConfig,
  displayErrors,
}: SelectProps<T>) => {
  const { options, onChangeEvent, disabled: selectDisabled, dynamicPlaceholder, nullable, required } = fieldConfig;
  const { values, initialValues } = formik;

  const setValue = useCallback(
    (newValue: string) => {
      if (values[inputName] !== newValue) {
        setInputValue(inputName, newValue);
        if (onChangeEvent) onChangeEvent(formik, values, newValue);
      }
    },
    [inputName, formik, values, onChangeEvent, setInputValue],
  );

  useEffect(() => {
    /* Si le champ est NON nullable */
    if (!nullable) {
      const newValue = options?.[0]?.value ?? '';

      // On contrôle que la valeur de formik est vide :
      if (!values[inputName]) {
        // - Si c'est le cas, on lui passe la valeur par défaut
        setValue(newValue);
      } else {
        // - Si ce n'est pas le cas, on s'assure que la valeur courante est présente dans les options
        const currValue = values[inputName];
        if (!options?.find((o) => o.value === currValue)) {
          // - - Si ce n'est pas le cas, on affecte la valeur par défaut
          setValue(newValue);
        }
      }
    } /* Si le champ EST nullable */ else if (values[inputName]) {
      // On s'assure que la valeur courante est présente dans les options :
      const currValue = values[inputName];
      if (!options?.find((o) => o.value === currValue)) {
        // - Si ce n'est pas le cas, on affecte une valeur vide
        setValue('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, setValue]);

  return (
    <Columns multiline verticalCentered={!!displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={labelTitle}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <Select
          data-cy={`selectType-${dataCy}-${inputName}`}
          name={inputName}
          label={labelTitle}
          placeholder={placeholder}
          onChange={({ selectValue }) =>
            onChangeEvent
              ? [setInputValue(inputName, selectValue), onChangeEvent(formik, values, selectValue ?? '')]
              : setInputValue(inputName, selectValue)
          }
          dynamicPlaceholder={dynamicPlaceholder}
          nullable={nullable}
          disabled={selectDisabled ? executeFormikValidation(selectDisabled, values) : false}
          value={values[inputName]}
          defaultValue={initialValues[inputName]}
        >
          {options?.map(({ value, label, disabled }) => (
            <SelectOption
              key={value}
              label={label}
              value={value}
              disabled={disabled ? executeFormikValidation(disabled, values) : false}
            />
          ))}
        </Select>
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={labelTitle}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default SelectComponent;
