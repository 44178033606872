import { InputClickEvent } from '@bytel/trilogy-react-ts/lib/components/input/InputProps';
import { WELCOME_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { ActualitesSearchFilters } from '@services/api/types';
import { useListerActualites } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import useUrlToken from '@shared/hooks/use-url-token';
import { defaultPagination } from '@shared/misc/helpers/pagination';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseListerActualitesProps } from './type';

const useData = (): UseListerActualitesProps => {
  const { t } = useTranslation('domain/actualites', {
    keyPrefix: 'list',
  });

  const siren = useSiren();

  const inputRef = useRef<HTMLInputElement | undefined>();
  const [searchFilters, setSearchFilters] = useState<ActualitesSearchFilters>({
    ...defaultPagination,
    ...{ limit: 50 },
  });
  const handleSearchClick = useCallback(({ inputValue }: InputClickEvent) => {
    if (inputValue === '') return;
    setSearchFilters({
      ...defaultPagination,
      ...{ limit: 50 },
      ...{ name: inputValue },
    });
  }, []);

  const {
    data: actualites,
    isLoading,
    isFetching,
  } = useListerActualites(siren?._links?.listerActualites?.href, searchFilters);

  const sirenToken = useUrlToken(RouteParameters.sirenId);

  const navigationItemConfig = useMemo(
    () => ({
      label: t('breadcrumb.title'),
      link: { href: WELCOME_PATH.replace(RouteParameters.sirenId, sirenToken.toString()) },
    }),
    [sirenToken, t],
  );

  return {
    urlCreate: siren?._actions.ajouterActualite,
    isLoading,
    isFetching,
    actualites: actualites?.items ?? [],
    searchFilters,
    setSearchFilters,
    inputRef,
    handleSearchClick,
    navigationItemConfig,
    t,
  };
};

export default useData;
