export enum SplashScreenStatus {
  LOADING = 0,
  ERROR = 1,
  SUCCESS = 2,
}

export type SplashScreenProps = {
  status?: SplashScreenStatus;
  text?: string;
};
