import { PaginationFilters, QueryParams } from './types';

export const setPaginationToSearchParams: (URLSearchParams, PaginationFilters) => void = (
  searchParams: URLSearchParams,
  options: PaginationFilters,
) => {
  if (options?.offset) {
    searchParams.set('offset', options.offset?.toString());
  } else {
    searchParams.set('offset', '0');
  }
  if (options?.limit) {
    searchParams.set('limit', options.limit?.toString());
  } else {
    searchParams.set('limit', '25');
  }
};

const queryToDontTake = ['offset', 'limit', 'isQueryLocked'];
/**
 * Permet d'ajouter les query params à une url
 * @param givenUrl url de base à modifier
 * @param options les options
 * @returns l'url modifié
 */
export const setQueryParams = <T extends PaginationFilters>(givenUrl: URL, options?: T): URL => {
  if (options) {
    const optionsKeys = Object.getOwnPropertyNames(options);
    optionsKeys
      .filter((key) => !queryToDontTake.includes(key))
      .forEach((key) => {
        const value = options[key];
        if (value) givenUrl.searchParams.set(key, value);
      });
  }

  setPaginationToSearchParams(givenUrl.searchParams, options);

  return givenUrl;
};

export const buildURIWithParams = (link: string, params?: QueryParams): string => {
  let queryParams = '';
  if (params)
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (link.includes('?') || queryParams.includes('?')) queryParams += `&${key}=${value}`;
      else queryParams += `?${key}=${value}`;
    });
  return `${link}${queryParams}`;
};

export default { setPaginationToSearchParams, setQueryParams };
