import { ECOASTUCES_LIST_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { EcoAstuceIn } from '@services/interfaces/generated/YSER-v1';
import { FormikConfigType, Shape } from '@shared/components/ui-components/form/type';
import {
  useCreateEcoAstuce,
  useEnvoyerImageEcoAstuces,
  useModifierEcoAstuce,
  useObtenirEcoAstuces,
} from '@shared/contexts/api';
import { useUrlTokens } from '@shared/hooks/use-url-token';

import { FormMode } from '@shared/enum';
import useFormMode from '@shared/hooks/use-form-mode';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { imageDimensionForCreationForm } from '@shared/constants';
import { useSiren } from '@shared/contexts/siren-context';
import { isValidHttpUrl } from '@shared/misc/helpers/strings';
import * as Yup from 'yup';
import { UseEditerEcoAstucesProps } from './type';

const useData = (): UseEditerEcoAstucesProps<EcoAstuceIn> => {
  const { t } = useTranslation('domain/eco-astuces', {
    keyPrefix: 'create',
  });

  const history = useHistory();
  const formMode = useFormMode();
  const isEditMode: boolean = formMode === FormMode.EDIT;

  const [ecoAstuceToken, sirenToken] = useUrlTokens([RouteParameters.ecoAstuceId, RouteParameters.sirenId]);

  const { data: ecoAstuce, isLoading, isFetching } = useObtenirEcoAstuces(ecoAstuceToken.toUrlString());
  const siren = useSiren();

  const uploadPicture = useEnvoyerImageEcoAstuces(siren?._actions?.envoyerImageEcoAstuce);
  const creerEcoAstuce = useCreateEcoAstuce(siren?._actions?.ajouterEcoAstuce);
  const modifierEcoAstuce = useModifierEcoAstuce(ecoAstuce?._actions?.modifierEcoAstuce);

  const backLink = useMemo<string>(
    () => ECOASTUCES_LIST_PATH.replace(RouteParameters.sirenId, sirenToken.toString()),
    [sirenToken],
  );

  const navigationItemConfig = React.useMemo<NavigationItemConfig>(() => {
    let label = isEditMode ? t('breadcrumb.editEcoAstuce') : t('breadcrumb.createEcoAstuce');
    if (ecoAstuce?.isBTE) {
      label = t('breadcrumb.viewEcoAstuceBTE');
    } else if (ecoAstuce && !ecoAstuce._actions.modifierEcoAstuce) {
      label = t('breadcrumb.viewEcoAstuce');
    }
    return { label, link: { href: backLink } };
  }, [backLink, ecoAstuce, isEditMode, t]);

  const cbMutateEcoAstuce = useCallback(
    async (ecoAstuceToPush: EcoAstuceIn) => {
      const ecoAstuceMutate = isEditMode ? modifierEcoAstuce : creerEcoAstuce;
      try {
        await ecoAstuceMutate.mutateAsync(ecoAstuceToPush);
        history.push(backLink);
      } catch (error) {
        console.log(error);
      }
    },
    [isEditMode, modifierEcoAstuce, creerEcoAstuce, history, backLink],
  );

  const onSubmit = useCallback(
    async (ecoAstuceToPush: EcoAstuceIn) => {
      const newEcoAstuce = { ...ecoAstuceToPush };

      if (!isValidHttpUrl(newEcoAstuce.image)) {
        const file = newEcoAstuce.image as unknown as File;
        if (file) {
          const request = {
            file,
          };
          try {
            const uploadedImageUri = await uploadPicture.mutateAsync(request);
            newEcoAstuce.image = uploadedImageUri?.url;
          } catch (error) {
            console.error('failed to upload image');
            newEcoAstuce.image = '';
          }
        } else {
          console.error('No file found');
        }
      }

      cbMutateEcoAstuce(newEcoAstuce);
    },
    [cbMutateEcoAstuce, uploadPicture],
  );

  const config = useMemo<FormikConfigType<EcoAstuceIn>>(
    () => ({
      configuration: [
        {
          inputName: 'image',
          type: 'image',
          label: t('chooseImage'),
          displayErrors: true,
          fieldConfig: {
            required: false,
            imagePlaceholder: '/assets/images/greyBackground.png',
            imageStyle: imageDimensionForCreationForm,
          },
        },
        {
          inputName: 'title',
          type: 'text',
          label: t('title'),
          displayErrors: true,
          fieldConfig: {
            required: true,
          },
        },
        {
          inputName: 'description',
          type: 'textarea',
          label: t('description'),
          displayErrors: true,
          fieldConfig: {
            required: true,
          },
        },
        {
          inputName: 'url',
          type: 'text',
          label: t('url'),
          displayErrors: true,
          fieldConfig: {
            required: false,
          },
        },
      ],
      initialValues: {
        image: ecoAstuce?.image,
        url: ecoAstuce?.url,
        title: ecoAstuce?.title,
        description: ecoAstuce?.description,
      },
      validationSchema: Yup.object<Shape<EcoAstuceIn>>({
        image: Yup.string().required(),
        title: Yup.string()
          .required(t('errors.formik.required'))
          .min(8, t('errors.formik.notEnoughChar', { item: t('errors.formik.title') })),
        description: Yup.string()
          .required(t('errors.formik.required'))
          .min(8, t('errors.formik.notEnoughChar', { item: t('errors.formik.description') })),
      }),
      onSubmit,
    }),
    [ecoAstuce?.image, ecoAstuce?.url, ecoAstuce?.title, ecoAstuce?.description, onSubmit, t],
  );

  return {
    config,
    isFetching,
    isLoading,
    ecoAstuce,
    navigationItemConfig,
    imageDimensionForCreationForm,
    tEcoAstuce: t,
  };
};
export default useData;
