import { Columns, ColumnsItem, RowItem, Rows, Text, TextLevels } from '@bytel/trilogy-react-ts';
import Popup from '@shared/components/popup';
import React from 'react';
import Card from './card';
import useData from './hook';
import { ActualitesListProps } from './type';

const ActualitesList: React.FC<ActualitesListProps> = ({ actualites, skeleton }) => {
  const { showPopup, config, activatePopup, t, currentActualiteTitle } = useData();

  return (
    <>
      <Columns multiline>
        {actualites.map((actualite) => (
          <ColumnsItem size={4} key={actualite.id}>
            <Card actualite={actualite} skeleton={skeleton} activatePopup={activatePopup} />
          </ColumnsItem>
        ))}
      </Columns>
      {showPopup && (
        <Popup config={config}>
          <Rows>
            <RowItem>
              <Text level={TextLevels.TWO}>{t('description', { name: currentActualiteTitle })}</Text>
            </RowItem>
          </Rows>
        </Popup>
      )}
    </>
  );
};

export default ActualitesList;
