import axios, { AxiosInstance } from 'axios'
import { construireHeadersTracabilite } from './builders/messageBuilder'
import { PrismeConfig } from './config'
import { MessagePrisme } from './interfaces/notifierMessagePrisme'

type MessagePartielPrisme = Pick<MessagePrisme, 'ctx' | 'events'>

export class SenderPrisme {
  private axiosInstance: AxiosInstance
  private envoieEnCours: boolean = false
  private queueEnvoies: MessagePartielPrisme[]
  private lockQueue = Promise.resolve()
  private timeoutEnvoi: NodeJS.Timeout | null = null

  private config: PrismeConfig

  constructor(config: PrismeConfig) {
    this.axiosInstance = axios.create()
    this.queueEnvoies = []
    this.config = config
  }

  async forcerEnvoiQueueMessages() {
    if (!this.envoieEnCours) {
      this.envoieEnCours = true
      try {
        await this.envoyerMessages()
      } catch (e) {
        console.error("Erreur lors de l'envoie des logs prisme", e)
      } finally {
        this.envoieEnCours = false
      }
    }
  }

  async logMessagePrisme(messagePrisme: MessagePartielPrisme) {
    await this.lockQueue
    this.queueEnvoies.push(messagePrisme)
    await this.envoyerSiNecessaire()
  }

  enrichirMessage(messagePartiel: MessagePartielPrisme): MessagePrisme {
    return {
      st: this.config.st,
      env: this.config.environnement,
      ...messagePartiel
    }
  }

  async envoyerSiNecessaire() {
    this.resetTimeout()
    if (this.queueEnvoies.length > 0 && this.queueEnvoies.length >= this.config.tailleMaxEnvoiQueueMessages) {
      await this.envoyerMessages()
    }
  }

  async envoyerMessages() {
    await this.lockQueue
    if (this.queueEnvoies.length > 0) {
      this.resetTimeout()
      this.lockQueue = new Promise(async (resolve, _) => {
        const messagesPrisme = await Promise.all(this.queueEnvoies.map(m => this.enrichirMessage(m)))
        try {
          await this.axiosInstance.post(this.config.urlEndpoint, messagesPrisme, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              ...construireHeadersTracabilite(this.config)
            }
          })
        } catch (e) {
          console.error("[prisme-logger] Erreur lors de l'envoie des logs à LOGAPI", e)
        } finally {
          this.queueEnvoies = []
          resolve()
        }
      })
    }
    return this.lockQueue
  }

  resetTimeout() {
    if (this.timeoutEnvoi) {
      clearTimeout(this.timeoutEnvoi)
    }
    this.timeoutEnvoi = setTimeout(async () => await this.envoyerMessages(), this.config.timeoutEnvoiQueueMessagesMs)
  }
}
