import { Columns, ColumnsItem } from '@bytel/trilogy-react-ts';
import React from 'react';
import { executeFormikValidation } from '../helpers';
import { DateProps, TriggeredEvent } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';
import { isEnter, matchEvents } from './util';

const DateComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label,
  placeholder,
  fieldConfig,
  displayConfig,
  displayErrors,
  type,
}: DateProps<T>) => {
  const { onChangeEvent, disabled, required, triggeredEvent = TriggeredEvent.OnMouseLeave } = fieldConfig;
  const { touched, values } = formik;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <input
          style={{ padding: 10 }}
          data-cy={`dateType-${dataCy}-${inputName}-${type}`}
          className="input"
          type={type}
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
          name={inputName}
          value={values[inputName]}
          placeholder={placeholder}
          onChange={(e) => {
            setInputValue(inputName, e.target.value);
            if (triggeredEvent === TriggeredEvent.OnChange && onChangeEvent) {
              onChangeEvent(formik, values as T, e.target.value);
            }
          }}
          onMouseLeave={() => {
            if (triggeredEvent === TriggeredEvent.OnMouseLeave && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
            touched[inputName] = true;
          }}
          disabled={disabled ? executeFormikValidation(disabled, values as T) : false}
          onBlur={() => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnBlur) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
          onClick={() => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnClick) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
          onFocus={() => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnFocus) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
          onKeyPress={({ key }) => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnKeyPress) && isEnter(key) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
          onKeyUp={({ key }) => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnKeyUp) && isEnter(key) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
          onMouseEnter={() => {
            if (matchEvents(triggeredEvent, TriggeredEvent.OnMouseEnter) && onChangeEvent) {
              onChangeEvent(formik, values as T, values[inputName]);
            }
          }}
        />
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default DateComponent;
