import { Columns, ColumnsItem } from '@bytel/trilogy-react-ts';
import React from 'react';
import AutoComplete from '../../autocomplete/AutoComplete';
import { executeFormikValidation } from '../helpers';
import { AutoCompleteProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const AutoCompleteComponent = <T extends object>({
  inputName,
  placeholder,
  dataCy,
  setInputValue,
  formik,
  label: labelTitle,
  fieldConfig,
  displayConfig,
  displayErrors,
}: AutoCompleteProps<T>) => {
  const { disabled, required, defaultValue, data, allowEmptyValueOnBlur, onChangeEvent, onSearchAsync, mapToOption } =
    fieldConfig;
  const { values, touched } = formik;

  return (
    <Columns multiline verticalCentered={!!displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={labelTitle}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <AutoComplete<T>
          dataCy={dataCy}
          placeholder={placeholder}
          name={inputName}
          allowEmptyValueOnBlur={allowEmptyValueOnBlur}
          onSelected={(selectValue) => {
            const optionKey = selectValue !== undefined ? mapToOption(selectValue).key : '';
            setInputValue(inputName, optionKey);
            if (onChangeEvent) {
              onChangeEvent(formik, values, optionKey);
            }
          }}
          onBlur={() => {
            touched[inputName] = true;
          }}
          data={data}
          disabled={disabled ? executeFormikValidation(disabled, values) : false}
          defaultValue={defaultValue}
          onSearchAsync={onSearchAsync}
          mapToOption={mapToOption}
        />
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={labelTitle}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default AutoCompleteComponent;
