import { BQuery } from '@bytel/query';
import { UploadImageIn, XAction } from '@services/interfaces/generated/YSER-v1';
import { QueryParams } from './types';
import { buildURIWithParams } from './utils';

export default class APIServicesBase {
  constructor(protected bquery: BQuery) {}

  getUrl(href: string): URL {
    if (href.startsWith('http')) {
      return new URL(href);
    }

    let baseUri = this.bquery?.config?.baseURL ?? 'https://api.yser.c2s-bouygues.dev/';
    if (baseUri.endsWith('/')) {
      baseUri = baseUri.substring(0, baseUri.length - 1);
    }

    return new URL(`${baseUri}${href}`);
  }

  executeAsync<TOutput = void, TInput = undefined>(
    link: XAction,
    input?: TInput,
    params?: QueryParams,
  ): Promise<TOutput> {
    const url = this.getUrl(buildURIWithParams(link.action, params));
    return this.bquery.request<TOutput>({
      url: url.toString(),
      data: input,
      method: link.method,
    });
  }

  uploadFileAsync<TOutput = void>(link: XAction, input?: UploadImageIn): Promise<TOutput> {
    const file: File = input?.file as unknown as File;
    const url = this.getUrl(link.action);
    return this.bquery.request<TOutput>({
      url: `${url.toString()}?filename=${file.name}`,
      data: file,
      method: link.method,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
