/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/require-default-props */
import * as time from '@helpers/time';
import ConsommationFlotteAPIService from '@services/api/ConsommationFlotteAPIService';
import { ConsommationFlotteList, ErreurFonctionnelle } from '@services/interfaces/generated/YSER-v1';
import { useBQuery } from '@shared/contexts/bquery-provider';
import { AxiosError } from 'axios';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { useOAuth } from '../auth-provider';
import { useOverrideQuery } from '../helpers';

const ConsommationFlotteAPIContext = React.createContext<ConsommationFlotteAPIService | null>(null);
const ConsommationFlotteCacheKey = 'ConsommationFlotte';
export type ConsommationFlotteAPIProviderProps = {
  mockInstance?: ConsommationFlotteAPIService;
};

export const ConsommationFlotteAPIProvider: React.FC<ConsommationFlotteAPIProviderProps> = ({
  children,
  mockInstance = undefined,
}) => {
  const BQuery = useBQuery();

  const instance = React.useMemo(() => {
    if (!!mockInstance) return mockInstance;

    return new ConsommationFlotteAPIService(BQuery);
  }, [mockInstance, BQuery]);

  return <ConsommationFlotteAPIContext.Provider value={instance}>{children}</ConsommationFlotteAPIContext.Provider>;
};

export const useConsommationFlotteAPI = (): ConsommationFlotteAPIService => {
  const context = React.useContext(ConsommationFlotteAPIContext);
  if (context === undefined || context === null) {
    throw new Error('The `useConsommationFlotteAPI` hook must be used within a ConsommationFlotteAPIProvider');
  }
  return context;
};

export const useObtenirConsommationFlotte = (
  href?: string,
): UseQueryResult<ConsommationFlotteList | undefined, AxiosError<ErreurFonctionnelle>> => {
  const ConsommationFlotteAPI = useConsommationFlotteAPI();
  const auth = useOAuth();
  return useOverrideQuery(
    [ConsommationFlotteCacheKey, href],
    () => ConsommationFlotteAPI.obtenirConsommationFlotte(href, { idToken: auth.idToken }),
    {
      cacheTime: time.fromSeconds(1),
      staleTime: time.fromSeconds(1),
    },
  );
};
