import React, { Suspense } from 'react';
import AppAuthentication from './authentication';
import APILoader from './loader/api';
import AppShell from './shell';
import SplashScreen from './splashscreen';

const App: React.FC = () => {
  // NOTE : Nous n'utilisons pas de `useTranslation(...)` ici, car cela génère une suspension dans le render, qui n'est pas traité par un Suspense au niveau au-dessus
  return (
    <Suspense fallback={<SplashScreen text="Chargement en cours ..." />}>
      <AppAuthentication>
        <APILoader>
          <AppShell />
        </APILoader>
      </AppAuthentication>
    </Suspense>
  );
};

export default App;
