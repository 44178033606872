import { Actualite, ActualiteIn, ActualiteList, ImageUrl, UploadImageIn, XAction } from '@generated/YSER-v1';
import { emptyGuid } from '@shared/constants';
import APIServicesBase from './APIServicesBase';
import { ActualitesSearchFilters, QueryParams } from './types';
import { setQueryParams } from './utils';

export default class ActualiteAPIService extends APIServicesBase {
  private descriptor?: ActualiteList;

  isLoaded(): boolean {
    return !!this.descriptor;
  }

  listerActualites(href?: string, options?: ActualitesSearchFilters): Promise<ActualiteList | undefined> {
    if (href === undefined) {
      return Promise.resolve(undefined);
    }

    const url = this.getUrl(href);
    setQueryParams(url, options);
    return this.bquery.get<ActualiteList>(url.toString());
  }

  obtenirActualite(href?: string): Promise<Actualite | undefined> {
    if (href === undefined || href === emptyGuid) {
      return Promise.resolve(undefined);
    }
    const url = this.getUrl(href);
    return this.bquery.get<Actualite>(url.toString());
  }

  createActualite(link: XAction, request: ActualiteIn, params: QueryParams): Promise<Actualite> {
    return super.executeAsync<Actualite, ActualiteIn>(link, request, params);
  }

  modifierActualite(link: XAction, request: ActualiteIn): Promise<Actualite> {
    return super.executeAsync<Actualite, ActualiteIn>(link, request);
  }

  supprimerActualite(link: XAction): Promise<void> {
    return super.executeAsync<void>(link);
  }

  envoyerImage(link: XAction, input: UploadImageIn): Promise<ImageUrl> {
    return super.uploadFileAsync<ImageUrl>(link, input);
  }
}
