import React from 'react';
import { AutoCompleteItemProps } from './AutoCompleteItemProps';

const AutoCompleteItem = <T extends object>({
  dataCy,
  children,
  item,
  onSelected,
}: AutoCompleteItemProps<T>): JSX.Element => {
  return (
    // eslint-disable-next-line
    <div
      data-cy={`autocomplete-item-${dataCy}`}
      role="listitem"
      className="autocomplete-item"
      onClick={() => {
        onSelected(item);
      }}
    >
      {children}
    </div>
  );
};

export default AutoCompleteItem;
