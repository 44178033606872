import { EcoAstuce, EcoAstuceIn, EcoAstuceList, ImageUrl, UploadImageIn, XAction } from '@generated/YSER-v1';
import { emptyGuid } from '@shared/constants';
import APIServicesBase from './APIServicesBase';
import { EcoAstucesSearchFilters } from './types';
import { setQueryParams } from './utils';

export default class EcoAstucesAPIService extends APIServicesBase {
  private descriptor?: EcoAstuceList;

  isLoaded(): boolean {
    return !!this.descriptor;
  }

  listerEcoAstuces(href?: string, options?: EcoAstucesSearchFilters): Promise<EcoAstuceList | undefined> {
    if (href === undefined) {
      return Promise.resolve(undefined);
    }

    const url = this.getUrl(href);
    setQueryParams(url, options);
    return this.bquery.get<EcoAstuceList>(url.toString());
  }

  obtenirEcoAstuce(href?: string): Promise<EcoAstuce | undefined> {
    if (href === undefined || href === emptyGuid) {
      return Promise.resolve(undefined);
    }
    const url = this.getUrl(href);
    return this.bquery.get<EcoAstuce>(url.toString());
  }

  createEcoAstuce(link: XAction, request: EcoAstuceIn): Promise<EcoAstuce> {
    return super.executeAsync<EcoAstuce, EcoAstuceIn>(link, request);
  }

  modifierEcoAstuce(link: XAction, request: EcoAstuceIn): Promise<EcoAstuce> {
    return super.executeAsync<EcoAstuce, EcoAstuceIn>(link, request);
  }

  supprimerEcoAstuce(link: XAction): Promise<void> {
    return super.executeAsync<void>(link);
  }

  envoyerImage(link: XAction, input: UploadImageIn): Promise<ImageUrl> {
    return super.uploadFileAsync<ImageUrl>(link, input);
  }
}
