import { BQuery } from '@bytel/query';
import { useAccessToken } from '@contexts/auth-provider';
import getConfig from '@misc/configuration';
import * as React from 'react';
import { useContext } from 'react';

const BQueryContext = React.createContext<BQuery | null>(null);

export const BQueryProvider: React.FC = ({ children }) => {
  // On ne passe pas le logger a BQuery car Prisme n'est pas disponible
  // const logger = useLogger();
  const logger = undefined;
  const header = useAccessToken();

  const instanceBQuery = React.useMemo(() => {
    return new BQuery(
      getConfig().then((config) => ({
        baseURL: config.API_URL,
        cache: {
          maxAge: 5 * 60 * 1000,
          exclude: {
            paths: [],
          },
        },
        authProvider: () => header,
      })),
      logger,
    );
  }, [logger, header]);

  return <BQueryContext.Provider value={instanceBQuery}>{children}</BQueryContext.Provider>;
};

export const useBQuery = (): BQuery => {
  const context = useContext(BQueryContext);
  if (context === undefined || context === null) {
    throw new Error('The `useBQuery` hook must be used within a BQueryProvider');
  }
  return context;
};
