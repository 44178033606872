export enum FormMode {
  EDIT = 'edit',
  CREATE = 'new',
}

export enum SI {
  OTA = 'OTA',
  PRESTATION = 'Prestation',
  SFR = 'sfr',
  VALORISATION = 'Valorisation',
  FULL = 'FULL',
  FULLER = 'FULLER',
}

export enum ActionInOut {
  ADD = 'Ajout',
  EDIT = 'Modification',
  DELETE = 'Suppression',
}
