import { Partenaire } from '@services/interfaces/generated/YSER-v1';
import { useTooglePartenaire } from '@shared/contexts/api/partenaire-provider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseItemPartenaireProps } from './type';

const useData = (partenaire: Partenaire): UseItemPartenaireProps => {
  const { t } = useTranslation('domain/partenaires');

  const togglePartenaire = useTooglePartenaire(partenaire._actions.togglePartenaire);

  const togglePartenaireCallback = useCallback(async () => {
    await togglePartenaire.mutateAsync({
      disabled: partenaire.estActif ?? false,
      id: partenaire.id,
    });
  }, [partenaire, togglePartenaire]);

  const { url } = partenaire;

  const redirectToPartenaireCallback = useCallback(() => {
    if (url) window.open(url, '_blank');
  }, [url]);

  return {
    togglePartenaireCallback,
    redirectToPartenaireCallback,
    t,
  };
};

export default useData;
