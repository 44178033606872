import { InputClickEvent } from '@bytel/trilogy-react-ts/lib/components/input/InputProps';
import { ECOASTUCES_LIST_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { EcoAstucesSearchFilters } from '@services/api/types';
import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { useListerEcoAstuces } from '@shared/contexts/api';
import { useSiren } from '@shared/contexts/siren-context';
import useUrlToken from '@shared/hooks/use-url-token';
import { defaultPagination } from '@shared/misc/helpers/pagination';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useData = () => {
  const { t } = useTranslation('domain/eco-astuces', {
    keyPrefix: 'list-bte',
  });

  const siren = useSiren();
  const [searchFilters, setSearchFilters] = useState<EcoAstucesSearchFilters>({
    ...defaultPagination,
    ...{ limit: 50, limitBTE: 50, onlyBTE: true },
  });
  const handleSearchClick = useCallback(({ inputValue }: InputClickEvent) => {
    if (inputValue === '') return;
    setSearchFilters({
      ...defaultPagination,
      ...{ limit: 50, limitBTE: 50, onlyBTE: true, title: inputValue },
    });
  }, []);

  const {
    data: ecoAstuces,
    isLoading,
    isFetching,
  } = useListerEcoAstuces(siren?._links?.listerEcoAstuces?.href, searchFilters);
  const sirenToken = useUrlToken(RouteParameters.sirenId);
  const ecoAstucesBTE = useMemo(() => ecoAstuces?.items.filter(({ isBTE }) => isBTE), [ecoAstuces?.items]);

  const navigationItemConfig = React.useMemo<NavigationItemConfig>(
    () => ({
      label: t('breadcrumb.ecoastuces-bte'),
      link: { href: ECOASTUCES_LIST_PATH.replace(RouteParameters.sirenId, sirenToken.toString()) },
    }),
    [sirenToken, t],
  );

  return {
    isLoading,
    isFetching,
    ecoAstucesBTE: ecoAstucesBTE ?? [],
    t,
    navigationItemConfig,
    handleSearchClick,
  };
};

export default useData;
