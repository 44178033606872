import { Columns, ColumnsItem, Icon, IconName, Link, Title, TitleLevels } from '@bytel/trilogy-react-ts';
import { sentenceToString } from '@shared/misc/helpers/strings';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import { NavigationItemProps } from './type';

const NavigationItem: React.FC<NavigationItemProps> = ({ config }) => {
  const { label, link } = config;

  return (
    <Columns className="is-marginless has-background-grey-light">
      <Link
        to={link?.href}
        removeLinkClass
        className="has-text-primary"
        data-cy="link-previous"
        key={sentenceToString(label)}
        routerLink={routerLink}
      >
        <Icon name={IconName.ARROW_LEFT} size="large" className="has-text-primary" />
      </Link>
      <ColumnsItem verticalCenter>
        <Title data-cy={`link-${sentenceToString(label)}`} level={TitleLevels.TWO} className="is-unselectable">
          {label}
        </Title>
      </ColumnsItem>
    </Columns>
  );
};

export default NavigationItem;
