/**
 * Nombre de minutes dans une heure
 */
const $minutes = 60;
/**
 * Nombre de secondes dans une minute
 */
const $seconds = 60;
/**
 * Nombre de millisecondes dans une seconde
 */
const $milliseconds = 1000;

/**
 * Retourne un nombre de secondes en millisecondes
 */
export const fromSeconds = (seconds: number) => {
  return seconds * $milliseconds;
};

/**
 * Retourne un nombre de minutes en millisecondes
 */
export const fromMinutes = (minutes: number) => {
  return minutes * $seconds * $milliseconds;
};

/**
 * Retourne un nombre d'heures en millisecondes
 */
export const fromHours = (hours: number) => {
  return hours * $minutes * $seconds * $milliseconds;
};

/**
 * Retourne un mois et une année formattés
 */
export const dateToFormat = (date: string): string => {
  const dateTrans = new Date(date);
  const month = dateTrans.toLocaleString('default', { month: 'short' }).replace('.', '');
  const year = dateTrans.getFullYear() % 100;
  return `${month}/${year}`;
};
