import { FooterBody, Link, Text, TextLevels } from '@bytel/trilogy-react-ts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

const Footer: React.FC = () => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'footer',
  });

  return (
    <footer className="footer is-paddingless" style={{ marginTop: 'auto' }}>
      <FooterBody className="footerCustom is-inverted">
        <Link
          data-cy="link-documentation"
          onClick={() => {
            window.open(t('descriptionUrl'), '_blank');
          }}
          to={t('descriptionUrl')}
        >
          <Text className="is-inverted" level={TextLevels.TWO}>
            {t('descriptionLink')}
          </Text>
        </Link>
      </FooterBody>
      <FooterBody />
    </footer>
  );
};

export default Footer;
