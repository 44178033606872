import { Columns, ColumnsItem, InputStatus, Textarea } from '@bytel/trilogy-react-ts';
import { executeFormikValidation } from '../helpers';
import { TextAreaProps, TriggeredEvent } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';
import { matchEvents } from './util';

const TextAreaComponent = <T extends object>(props: TextAreaProps<T>) => {
  const { fieldConfig, inputName, dataCy, setInputValue, formik, label, placeholder, displayConfig, displayErrors } =
    props;

  const { disabled, required, onChangeEvent, triggeredEvent = TriggeredEvent.OnMouseLeave } = fieldConfig;
  const { values, errors } = formik;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <Textarea
          data-cy={`textAreaType-${dataCy}-${inputName}`}
          placeholder={placeholder}
          status={errors[inputName] && displayErrors ? InputStatus.ERROR : InputStatus.WARNING}
          defaultValue={values[inputName]}
          onChange={({ textareaValue }) => {
            setInputValue(inputName, textareaValue);
            if (matchEvents(triggeredEvent, TriggeredEvent.OnChange) && onChangeEvent) {
              onChangeEvent(formik, values as T, textareaValue);
            }
          }}
          disabled={disabled ? executeFormikValidation(disabled, values as T) : false}
        />
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default TextAreaComponent;
