import React, { useCallback, useContext, useMemo, useState } from 'react';
import { LoaderContextType } from './types';

const defaultValue = {
  showLoader: () => {
    // Default show loader func
  },
  hideLoader: () => {
    // Default hide loader func
  },
};

const Context = React.createContext<LoaderContextType>(defaultValue);

export const LoadingContext: React.FC = ({ children }) => {
  const [invokersMap, setInvokersMap] = useState<Record<string, boolean>>({ default: false });

  const show = useMemo<boolean>(() => {
    return Object.values(invokersMap).some((x) => x);
  }, [invokersMap]);

  const showLoader = useCallback(
    (invokerKey = 'default') => {
      if (!invokersMap[invokerKey]) {
        setInvokersMap((current) => ({
          ...current,
          [invokerKey]: true,
        }));
      }
    },
    [invokersMap],
  );

  const hideLoader = useCallback(
    (invokerKey = 'default') => {
      if (invokersMap[invokerKey]) {
        setInvokersMap((current) => ({
          ...current,
          [invokerKey]: false,
        }));
      }
    },
    [invokersMap],
  );

  const values = useMemo(
    () => ({
      showLoader,
      hideLoader,
    }),
    [hideLoader, showLoader],
  );

  return (
    <Context.Provider value={values}>
      {children}
      {show && (
        <div
          data-cy="loader-background"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            zIndex: 9001, // 9000 étant le zIndex des popover trilogy (valeur la plus haute utilisée actuellement)
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <div
            data-cy="loader"
            style={{
              height: '10vh',
              width: '10vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div className="loader">
              <div className="loader-body" />
            </div>
          </div>
        </div>
      )}
    </Context.Provider>
  );
};

export const useLoader = () => useContext(Context);
