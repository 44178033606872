const blue = '#29d';
const lightBlue = '#109DB9';

const theme = {
  colors: {
    blue,
    lightBlue,
  },
};

export default theme;
