import { format } from 'date-fns'
import * as URI from 'urijs'
import { PrismeConfig } from '../config'
import { uuid } from '../helpers/uuid'
import { Contexte, Erreur, Evenement, ExecAppelRest, Log } from '../interfaces/notifierMessagePrisme'
import { filtrerInput } from './inputFilter'
import { extrairePatternUrl } from './patternExtractor'

export const HEADER_X_SOURCE = 'X-Source'
export const HEADER_TRACKER_ID = 'trackerId'
export const HEADER_X_REQUEST_ID = 'X-Request-Id'
export const HEADER_X_ACTION_ID = 'X-Action-Id'
export const HEADER_X_MESSAGE_ID = 'X-Message-Id'
export const DATE_FORMAT_PRISME = "yyyy-MM-dd'T'HH:mm:ss.SSSXX"
export const HEADER_REQUEST_TIME = 'X-Request-Time'

export function construireContexte(
  prismeConfig: PrismeConfig,
  {
    headers,
    erreur,
    action,
    ...specifiqueMetier
  }: { headers?: any; erreur?: Erreur | Error; action?: string; [key: string]: any }
): Contexte {
  const ctx: Contexte = {
    requestId: getCaseInsensitive(headers, HEADER_X_REQUEST_ID) || uuid(),
    actionId: getCaseInsensitive(headers, HEADER_X_ACTION_ID) || uuid(),
    messageId: getCaseInsensitive(headers, HEADER_X_MESSAGE_ID) || uuid(),
    source: getCaseInsensitive(headers, HEADER_X_SOURCE) || prismeConfig.st,
    trackerId: getCaseInsensitive(headers, HEADER_TRACKER_ID) || prismeConfig.getTrackerId(),

    location_origin: globalThis?.location?.origin,
    location_pathname: globalThis?.location?.pathname,
    location_href: globalThis?.location?.href,

    ...prismeConfig.userInfoProvider?.(),
    ...specifiqueMetier
  }

  return ctx
}

export function construireEvenement(prismeConfig: PrismeConfig, currentTime: number, enErreur: boolean): Evenement {
  return {
    idEv: uuid(),
    nom: `${prismeConfig.st}_Evenement`,
    date: format(currentTime, DATE_FORMAT_PRISME),
    timestamp: currentTime,
    severite: enErreur ? 'ERROR' : 'INFO',
    priorite: enErreur ? '3' : '6',
    emetteur: 'LoggeurPrismeIHM'
  } as Evenement
}

export function construireExecAppelRest(
  prismeConfig: PrismeConfig,
  currentTime: number,
  headers: Record<string, string>,
  url?: string,
  method?: string,
  input?: any,
  status?: string | number,
  erreur?: Erreur
): ExecAppelRest {
  const evenement = construireEvenement(prismeConfig, currentTime, !!erreur)
  const requestTime = headers[HEADER_REQUEST_TIME] ? parseInt(headers[HEADER_REQUEST_TIME]) : undefined

  let nom = `APPELRS`
  let host: string | undefined
  let port: number | undefined
  let urlPattern: string | undefined
  let pathname: string | undefined

  if (url != null) {
    if (url.startsWith('http')) {
      let urlObj = URI(url)
      host = urlObj.hostname()
      port = urlObj.port() ? parseInt(urlObj.port()) : undefined
      pathname = urlObj.pathname()
    } else {
      host = window?.location?.origin
      pathname = url
    }
    urlPattern = extrairePatternUrl(url)
    const pathnamePattern = extrairePatternUrl(pathname)
    nom += `_${pathnamePattern}`
  }

  let inputFinal: string | undefined

  if (!prismeConfig.disableInput) {
    const inputFinalObj = filtrerInput(input, prismeConfig.filtresInputAdditionnels)
    inputFinal = inputFinalObj == null ? undefined : JSON.stringify(inputFinalObj).substring(0, 31990)
  }

  let execAppelRest: ExecAppelRest = {
    ...evenement,
    typeEv: 'APPELRESS',
    nom,
    debut: requestTime ? format(requestTime, DATE_FORMAT_PRISME) : undefined,
    fin: format(currentTime, DATE_FORMAT_PRISME),
    duree: requestTime ? currentTime - requestTime : undefined,
    enErreur: !!erreur,
    erreur,
    url,
    urlPattern,
    headers: masquerHeadersAuthentification(headers),
    host,
    port,
    httpMethod: method || 'get',
    httpStatus: status?.toString(),
    input: inputFinal
  }
  return execAppelRest
}

function masquerHeadersAuthentification(headers: any) {
  const headersFinaux = { ...headers }
  Object.keys(headers).forEach(key => {
    if (/authorization/i.test(key)) {
      headersFinaux[key] = headersFinaux[key].substring(0, 14) + '**HIDDEN**'
    }
  })
  return headersFinaux
}

export function construireLog(
  prismeConfig: PrismeConfig,
  currentTime: number,
  message: string,
  erreur?: Erreur | Error,
  action?: string
): Log {
  const evenement = construireEvenement(prismeConfig, currentTime, !!erreur)

  let erreurFinale: Erreur
  if (erreur && erreur instanceof Error) {
    erreurFinale = {
      code: erreur.name,
      description: erreur.message,
      type: 'TECHNIQUE',
      declencheur: erreur.stack,
      origine: erreur.stack?.split(/\n/)[0]
    }
  } else {
    erreurFinale = erreur as Erreur
  }

  return {
    ...evenement,
    typeEv: 'LOG',
    nom: `LOG`,
    message,
    erreur: erreurFinale,
    action
  }
}

export function construireHeadersTracabilite(prismeConfig: PrismeConfig, headersExistants?: Record<string, string>) {
  const headers = headersExistants || {}
  if (!getCaseInsensitive(headers, HEADER_X_SOURCE)) headers[HEADER_X_SOURCE] = prismeConfig.st
  if (!getCaseInsensitive(headers, HEADER_X_REQUEST_ID)) headers[HEADER_X_REQUEST_ID] = uuid()
  if (!getCaseInsensitive(headers, HEADER_X_ACTION_ID)) headers[HEADER_X_ACTION_ID] = uuid()
  if (!getCaseInsensitive(headers, HEADER_X_MESSAGE_ID)) headers[HEADER_X_MESSAGE_ID] = uuid()
  if (!getCaseInsensitive(headers, HEADER_TRACKER_ID)) headers[HEADER_TRACKER_ID] = prismeConfig.getTrackerId()
  if (!getCaseInsensitive(headers, HEADER_REQUEST_TIME)) headers[HEADER_REQUEST_TIME] = Date.now().toString()

  return headers
}

function getCaseInsensitive(obj: { [key: string]: string }, property: string): string | undefined {
  return obj?.[Object.keys(obj || {}).find(prop => prop.toLowerCase() === property.toLowerCase()) || '']
}
