import {
  Alignable,
  Card,
  CardContent,
  CardImage,
  CardImageSize,
  Icon,
  IconName,
  Link,
  RowItem,
  Rows,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  TrilogyColor,
} from '@bytel/trilogy-react-ts';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import useData from './hook';
import { BoxProps } from './type';

/**
 * Box component pour EcoAstuce et Actualités
 * @param dataCy {string} cypress testing
 * @param title {string} title text
 * @param text {string} content text
 * @param detailLink {string} link
 * @param showDelete {boolean} show delete button
 * @param onDelete {clickEvent} onClick delete button
 * @param skeleton {boolean} component skeleton
 * @param src {string} image url source
 */
const Box: React.FC<BoxProps> = ({ dataCy, title, text, detailLink, showDelete, onDelete, skeleton, src }) => {
  const { truncatedTextContent, truncatedTitleContent } = useData(text, title);
  return (
    <Card data-cy={`card-${dataCy}`} horizontal skeleton={skeleton} className="is-fullheight">
      <CardImage size={CardImageSize.SIZE_3} src={src} data-cy={`card-image-${dataCy}`} />
      <CardContent>
        {showDelete && (
          <Icon
            align={Alignable.ALIGNED_END}
            data-cy={`card-icon-close-${dataCy}`}
            color={TrilogyColor.PRIMARY}
            name={IconName.TIMES_CIRCLE}
            onClick={onDelete}
            style={{ cursor: 'pointer' }}
          />
        )}
        <Link fixed to={detailLink} routerLink={routerLink}>
          <Rows>
            <RowItem>
              {title && (
                <Title level={TitleLevels.THREE} data-cy={`card-title-${dataCy}`}>
                  {truncatedTitleContent}
                </Title>
              )}
            </RowItem>
            <RowItem>
              {truncatedTextContent && (
                <Text level={TextLevels.THREE} data-cy={`card-text-${dataCy}`}>
                  {truncatedTextContent}
                </Text>
              )}
            </RowItem>
          </Rows>
        </Link>
      </CardContent>
    </Card>
  );
};
export default Box;
