import publicIp from 'public-ip'

const IP_STORAGE_KEY = 'current-ip'

let nbEchec = 0
export async function getPublicIp(storage: Storage): Promise<string> {
  const ipExistante = storage.getItem(IP_STORAGE_KEY)
  if (ipExistante) {
    return ipExistante
  }
  let _publicIp: string
  try {
    _publicIp = await publicIp.v4({ timeout: 1000 })
    storage.setItem(IP_STORAGE_KEY, _publicIp)
  } catch (erreur) {
    _publicIp = ''
    nbEchec++
    if (nbEchec >= 5) {
      storage.setItem(IP_STORAGE_KEY, _publicIp)
    }
  }
  return _publicIp
}
