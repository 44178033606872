import { Checkbox, Columns, ColumnsItem } from '@bytel/trilogy-react-ts';
import React from 'react';
import { executeFormikValidation } from '../helpers';
import { CheckboxProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const CheckboxComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label,
  fieldConfig,
  displayConfig,
  displayErrors,
}: CheckboxProps<T>) => {
  const { options, disabled, onChangeEvent, required } = fieldConfig;
  const { values } = formik;

  const handleChange = (op) => {
    const value = formik.values[inputName] || [];
    const updatedValue = value.includes(op.value) ? value.filter((v) => v !== op.value) : [...value, op.value];
    setInputValue(inputName, updatedValue);
    if (onChangeEvent) onChangeEvent(formik, values as T, values[inputName]);
  };
  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        {options?.map((o) => (
          <Checkbox
            key={o.value}
            data-cy={`checkbox-type-${dataCy}-${inputName}`}
            name={inputName}
            label={o.label}
            checked={values[inputName]?.includes(o.value)}
            value={o.value}
            onChange={() => handleChange(o)}
            disabled={disabled ? executeFormikValidation(disabled, values as T) : false}
          />
        ))}
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default CheckboxComponent;
