import { OAUTH_CALLBACK, OAUTH_LOGIN } from '@router/paths';
import {
  useAuthenticationError,
  useIsAuthenticated,
  useIsAuthenticating,
  useIsLoaded,
} from '@shared/contexts/auth-provider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import SplashScreen from '../splashscreen';
import { SplashScreenStatus } from '../splashscreen/types';
import AppAuthenticationCallBack from './callback';
import AppAuthenticationInProgress from './inProgress';

const AppAuthentication: React.FC = ({ children }) => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'loading',
  });
  const location = useLocation();

  const isLoaded = useIsLoaded();
  const isAuthenticating = useIsAuthenticating();
  const isAuthenticated = useIsAuthenticated();
  const [hasError] = useAuthenticationError();
  const isAuthRoute = location.pathname === OAUTH_LOGIN || location.pathname === OAUTH_CALLBACK;

  return (
    <Switch>
      <Route exact path={OAUTH_LOGIN}>
        <AppAuthenticationInProgress />
      </Route>
      <Route exact path={OAUTH_CALLBACK}>
        <AppAuthenticationCallBack />
      </Route>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {isLoaded && isAuthenticated && !isAuthRoute && <>{children}</>}
      <Route>
        {isLoaded && isAuthenticating && !isAuthenticated && <Redirect to={OAUTH_LOGIN} />}
        {!isLoaded && !hasError && <SplashScreen text={t('global')} />}
        {!isLoaded && hasError && <SplashScreen text={t('authentication-failed')} status={SplashScreenStatus.ERROR} />}
      </Route>
    </Switch>
  );
};

export default AppAuthentication;
