import { Title } from '@bytel/trilogy-react-ts';
import {
  AutoCompleteConfig,
  ImagePickerInputConfig,
  InputConfig,
  ListConfig,
  PreCompleteInputConfig,
  RenderInputProps,
} from '../type';
import AutoCompleteComponent from './autoCompleteComponent';
import BaseInputComponent from './baseInputComponent';
import CheckboxComponent from './checkBoxComponent';
import DateComponent from './dateComponent';
import DropDownComponent from './dropDownComponent';
import ImagePickerComponent from './imagePickerComponent';
import PreCompleteInputComponent from './preCompleteInputComponent';
import RadioComponent from './radioComponent';
import SelectComponent from './selectComponent';
import './style.css';
import SwitchComponent from './switchComponent';
import TextAreaComponent from './textAreaComponent';

const RenderInputType = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  type,
  label,
  fieldConfig,
  displayConfig,
  placeholder,
  displayErrors,
}: RenderInputProps<T>): JSX.Element => {
  switch (type) {
    case 'text':
    case 'number':
      return (
        <BaseInputComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig as InputConfig<T>}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
          type={type}
        />
      );
    case 'preCompleteText':
      return (
        <PreCompleteInputComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig as PreCompleteInputConfig<T>}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    case 'checkBox':
      return (
        <CheckboxComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          displayErrors={displayErrors}
        />
      );
    case 'radio':
      return (
        <RadioComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          displayErrors={displayErrors}
        />
      );
    case 'select':
      return (
        <SelectComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig as ListConfig<T>}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    case 'date':
    case 'datetime-local':
      return (
        <DateComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          type={type}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    case 'switch':
      return (
        <SwitchComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          displayErrors={displayErrors}
        />
      );
    case 'dropDown':
      return (
        <DropDownComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    case 'autocomplete':
      return (
        <AutoCompleteComponent<T>
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig as AutoCompleteConfig<T>}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    case 'image':
      return (
        <ImagePickerComponent
          label={label}
          dataCy={dataCy}
          inputName={inputName}
          setInputValue={setInputValue}
          formik={formik}
          displayErrors={displayErrors}
          fieldConfig={fieldConfig as ImagePickerInputConfig<T>}
        />
      );
    case 'textarea':
      return (
        <TextAreaComponent
          inputName={inputName}
          dataCy={dataCy}
          setInputValue={setInputValue}
          formik={formik}
          label={label}
          fieldConfig={fieldConfig as InputConfig<T>}
          displayConfig={displayConfig}
          placeholder={placeholder}
          displayErrors={displayErrors}
        />
      );
    default:
      return <Title data-cy={`defaultCase-${dataCy}-${inputName}`}>Oups verifier les type</Title>;
  }
};
export default RenderInputType;
