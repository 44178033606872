import {
  Columns,
  ColumnsItem,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
} from '@bytel/trilogy-react-ts';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import { executeFormikValidation } from '../helpers';
import { DropDownProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const DropDownComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label,
  placeholder,
  fieldConfig,
  displayConfig,
  displayErrors,
}: DropDownProps<T>) => {
  const { options, disabled, onChangeEvent, buttonContent, required } = fieldConfig;
  const { values } = formik;

  const handleChange = (op) => {
    const value = formik.values[inputName] || [];
    const updatedValue = value.includes(op.value) ? value.filter((v) => v !== op.value) : [...value, op.value];
    setInputValue(inputName, updatedValue);
    if (onChangeEvent) onChangeEvent(formik, values as T, values[inputName]);
    console.log(formik);
  };
  return (
    <Columns multiline verticalCentered={!!displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={label}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        <Dropdown data-cy={`dropdown-type-${dataCy}-${inputName}`}>
          <DropdownTrigger label={label} placeholder={placeholder} />
          <DropdownMenu>
            {options?.map((o, i) => (
              <DropdownItem
                data-cy={`dropdownitem-type-${dataCy}-${inputName}-${i}`}
                key={o.value}
                label={o.label}
                value={o.value}
                checked={values[inputName]?.includes(o.value)}
                onChange={() => handleChange(o)}
                disabled={disabled ? executeFormikValidation(disabled, values) : false}
              />
            ))}
            {buttonContent && (
              <>
                <DropdownDivider />
                <DropdownItem>
                  <Link to="/" routerLink={routerLink}>
                    {buttonContent}
                  </Link>
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={label}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default DropDownComponent;
