import { ACTUALITE_EDIT_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import ActualiteBox from '@shared/components/box';
import useUrlToken from '@shared/hooks/use-url-token';
import UrlToken from '@shared/misc/helpers/urlToken';
import React from 'react';
import useData from './hook';
import { CardProps } from './type';

const Card: React.FC<CardProps> = ({ actualite, skeleton, activatePopup }) => {
  const { confirmDelete } = useData(actualite);
  const sirenToken = useUrlToken(RouteParameters.sirenId);

  return (
    <ActualiteBox
      dataCy={actualite.id}
      title={actualite.title}
      text={actualite.description}
      src={actualite.image ?? ''}
      showDelete
      onDelete={() => {
        activatePopup(confirmDelete, actualite);
      }}
      detailLink={ACTUALITE_EDIT_PATH.replace(RouteParameters.sirenId, sirenToken.toString()).replace(
        RouteParameters.actualiteId,
        UrlToken.fromXLink(actualite._links.self).toString(),
      )}
      skeleton={skeleton}
    />
  );
};

export default Card;
