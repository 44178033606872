import { ACTUALITES_LIST_PATH } from '@router/paths';
import { RouteParameters } from '@router/types';
import { ActualiteIn } from '@services/interfaces/generated/YSER-v1';
import { FormikConfigType, Shape } from '@shared/components/ui-components/form/type';
import {
  useCreateActualite,
  useEnvoyerImageActualites,
  useModifierActualite,
  useObtenirActualites,
} from '@shared/contexts/api';
import { useUrlTokens } from '@shared/hooks/use-url-token';

import { FormMode } from '@shared/enum';
import useFormMode from '@shared/hooks/use-form-mode';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { NavigationItemConfig } from '@shared/components/navigation-item/type';
import { imageDimensionForCreationForm } from '@shared/constants';
import { useSiren } from '@shared/contexts/siren-context';
import { isValidHttpUrl } from '@shared/misc/helpers/strings';
import * as Yup from 'yup';
import { UseEditerActualitesProps } from './type';

const useData = (): UseEditerActualitesProps<ActualiteIn> => {
  const { t } = useTranslation('domain/actualites', {
    keyPrefix: 'create',
  });

  const history = useHistory();
  const formMode = useFormMode();
  const isEditMode: boolean = formMode === FormMode.EDIT;
  const [actualiteToken, sirenToken] = useUrlTokens([RouteParameters.actualiteId, RouteParameters.sirenId]);

  const { data: actualite, isLoading, isFetching } = useObtenirActualites(actualiteToken.toUrlString());
  const siren = useSiren();

  const uploadPicture = useEnvoyerImageActualites(siren?._actions?.envoyerImageActualite);
  const creerActualite = useCreateActualite(siren?._actions?.ajouterActualite);
  const modifierActualite = useModifierActualite(actualite?._actions?.modifierActualite);

  const backActualites = useMemo<string>(
    () => ACTUALITES_LIST_PATH.replace(RouteParameters.sirenId, sirenToken.toString()),
    [sirenToken],
  );

  const navigationItemConfig = React.useMemo<NavigationItemConfig>(
    () => ({
      label: isEditMode ? t('breadcrumb.editActualite') : t('breadcrumb.createActualite'),
      link: { href: backActualites },
    }),
    [backActualites, isEditMode, t],
  );

  const cbMutateActualite = useCallback(
    async (actualiteToPush: ActualiteIn) => {
      const actualiteMutate = isEditMode ? modifierActualite : creerActualite;
      try {
        await actualiteMutate.mutateAsync(actualiteToPush);
        history.push(backActualites);
      } catch (error) {
        console.log(error);
      }
    },
    [isEditMode, modifierActualite, creerActualite, history, backActualites],
  );

  const onSubmit = useCallback(
    async (actualiteToPush: ActualiteIn) => {
      const newActualite = { ...actualiteToPush };

      if (!isValidHttpUrl(newActualite.image)) {
        const file = newActualite.image as unknown as File;
        if (file) {
          const request = {
            file,
          };
          try {
            const uploadedImageUri = await uploadPicture.mutateAsync(request);
            newActualite.image = uploadedImageUri?.url;
          } catch (error) {
            console.error('failed to upload image');
            newActualite.image = '';
          }
        } else {
          console.error('No file found');
        }
      }

      cbMutateActualite(newActualite);
    },
    [cbMutateActualite, uploadPicture],
  );

  const config = useMemo<FormikConfigType<ActualiteIn>>(
    () => ({
      configuration: [
        {
          inputName: 'image',
          type: 'image',
          label: t('chooseImage'),
          displayErrors: true,
          fieldConfig: {
            required: false,
            imagePlaceholder: '/assets/images/greyBackground.png',
            imageStyle: imageDimensionForCreationForm,
          },
        },
        {
          inputName: 'title',
          type: 'text',
          label: t('title'),
          displayErrors: true,
          fieldConfig: {
            required: true,
          },
        },
        {
          inputName: 'description',
          type: 'textarea',
          label: t('description'),
          displayErrors: true,
          fieldConfig: {
            required: true,
          },
        },
        {
          inputName: 'url',
          type: 'text',
          label: t('url'),
          displayErrors: true,
          fieldConfig: {
            required: false,
          },
        },
      ],
      initialValues: {
        image: actualite?.image,
        url: actualite?.url,
        title: actualite?.title,
        description: actualite?.description,
      },
      validationSchema: Yup.object<Shape<ActualiteIn>>({
        image: Yup.string().required(),
        title: Yup.string()
          .required(t('errors.formik.required'))
          .min(8, t('errors.formik.notEnoughChar', { item: t('errors.formik.title') })),
        description: Yup.string()
          .required(t('errors.formik.required'))
          .min(8, t('errors.formik.notEnoughChar', { item: t('errors.formik.description') })),
      }),
      onSubmit,
    }),
    [actualite?.image, actualite?.url, actualite?.title, actualite?.description, onSubmit, t],
  );

  return {
    config,
    isFetching,
    isLoading,
    navigationItemConfig,
  };
};
export default useData;
