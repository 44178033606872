import {
  Box,
  BoxContent,
  Button,
  Columns,
  ColumnsItem,
  Container,
  Headline,
  HeadlineLevel,
  Icon,
  IconName,
  IconPosition,
  IconSize,
  Popover,
  RowItem,
  Rows,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  TypographyColor,
} from '@bytel/trilogy-react-ts';
import useData from '@domain/home/box-consommation-flotte/hook';
import useHomeData from '@domain/home/hook';
import { unitCO2eq, unitData } from '@shared/constants';
import { useTranslation } from 'react-i18next';
import ButtonNavigation from '../button-navigation';
import './style.css';

const BoxBilanCarbone: React.FC = () => {
  const { last } = useData();
  const { buttonNeutraliteCarbone } = useHomeData();

  const { t } = useTranslation('domain/home', {
    keyPrefix: 'consommationFlotte',
  });

  return (
    <Box className="is-fullwidth is-fullheight" data-cy="home-boxBilanCarbone">
      <BoxContent>
        <Rows>
          <RowItem>
            <Title level={TitleLevels.THREE} data-cy="boxBilanCarbone-bilan">
              {t('bilan')}{' '}
              <Popover content={<Text>{t('infoConso')}</Text>} className="is-popover-top">
                <Icon name={IconName.INFOS_CIRCLE} size={IconSize.SMALL} position={IconPosition.LEFT} />
              </Popover>
            </Title>
          </RowItem>
          <RowItem>
            <Columns className="is-flex is-aligned-center">
              <ColumnsItem size={6} className="is-fullwidth is-flex is-justified-center">
                <Container fluid data-cy="boxBilanCarbone-container">
                  <Columns className="is-justified-center">
                    <ColumnsItem size={5} className="is-flex">
                      <Box className="is-flat is-flat-primary has-text-centered">
                        <Text level={TextLevels.TWO} data-cy="boxBilanCarbone-consommation">
                          {t('consommation')}
                        </Text>
                        <Text className="has-text-grey">
                          <Title
                            markup="span"
                            className="is-primary"
                            level={TitleLevels.ONE}
                            data-cy="boxBilanCarbone-valeurFlotteConso"
                          >
                            {last?.data.value ?? 0}
                          </Title>{' '}
                          {last?.data.unit ?? unitData[0]}
                        </Text>
                        <Text level={TextLevels.TWO} data-cy="boxBilanCarbone-internet">
                          {t('internet')}
                        </Text>
                      </Box>
                    </ColumnsItem>
                    <ColumnsItem
                      size={2}
                      className="is-unselectable is-flex is-paddingless is-aligned-center is-justified-center "
                      verticalCenter
                    >
                      <div className="circleEgal has-text-secondary is-flex is-justified-center">
                        <Headline typo={TypographyColor.TEXT_SECONDARY} level={HeadlineLevel.LEVEL2}>
                          =
                        </Headline>
                      </div>
                    </ColumnsItem>
                    <ColumnsItem size={5} className="is-flex-1">
                      <Box className="is-flat is-flat-primary has-text-centered is-fullheight is-justified-center">
                        <Text level={TextLevels.TWO}>{t('equivalent')}</Text>
                        <Text className="has-text-grey" data-cy="boxBilanCarbone-valeurCarbone">
                          <Title
                            markup="span"
                            className="is-primary"
                            level={TitleLevels.ONE}
                            data-cy="boxBilanCarbone-equivalent"
                          >
                            {last?.co2eq.value ?? 0}
                          </Title>{' '}
                          {last?.co2eq.unit ?? unitCO2eq[0]}
                          <sub>2</sub>
                        </Text>
                        <Text level={TextLevels.TWO} data-cy="boxBilanCarbone-mois">
                          {t('mois')}
                        </Text>
                      </Box>
                    </ColumnsItem>
                  </Columns>
                </Container>
              </ColumnsItem>
              <ColumnsItem size={6}>
                <Rows>
                  <RowItem className="is-fullwidth is-flex is-justified-center">
                    <Text
                      level={TextLevels.TWO}
                      className="has-text-justified has-text-weight-bold"
                      data-cy="boxConsommationFlotte-description"
                    >
                      {t('description')}
                    </Text>
                  </RowItem>
                  <RowItem>
                    <Button
                      variant="TERTIARY"
                      className="is-fullwidth"
                      onClick={() => {
                        window.open(t('urlBilan'), '_blank');
                      }}
                    >
                      <Text level={TextLevels.TWO} data-cy="boxConsommationFlotte-bilan-btn">
                        {t('button')}
                      </Text>
                    </Button>
                  </RowItem>
                  <RowItem key={buttonNeutraliteCarbone.dataCy}>
                    <ButtonNavigation
                      dataCy={buttonNeutraliteCarbone.dataCy}
                      titre={buttonNeutraliteCarbone.titre}
                      description={buttonNeutraliteCarbone.description}
                      routeRef={buttonNeutraliteCarbone.routeRef}
                      iconName={buttonNeutraliteCarbone.iconName}
                    />
                  </RowItem>
                </Rows>
              </ColumnsItem>
            </Columns>
          </RowItem>
        </Rows>
      </BoxContent>
    </Box>
  );
};

export default BoxBilanCarbone;
