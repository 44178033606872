import {
  Button,
  Columns,
  ColumnsItem,
  Icon,
  IconName,
  IconSize,
  Link,
  RowItem,
  Rows,
  Section,
  Text,
  TextLevels,
  Title,
  TitleLevels,
  VariantState,
} from '@bytel/trilogy-react-ts';
import { emptyGuid } from '@shared/constants';
import UrlToken from '@shared/misc/helpers/urlToken';
import React from 'react';
import { Link as routerLink } from 'react-router-dom';
import { HeadbandCreationProps } from './type';

const HeadbandCreation: React.FC<HeadbandCreationProps> = ({ dataCy, PATH, t, urlCreate, id }) => {
  return (
    <Section background="PRIMARY" data-cy={`${dataCy}-new-container`}>
      <Columns>
        <ColumnsItem size={8}>
          <Rows>
            <RowItem narrow>
              <Title inverted level={TitleLevels.TWO} data-cy={`${dataCy}-new-title`}>
                {t('new.title')}
              </Title>
            </RowItem>
            <RowItem narrow>
              <Text level={TextLevels.ONE} inverted data-cy={`${dataCy}-new-description`}>
                {t('new.desc')}
              </Text>
            </RowItem>
          </Rows>
        </ColumnsItem>
        <ColumnsItem verticalCenter className=" is-flex" size={1} />
        <ColumnsItem verticalCenter className=" is-flex" size={3}>
          <Rows>
            <RowItem>
              <Link
                fixed
                to={PATH.replace(id, UrlToken.fromXLink({ href: emptyGuid }).toString())}
                data-cy={`${dataCy}-new-button`}
                routerLink={routerLink}
                className="is-flex is-justified-end"
              >
                <Button disabled={urlCreate === undefined} variant={VariantState.TERTIARY}>
                  <Icon name={IconName.PLUS} size={IconSize.SMALL} align="ALIGNED_CENTER" />
                  <Text level={TextLevels.ONE} className="has-text-white is-offset-1">
                    {t('new.button')}
                  </Text>
                </Button>
              </Link>
            </RowItem>
          </Rows>
        </ColumnsItem>
      </Columns>
    </Section>
  );
};

export default HeadbandCreation;
