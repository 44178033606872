export type AssemblyInfo = {
  name: string;
  version: string;
  commit: string;
};

const assembly: AssemblyInfo = {
  name: process.env.REACT_APP_NAME ?? '',
  version: process.env.REACT_APP_VERSION ?? '',
  commit: process.env.REACT_APP_COMMIT ?? '',
};

export default assembly;
