import { Columns, ColumnsItem, Radio } from '@bytel/trilogy-react-ts';
import React from 'react';
import { executeFormikValidation } from '../helpers';
import { RadioProps } from '../type';
import ErrorComponent from './errorComponent';
import LabelComponent from './labelComponent';

const RadioComponent = <T extends object>({
  inputName,
  dataCy,
  setInputValue,
  formik,
  label: labelTitle,
  fieldConfig,
  displayConfig,
  displayErrors,
}: RadioProps<T>) => {
  const { options, onChangeEvent, required } = fieldConfig;
  const { values } = formik;

  return (
    <Columns multiline verticalCentered={displayConfig?.isPopupDisplay}>
      <LabelComponent
        dataCy={dataCy}
        inputName={inputName}
        label={labelTitle}
        displayConfig={displayConfig}
        required={required}
      />
      <ColumnsItem size={displayConfig?.isPopupDisplay ? 8 : 12}>
        {options?.map(({ label, value, disabled }) => (
          <Radio
            data-cy={`radioType-${dataCy}-${inputName}`}
            name={inputName}
            label={label}
            key={value}
            checked={values[inputName] === value}
            value={value}
            onChange={() =>
              onChangeEvent
                ? [setInputValue(inputName, value), onChangeEvent(formik, values as T, values[inputName])]
                : setInputValue(inputName, value)
            }
            disabled={disabled ? executeFormikValidation(disabled, values) : false}
          />
        ))}
        <ErrorComponent
          formik={formik}
          dataCy={dataCy}
          inputName={inputName}
          label={labelTitle}
          displayErrors={displayErrors}
        />
      </ColumnsItem>
    </Columns>
  );
};
export default RadioComponent;
