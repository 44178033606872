/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useLogger } from '@contexts/logger-provider';
import { Redirect } from 'react-router-dom';
import { RouteParamsType } from './types';

export function extractRouteParams<T = any>(props): T {
  const queryParams = {};
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.forEach((value, key) => {
    if (queryParams[key] == null) {
      queryParams[key] = value;
    } else {
      queryParams[key] = [queryParams[key], value].flat();
    }
  });
  const pathParams = props?.computedMatch?.params ?? props?.match?.params;

  return { ...queryParams, ...pathParams } as T;
}

const RouteParamsExtractor: React.FC<any> = ({ composant: Composant, wrapper: Wrapper, ...props }) => {
  const LOGGER = useLogger();

  const params = extractRouteParams<RouteParamsType>(props);
  if (params.trackerId != null) {
    LOGGER.setTrackerId(params.trackerId);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('trackerId');
    searchParams.delete('login');
    searchParams.delete('accessToken');
    return (
      <Redirect
        to={{
          pathname: props.location.pathname,
          search: searchParams.toString(),
        }}
      />
    );
  }
  if (Wrapper !== null) {
    return (
      <Wrapper>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Composant {...params} />
      </Wrapper>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Composant {...params} />;
};

export default RouteParamsExtractor;
