import { VariantState } from '@bytel/trilogy-react-ts';
import { EcoAstuce } from '@services/interfaces/generated/YSER-v1';
import { PopupConfig } from '@shared/components/popup/type';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useData = () => {
  const { t } = useTranslation('domain/eco-astuces', {
    keyPrefix: 'popup',
  });

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [currentEcoastuce, setCurrentEcoastuce] = useState<EcoAstuce>();
  const [deleteEcoastuce, setDeleteEcoastuce] = useState<() => () => Promise<void>>();

  const activatePopup = useCallback((confirmDelete: () => Promise<void>, ecoAstuce: EcoAstuce) => {
    setDeleteEcoastuce(() => confirmDelete);
    setCurrentEcoastuce(ecoAstuce);
    setShowPopup(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowPopup(false);
    setCurrentEcoastuce(undefined);
    setDeleteEcoastuce(undefined);
  }, []);

  const handleClick = useCallback(() => {
    if (deleteEcoastuce) deleteEcoastuce()();
    handleClose();
  }, [deleteEcoastuce, handleClose]);

  const config = useMemo<PopupConfig>(
    () => ({
      title: t('title'),
      ctaContent: t('confirm'),
      ctaCancelContent: t('cancel'),
      btnValidColor: VariantState.TERTIARY,
      ctaOnClick: handleClick,
      onClose: handleClose,
      ctaCancelOnClick: handleClose,
      t,
      dataCy: `ecoastuces-${currentEcoastuce?.id}-popup`,
    }),
    [handleClick, currentEcoastuce?.id, handleClose, t],
  );

  return {
    showPopup,
    activatePopup,
    config,
    t,
    currentEcoastuceTitle: currentEcoastuce?.title,
  };
};

export default useData;
