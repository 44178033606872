(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["axios"], factory);
	else if(typeof exports === 'object')
		exports["@bytel/prisme-logger"] = factory(require("axios"));
	else
		root["@bytel/prisme-logger"] = factory(root["axios"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_axios__) {
return 