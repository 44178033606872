import { WorkInProgressPage } from '@pages';
import AuthenticatedRoute from '@router/AuthenticatedRoute';
import RouteParamsExtractor from '@router/route-params-extractor';

import { ERREUR_PATH } from '@router/paths';
import { ROUTES } from '@router/routes';

import SplashScreen from '@domain/app/splashscreen';
import { RouteParameters, RouteType } from '@router/types';
import { useRoles } from '@shared/contexts/auth-provider';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory } from 'react-router-dom';

const buildRoutes: (route: RouteType) => JSX.Element[] = (route: RouteType) => {
  const component = route.composant ?? WorkInProgressPage;

  return [
    <AuthenticatedRoute
      exact
      key={route.path}
      path={route.path}
      rolesAllowed={route.roles}
      // eslint-disable-next-line react/jsx-props-no-spreading
      render={(props) => <RouteParamsExtractor wrapper={null} composant={component} {...props} />}
    />,
    ...(route.children?.map((child) => buildRoutes({ ...child, path: route.path + child.path })) || []).flat(),
  ];
};

const AppContent: React.FC = () => {
  const { t } = useTranslation('domain/app-shell', {
    keyPrefix: 'loading',
  });

  const [routes] = React.useState<Array<JSX.Element>>(ROUTES.map(buildRoutes).flat());

  const roles = useRoles();
  const history = useHistory();
  if (roles.length === 0) {
    history.push(`${ERREUR_PATH.replace(RouteParameters.erreurId, '403')}`);
  }

  return (
    <main
      style={{ minHeight: 'calc(100vh - 78px - 100px)' }}
      data-cy="container-app-content"
      className="main-content is-marginless is-paddingless is-fullwidth is-fullheight has-background-white"
    >
      <Suspense fallback={<SplashScreen text={t('page')} />}>
        <Switch>{routes}</Switch>
      </Suspense>
    </main>
  );
};

export default AppContent;
