import AxiosPrismeLogger from '@bytel/prisme-logger/lib/axios';
import { ActualitesAPIService, APIDescriptorService, PartenaireAPIService } from '@services/api';
import { EcoAstucesAPIService } from '@services/api/';
import ConsommationFlotteAPIService from '@services/api/ConsommationFlotteAPIService';
import SirenAPIService from '@services/api/SirenAPIService';
import { Siren } from '@services/interfaces/generated/YSER-v1';
import { QueryClient } from 'react-query';

export type NotifyType = {
  text: string;
  vertical?: 'top' | 'bottom';
  horizontal?: 'center' | 'left' | 'right';
  severity?: 'success' | 'info' | 'warning' | 'error';
  open?: boolean;
  dataCy?: string;
};

export type NotifyContextType = {
  notification?: NotifyType;
  pushNotification: (n: NotifyType) => void;
};

export type AvailableType<T> = keyof T;
export type OptionType<T> = {
  key: string;
  prop: AvailableType<T>;
};

export type I18nConfigType<T> = {
  key: string;
  options?: OptionType<T>[];
};

// Providers
export type AllProviderProps = {
  logger?: AxiosPrismeLogger;
  reactQueryInstance?: QueryClient;
  apiDescriptorInstance?: APIDescriptorService;
  partenaireAPIInstance?: PartenaireAPIService;
  ecoAstuceAPIInstance?: EcoAstucesAPIService;
  actualiteAPIInstance?: ActualitesAPIService;
  consommationFlotteAPIInstance?: ConsommationFlotteAPIService;
  sirenAPIInstance?: SirenAPIService;
};

export type LoaderContextType = {
  showLoader: (invokerKey?: string) => void;
  hideLoader: (invokerKey?: string) => void;
};

export type SirenContextType = {
  siren?: Siren;
  setSiren: (version?: Siren) => void;
};

export const SIREN_CACHE_KEY = 'selectedSiren';
